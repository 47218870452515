import styled from '@emotion/styled';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BsDisplay, BsXLg } from 'react-icons/bs';
import CloseButton from '../atoms/CloseButton';
import IconCircle from '../atoms/IconCircle';
import Slider from '../atoms/Slider';
import { Persona, Resource } from '../../types/SessionHosts';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import ErrorSideBar from '../../pages/Error/ErrorSideBar';
dayjs.extend(utc);

type Props = {
  sessionHostId: string;
  vmDetailsOpen: boolean;
  closeVMDetails: () => void;
};

const AccessWrapper = styled('div')`
  background: #efefef;
  padding: 60px 40px 40px 40px;

  h2 {
    text-align: center;
    font-size: 22px;
    margin: 15px 0 30px 0;
  }

  .details {
    margin: 20px 0 60px;
    padding: 0;
    list-style: none;

    li {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
    }

    strong {
      font-size: 14px;
    }
  }
}`;

export const VMDetails = ({ sessionHostId, vmDetailsOpen, closeVMDetails }: Props) => {
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState<Resource | null>(null);
  const [showErrorPage, setShowErrorPage] = useState(false);

  const getDetails = async () => {
    setLoading(true);
    setShowErrorPage(false);
    try {
      const resp = await axios.get(process.env.REACT_APP_API_BASE_URL + '/api/sessionhosts/' + sessionHostId);
      setDetails(resp.data.sessionHost);
    } catch (error: any) {
      if (error.isAxiosError && error.response.data.detail.includes('does not exist.')) {
        setShowErrorPage(true);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (vmDetailsOpen === true && loading === false) {
      getDetails();
    }
  }, [vmDetailsOpen]);

  return (
    <Slider open={vmDetailsOpen} width='500px'>
      {showErrorPage ? (
        <AccessWrapper>
          <ErrorSideBar
            errorMessage={"We can't seem to find the session host you are looking for..."}
            closeSideBar={closeVMDetails}
          />
        </AccessWrapper>
      ) : (
        <AccessWrapper>
          <div>
            <IconCircle className='xl'>
              <BsDisplay />
            </IconCircle>
            <h2>Details</h2>
            <CloseButton aria-label='Close' onClick={closeVMDetails}>
              <BsXLg />
            </CloseButton>

            <ul className='details'>
              {details!?.name !== undefined ? (
                <li>
                  <strong>VM: </strong>
                  <span>{details!?.name}</span>
                </li>
              ) : (
                <li>
                  <strong>ID: </strong>
                  <span>{details!?.id}</span>
                </li>
              )}
              {(details?.persona === Persona.DataCollection || details?.persona === Persona.Forensic) && (
                <>
                  <li>
                    <strong>Friendly Name: </strong>
                    <span>{details?.friendlyName || ''}</span>
                  </li>
                  <li>
                    <strong>Friendly Name Status: </strong>
                    <span>{details?.friendlyNameStatus || ''}</span>
                  </li>
                </>
              )}
              <li>
                <strong>User: </strong>
                <span>{details?.user?.name || ''}</span>
              </li>
              <li>
                <strong>Persona: </strong>
                <span>{details!?.persona}</span>
              </li>
              {details!?.persona === Persona.DataCollection && (
                <li>
                  <strong>Matter Id: </strong>
                  <span>{details?.jobMatterId || ''}</span>
                </li>
              )}
              <li>
                <strong>User Status: </strong>
                <span>{details!?.userStatus}</span>
              </li>
              <li>
                <strong>Region: </strong>
                <span>{details!?.region}</span>
              </li>
              <li>
                <strong>Size: </strong>
                <span>{details!?.size}</span>
              </li>
              <li>
                <strong>Disk Size: </strong>
                <span>{details!?.dataDiskSizeInGB} GB</span>
              </li>
              <li>
                <strong>Status: </strong>
                <span>{details!?.status}</span>
              </li>
              <li>
                <strong>Internet Access: </strong>
                <span>{details!?.internetStatus}</span>
              </li>
              <li>
                <strong>Is Redeploying: </strong>
                <span>{details!?.isRedeploying ? 'true' : 'false'}</span>
              </li>
              {/* <li><strong>Internet Status: </strong><span>{details!?.internetStatus}</span></li>
          {details!?.internetStatus === 'Granted' &&
          <>
            <li><strong>Internet Duration in hours: </strong><span>{details!?.internetDurationInHours}</span></li>
            <li><strong>Internet Start Time: </strong><span>{dayjs.utc(details!?.internetStartTime).local().format('DD-MMM-YYYY hh:mm a')}</span></li>
            <li><strong>Internet End Time: </strong><span>{dayjs.utc(details!?.internetEndTime).local().format('DD-MMM-YYYY hh:mm a')}</span></li>
          </>
          } */}
              <li>
                <strong>Power Status: </strong>
                <span>{details!?.powerStatus}</span>
              </li>
              <li>
                <strong>Auto Shutdown Status: </strong>
                <span>{details!?.autoShutdownStatus}</span>
              </li>
              {details!?.autoShutdownTime && (
                <li>
                  <strong>Auto Shutdown Time: </strong>
                  <span>{details!?.autoShutdownTime}</span>
                </li>
              )}
              {details!?.nextAutoShutdownDateTimeOffset && (
                <li>
                  <strong>Next Auto Shutdown Offset: </strong>
                  <span>{dayjs.utc(details!?.nextAutoShutdownDateTimeOffset).format('DD-MMM-YYYY hh:mm a')}</span>
                </li>
              )}
              <li>
                <strong>Auto Shutdown Time Zone: </strong>
                <span>{details!?.autoShutdownTimeZone}</span>
              </li>
              <li>
                <strong>Created Date: </strong>
                <span>
                  {dayjs
                    .utc(details!?.created)
                    .local()
                    .format('DD-MMM-YYYY')}
                </span>
              </li>
              <li>
                <strong>Last Modified: </strong>
                <span>
                  {dayjs
                    .utc(details!?.lastModified)
                    .local()
                    .format('DD-MMM-YYYY hh:mm a')}
                </span>
              </li>
            </ul>
          </div>
        </AccessWrapper>
      )}
    </Slider>
  );
};

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import Button from '../../atoms/Button';
import DropDownSelector from '../../atoms/DropDownSelector';
import Label from '../../atoms/Label';
import TextInputAutoComplete from '../../molecules/TextInputAutoComplete';
import { Spinner } from '../../molecules/Spinner';
import { PERSONAS_ID, regions } from '../../../constants';
import { FormField, InlineError, PageError } from './styled';
import {
  DADTADISK_DAILYDRIVER_DEFAULT,
  DADTADISK_FORENSIC_DEFAULT,
  DADTADISK_DATACOLLECTION_DEFAULT,
  getDataDiskSizeByPersona,
  getVmSizeByPersona,
  personas,
  mapJobsDropdownFormat,
  mapJobUserList,
  mapSubmitPayload,
  VM_DEFAULT_SIZE
} from './utils';
import { getJobById, searchJobs } from 'api';
import { Item, Job, JobUser, Option } from '@/types/Jobs';
import Toast from '../../../components/atoms/Toast';

export const VMForm = () => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    watch,
    setValue
  } = useForm();
  const [users, setUsers] = useState<Item[]>();
  const [jobs, setJobs] = useState<Job[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [toastActive, setToastActive] = useState(false);
  const [toastContent, setToastContent] = useState('');

  const watchPersona = watch('persona');
  const watchJob = watch('jobId');

  const location = useLocation();
  const jobIdFromPreviousPage = location.state;

  const addToast = (content: string) => {
    setToastActive(true);
    setToastContent(content);
    setTimeout(() => {
      setToastActive(false);
      setToastContent('');
    }, 5000);
  };

  useEffect(() => {
    (async () => {
      if (jobIdFromPreviousPage && jobIdFromPreviousPage.length > 0) {
        try {
          setValue('persona', PERSONAS_ID.DATA_COLLECTION);
          const responseJob = await getJobById(jobIdFromPreviousPage);
          responseJob && setValue('jobId', responseJob.id);
          responseJob && setValue('region', responseJob.region);
          setJobs([responseJob]);
          const formatUsers = responseJob && mapJobUserList(responseJob.jobUsers);
          const primaryJobUser = responseJob && responseJob.jobUsers?.find((user: JobUser) => user.type === 'Primary');
          formatUsers && setUsers([...formatUsers, { id: '', name: 'None' }]);
          primaryJobUser && setValue('userId', primaryJobUser.user.id);
        } catch (err: any) {
          err.response && addToast(err.response.data.detail);
        }
      }
    })();
  }, []);

  const dataDiskDefualtValue =
    watchPersona === PERSONAS_ID.DAILY_DRIVER
      ? DADTADISK_DAILYDRIVER_DEFAULT
      : watchPersona === PERSONAS_ID.FORENSIC
      ? DADTADISK_FORENSIC_DEFAULT
      : DADTADISK_DATACOLLECTION_DEFAULT;

  const vmDefaultSize = watchPersona === PERSONAS_ID.FORENSIC ? undefined : VM_DEFAULT_SIZE;

  const isDisableJobRelated = watchPersona === PERSONAS_ID.DATA_COLLECTION && !watchJob && !jobIdFromPreviousPage;

  const createNew = () => {
    reset();
    setIsSubmitted(false);
    setUsers([] as any);
  };

  const getUsers = async (search: string) => {
    const userData = await axios.get(process.env.REACT_APP_API_BASE_URL + '/api/users/search?name=' + search);
    setUsers(userData.data.users);
  };

  const fetchJobs = async (search: string) => {
    const res = await searchJobs(search);
    setJobs(res.data.jobs);
  };

  const resetJobs = () => {
    setJobs([] as any);
    setUsers([] as any);
    setValue('jobId', undefined);
    setValue('region', undefined);
    setValue('size', undefined);
    setValue('userId', undefined);
  };

  const resetUsers = () => {
    setUsers([] as any);
    setValue('userId', undefined);
  };

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    const submitPayload = mapSubmitPayload(data);

    await axios
      .post(process.env.REACT_APP_API_BASE_URL + '/api/sessionhosts', submitPayload)
      .then(() => {
        setIsLoading(false);
        setIsSubmitted(true);
      })
      .catch(error => {
        setIsLoading(false);
        addToast(error.response.data.detail);
      });
  };

  // set region and primary analyst as default when jobId form api call
  useEffect(() => {
    const selectJob = jobs.length > 0 && jobs.find(job => job.id === watchJob);
    const formatUsers = selectJob && mapJobUserList(selectJob.jobUsers);
    const primaryJobUser = selectJob && selectJob.jobUsers?.find(user => user.type === 'Primary');

    formatUsers && setUsers([...formatUsers, { id: '', name: 'None' }]);
    selectJob && setValue('region', selectJob.region);
    primaryJobUser && setValue('userId', primaryJobUser.user.id);
  }, [watchJob]);

  // reset all job related info when the user re-select persona
  useEffect(() => {
    resetJobs();
  }, [watchPersona]);

  // set default value into the context when the user re-select persona
  useEffect(() => {
    setValue('dataDiskSizeInGB', dataDiskDefualtValue);
    setValue('size', vmDefaultSize);
  }, [watchPersona]);

  return (
    <>
      {!isLoading ? (
        <>
          {!isSubmitted ? (
            <>
              <Toast open={toastActive} content={toastContent}></Toast>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormField>
                  <Label htmlFor='persona'>Persona</Label>
                  <DropDownSelector
                    defaultValue=''
                    placeholder='Please select'
                    ariaLabel='Persona'
                    disabled={jobIdFromPreviousPage && jobIdFromPreviousPage.length > 0}
                    {...register('persona', { required: true })}
                    options={personas!?.map((persona: Option) => ({
                      label: persona.name,
                      value: persona.id
                    }))}
                  />
                  {errors.persona && <InlineError>This field is required</InlineError>}
                </FormField>
                {watchPersona === PERSONAS_ID.DATA_COLLECTION &&
                  jobIdFromPreviousPage &&
                  jobIdFromPreviousPage.length > 0 && (
                    <FormField>
                      <Label htmlFor='jobid'>Matter</Label>
                      <DropDownSelector
                        defaultValue=''
                        placeholder='Please select'
                        ariaLabel='Matter'
                        disabled={jobIdFromPreviousPage && jobIdFromPreviousPage.length > 0}
                        {...register('jobId', { required: true })}
                        options={mapJobsDropdownFormat(jobs)!?.map((job: Option) => ({
                          label: `${job.matterId} - ${job.name}`,
                          value: job.id
                        }))}
                      />
                    </FormField>
                  )}
                {watchPersona === PERSONAS_ID.DATA_COLLECTION && !jobIdFromPreviousPage && (
                  <FormField>
                    <Label htmlFor='jobId'>Matter</Label>
                    <Controller
                      control={control}
                      name='jobId'
                      render={({ field: { onChange } }) => (
                        <TextInputAutoComplete
                          setSelection={true}
                          onSetOption={onChange}
                          searchResults={mapJobsDropdownFormat(jobs)}
                          searchValue={fetchJobs}
                          changeSelection={resetJobs}
                          ariaLabel='Job'
                          customLable={['jobId', 'title']}
                        />
                      )}
                      rules={{
                        required: 'This field is required'
                      }}
                    />
                    {errors.jobId && <InlineError>This field is required</InlineError>}
                  </FormField>
                )}

                <FormField>
                  <Label htmlFor='region'>Region</Label>
                  <DropDownSelector
                    defaultValue=''
                    disabled={watchPersona === PERSONAS_ID.DATA_COLLECTION}
                    placeholder='Please select'
                    ariaLabel='Region'
                    {...register('region', { required: true })}
                    options={regions!?.map((region: Option) => ({
                      label: region.name,
                      value: region.id
                    }))}
                  />
                  {errors.region && <InlineError>This field is required</InlineError>}
                </FormField>

                {watchPersona === PERSONAS_ID.DATA_COLLECTION ? (
                  <FormField>
                    <Label htmlFor='userId'>Analyst</Label>
                    <DropDownSelector
                      defaultValue=''
                      disabled={isDisableJobRelated}
                      placeholder='Please select'
                      ariaLabel='Analyst'
                      {...register('userId')}
                      options={users!.map((user: Option) => ({
                        label: user.name,
                        value: user.id
                      }))}
                    />
                  </FormField>
                ) : (
                  <FormField>
                    <Label htmlFor='userId'>Analyst</Label>
                    <Controller
                      control={control}
                      name='userId'
                      render={({ field: { onChange } }) => (
                        <TextInputAutoComplete
                          setSelection={true}
                          onSetOption={onChange}
                          searchResults={users}
                          searchValue={getUsers}
                          changeSelection={resetUsers}
                          ariaLabel='Analyst'
                          disabled={isDisableJobRelated}
                        />
                      )}
                    />
                  </FormField>
                )}
                <FormField>
                  <Label htmlFor='size'>Size</Label>
                  <DropDownSelector
                    defaultValue=''
                    placeholder='Please select'
                    ariaLabel='Size'
                    {...register('size', { required: true })}
                    options={getVmSizeByPersona(watchPersona)!?.map((size: Option) => ({
                      label: size.name,
                      value: size.id
                    }))}
                  />
                  {errors.size && <InlineError>This field is required</InlineError>}
                </FormField>
                <FormField>
                  <Label htmlFor='dataDiskSizeInGB'>Data Disk Size</Label>
                  <DropDownSelector
                    defaultValue={dataDiskDefualtValue}
                    disabled={watchPersona === PERSONAS_ID.DAILY_DRIVER}
                    placeholder='Please select'
                    ariaLabel='Data Disk Size'
                    {...register('dataDiskSizeInGB')}
                    options={getDataDiskSizeByPersona(watchPersona)!?.map((size: Option) => ({
                      label: size.name,
                      value: size.id
                    }))}
                  />
                </FormField>
                <Button type='submit'>Submit</Button>
              </form>
            </>
          ) : (
            <div>
              <h3>Your request has been submitted</h3>
              <Button type='button' onClick={createNew} secondary>
                Create new VM
              </Button>
            </div>
          )}
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

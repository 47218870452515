import { Region, SessionHostUserStatus, Size, User } from './Jobs';

export type Resource = {
  id: string;
  userId: string;
  user?: User;
  jobId: string;
  eligibleJobUsers?: User[];
  region: Region;
  persona: Persona;
  jobMatterId?: string;
  size?: Size;
  dataDiskSizeInGB?: number;
  name?: string;
  friendlyName?: string;
  friendlyNameStatus?: FriendlyNameStatus;
  subscriptionId?: string;
  resourceGroup?: string;
  status: SessionHostStatus;
  isRedeploying?: boolean;
  userStatus: SessionHostUserStatus;
  internetStatus: InternetStatus;
  internetDurationInHours?: number;
  internetStartTime?: string;
  internetEndTime?: string;
  powerStatus?: PowerStatus;
  autoShutdownStatus: AutoShutdownStatus;
  autoShutdownTime?: string;
  autoShutdownTimeZone?: string;
  nextAutoShutdownDateTimeOffset?: string;
  created: string;
  lastModified: string;
};

export enum FriendlyNameStatus {
  NotSet = 'NotSet',
  Updating = 'Updating',
  Set = 'Set',
  FailedToUpdate = 'FailedToUpdate'
}

export type Scale = {
  id: string;
  vmSize: string;
  vmDiskSize?: string;
};

export type ShutdownUpdate = {
  action: string;
  shutdownTime?: string;
};

export type NotesSearch = {
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  totalCount: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  items: Array<Note>;
};

export type Note = {
  id: string;
  jobId: string;
  note: string;
  created: string;
  lastModified: string;
  createdByUser: User;
};

export enum Persona {
  DailyDriver = 'DailyDriver',
  Forensic = 'Forensic',
  DataCollection = 'DataCollection'
}

export enum SessionHostStatus {
  DEPLOYING = 'Deploying',
  DEPLOYED = 'Deployed',
  FAILEDTODEPLOY = 'FailedToDeploy',
  RESCALING = 'Rescaling',
  RESCALED = 'Rescaled',
  FAILEDTORESCALE = 'FailedToRescale',
  REMOVING = 'Removing',
  REMOVED = 'Removed',
  FAILEDTOREMOVE = 'FailedToRemove'
}

export enum InternetStatus {
  DISABLED = 'Disabled',
  GRANTING = 'Granting',
  GRANTED = 'Granted',
  FAILEDTOGRANT = 'FailedToGrant',
  REVOKING = 'Revoking',
  FAILEDTOREVOKE = 'FailedToRevoke'
}

export enum PowerStatus {
  DEALLOCATED = 'Deallocated',
  DEALLOCATING = 'Deallocating',
  RUNNING = 'Running',
  STARTING = 'Starting',
  STOPPED = 'Stopped',
  STOPPING = 'Stopping',
  UNKNOWN = 'Unknown'
}

export enum AutoShutdownStatus {
  DISABLING = 'Disabling',
  DISABLED = 'Disabled',
  FAILEDTODISABLE = 'FailedToDisable',
  SKIPPINGNEXT = 'SkippingNext',
  SKIPPEDNEXT = 'SkippedNext',
  FAILEDTOSKIPNEXT = 'FailedToSkipNext',
  ENABLING = 'Enabling',
  ENABLED = 'Enabled',
  FAILEDTOENABLE = 'FailedToEnable',
  UPDATING = 'Updating',
  UPDATED = 'Updated',
  FAILEDTOUPDATE = 'FailedToUpdate'
}

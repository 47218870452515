import axios from 'axios';
import { Resource } from '../types/SessionHosts';

export type SearchQuery = {
  pageNumber?: number;
  pageSize: number;
  orderBy?: string;
  userId?: string;
  name?: string;
  persona?: string;
  region?: string;
  userStatus?: string;
};
export type ResourceResponse = {
  totalPages: number;
  pageNumber: number;
  items: Resource[];
};
export const getSessionHosts = async (searchQuery: SearchQuery): Promise<ResourceResponse> => {
  const res = await axios.post(process.env.REACT_APP_API_BASE_URL + '/api/sessionhosts/search', searchQuery);
  return res.data;
};

import React from 'react';
import './App.css';

import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from './services/msal/authConfig';

import { RequestInterceptor } from './services/msal/RequestInterceptor';
import { RootComponent } from './components/RootComponent';

import { Global, ThemeProvider } from '@emotion/react';
import { globalStyling } from './styles/globals';
import theme from './styles/theme';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './services/appInsights/appInsights';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Global styles={globalStyling} />
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}>
        <RequestInterceptor>
          <RootComponent />
        </RequestInterceptor>
      </MsalAuthenticationTemplate>
    </ThemeProvider>
  );
}

export default withAITracking(reactPlugin, App);

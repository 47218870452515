import React from 'react';
import { useState, useEffect, createContext } from 'react';
import { Spinner } from './molecules/Spinner';
import axios from 'axios';
import SiteHeader from './molecules/SiteHeader';
import styled from '@emotion/styled';
import { Router } from 'routes';
import { SessionTimer } from './molecules/SessionTimer';

export interface User {
  name: string;
  id: string;
}

export interface iUserContext {
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User>>;
}

export const UserContext = createContext<iUserContext>({
  user: {
    name: '',
    id: ''
  },
  setUser: () => {}
});

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Main = styled('div')`
  flex: 1;
  padding: 20px 16px 20px;
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
`;

export const RootComponent = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [user, setUser] = useState<User>({
    name: '',
    id: ''
  });
  const value = { user, setUser };

  useEffect(() => {
    (async () => {
      const profileData = await axios.get(process.env.REACT_APP_API_BASE_URL + '/api/users/me');
      setIsLoaded(true);
      setUser(profileData.data.user);
    })();
  }, []);

  return (
    <div className='App'>
      {isLoaded ? (
        <>
          <UserContext.Provider value={value}>
            <Wrapper>
              <SessionTimer />
              <SiteHeader />
              <Main>
                <Router />
              </Main>
            </Wrapper>
          </UserContext.Provider>
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

import styled from '@emotion/styled';

const BreadCrumbs = styled('div')`
  font-size: 75%;
  display: flex;
  align-items: center;

  svg {
    margin: 0 5px;
  }

  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;
export default BreadCrumbs;

import { FriendlyNameStatus, InternetStatus, Persona, Resource, SessionHostStatus } from '../../../types/SessionHosts';
import React from 'react';
import { BsEye, BsThreeDots } from 'react-icons/bs';
import { HiStatusOnline, HiStatusOffline } from 'react-icons/hi';
import Badge from '../../atoms/Badge';
import { LinkButton, LinkButtonHighlight, ResourceOptions, IconContainer } from './styled';
import { SessionHostUserStatus } from '../../../types/Jobs';

type Props = {
  resource: Resource;
  optionsClickedIndex?: number | null;
  toggleAction: (index: number) => void;
  handleClickDetails: (index: number) => void;
  handleClickNotes: (index: number) => void;
  handleClickInternetAccess: (index: number) => void;
  handleUpdateShutdown: (index: number) => void;
  handleScaleVM: (index: number) => void;
  handleRedeployVM: (index: number) => void;
  handleDelteVM: (index: number) => void;
  handleAssignUser?: (index: number) => void;
  handleUpdateFriendlyName: (index: number) => void;
  index?: number;
};
const ResourceItem = React.forwardRef<HTMLUListElement, Props>((props, ref) => {
  const {
    index = 0,
    optionsClickedIndex = null,
    resource,
    toggleAction,
    handleClickDetails,
    handleClickNotes,
    handleClickInternetAccess,
    handleUpdateShutdown,
    handleScaleVM,
    handleRedeployVM,
    handleDelteVM,
    handleAssignUser,
    handleUpdateFriendlyName
  } = props;

  const isInternetAccessDisabled = !(
    resource.persona === Persona.DataCollection && resource.userStatus === SessionHostUserStatus.ASSIGNED
  );
  const isFriendlyNameDisabled =
    resource.persona === Persona.DailyDriver ||
    resource.friendlyNameStatus === FriendlyNameStatus.Updating ||
    resource.friendlyNameStatus === FriendlyNameStatus.FailedToUpdate;

  const displayName =
    resource.friendlyName && resource.friendlyName.length > 0 ? (
      resource.friendlyName
    ) : resource.name ? (
      resource.name
    ) : (
      <em>Name pending</em>
    );

  return (
    <>
      <tr key={index}>
        <td>{displayName}</td>
        <td style={{ width: '140px' }}>{resource?.persona || ''}</td>
        <td>{resource?.user?.name || ''}</td>
        <td style={{ width: '100px' }}>{resource?.region || ''}</td>
        <td style={{ textAlign: 'center' }}>
          <Badge type='badge' status={resource.userStatus} />
        </td>
        <td style={{ textAlign: 'center' }}>
          <Badge type='badge' status={resource.status} />
        </td>
        <td style={{ textAlign: 'center', width: '140px' }}>
          <IconContainer>
            <div className='status-text'>
              <Badge type='badge' status={resource.internetStatus} />
            </div>
            {resource.internetStatus === InternetStatus.GRANTED ? (
              <div className='status-icon'>
                <HiStatusOnline color={'#228B22'} size='30px' />
              </div>
            ) : (
              <div className='status-icon'>
                <HiStatusOffline color={'#FF6347'} size='30px' />
              </div>
            )}
          </IconContainer>
        </td>
        <td style={{ textAlign: 'center' }}>
          <LinkButtonHighlight
            onClick={() => {
              handleClickDetails(index);
            }}>
            <BsEye style={{ fontSize: '24px' }} />
          </LinkButtonHighlight>
        </td>
        <td>
          {(resource.status === SessionHostStatus.DEPLOYED || resource.status === SessionHostStatus.RESCALED) && (
            <ResourceOptions>
              <LinkButton
                aria-label={'Actions for resource: ' + resource.id}
                onClick={() => {
                  toggleAction(index);
                }}>
                <BsThreeDots />
              </LinkButton>
              {optionsClickedIndex === index && (
                <ul className='options-list' ref={ref}>
                  <li>
                    <LinkButton
                      onClick={() => {
                        handleClickNotes(index);
                      }}>
                      Notes
                    </LinkButton>
                  </li>
                  <li>
                    <LinkButton
                      disabled={isInternetAccessDisabled}
                      onClick={() => {
                        handleClickInternetAccess(index);
                      }}>
                      Internet Access
                    </LinkButton>
                  </li>

                  <li>
                    <LinkButton
                      onClick={() => {
                        handleUpdateShutdown(index);
                      }}>
                      Update Shutdown
                    </LinkButton>
                  </li>
                  <li>
                    <LinkButton
                      onClick={() => {
                        handleScaleVM(index);
                      }}>
                      Scale VM
                    </LinkButton>
                  </li>
                  <li>
                    <LinkButton
                      onClick={() => {
                        handleRedeployVM(index);
                      }}>
                      Redeploy VM
                    </LinkButton>
                  </li>
                  <li>
                    <LinkButton
                      onClick={() => {
                        handleDelteVM(index);
                      }}>
                      Delete VM
                    </LinkButton>
                  </li>
                  {handleAssignUser && (
                    <li>
                      <LinkButton
                        onClick={() => {
                          handleAssignUser(index);
                        }}>
                        User Assignment
                      </LinkButton>
                    </li>
                  )}
                  <li>
                    <LinkButton
                      disabled={isFriendlyNameDisabled}
                      onClick={() => {
                        handleUpdateFriendlyName(index);
                      }}>
                      Update Friendly Name
                    </LinkButton>
                  </li>
                </ul>
              )}
            </ResourceOptions>
          )}
        </td>
      </tr>
    </>
  );
});

ResourceItem.displayName = 'ResourceItem';

export default ResourceItem;

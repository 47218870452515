import React from 'react';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';
import { BsTrash, BsXLg } from 'react-icons/bs';
import Button from '../atoms/Button';
import CloseButton from '../atoms/CloseButton';
import IconCircle from '../atoms/IconCircle';
import Label from '../atoms/Label';
import Slider from '../atoms/Slider';
import TextArea from '../atoms/TextArea';
import { Job } from './../../types/Jobs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import CheckboxTick from './../icons/check.svg';
import Confirm from '../molecules/Confirm';
import { useState } from 'react';
dayjs.extend(utc);

type Props = {
  details?: Job;
  deleteJobOpen: boolean;
  deleteJob: (reason: string) => void;
  closeDeleteJob: () => void;
};

const AccessWrapper = styled('div')`
  background: #efefef;
  padding: 60px 90px 40px 90px;

  h2 {
    text-align: center;
    font-size: 22px;
    margin: 15px 0 30px 0;
  }

  .details {
    margin: 20px 0 60px;
    padding: 0;
    list-style: none;

    li {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
    }

    strong {
      font-size: 14px;
    }
  }
`;

const FormField = styled('div')`
  margin-bottom: 15px;
`;

const InlineError = styled.div(() => ({
  color: '#CC0000',
  marginTop: '5px',
  width: '100%',
  marginLeft: 0
}));

const CheckboxWrap = styled('div')`
  margin-bottom: 20px;
`;

const CheckboxLabel = styled.label(({ theme }) => ({
  marginLeft: 0,
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',

  input: {
    position: 'absolute',
    left: '-3000em'
  },

  'input+span': {
    border: '1px solid',
    borderColor: theme.colors.primary,
    background: theme.colors.white,
    width: '20px',
    height: '20px',
    display: 'block',
    borderRadius: '4px',
    marginRight: '10px',
    marginBottom: '2px',
    cursor: 'pointer'
  },

  'input:checked + span': {
    backgroundColor: theme.colors.secondary,
    borderColor: theme.colors.secondary,
    color: '#FFFFFF',
    backgroundSize: '25px 25px',
    backgroundPosition: '-4px',
    backgroundImage: `url(` + CheckboxTick + `)`
  }
}));

export const DeleteJob = ({ details, deleteJobOpen, deleteJob, closeDeleteJob }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [formData, setFormData] = useState<any>();

  const verifyForm = (data: any) => {
    setFormData(data);
    setConfirmOpen(true);
  };

  const confirmSubmit = () => {
    setConfirmOpen(false);
    deleteJob(formData);
  };

  return (
    <>
      <Slider open={deleteJobOpen} width='500px'>
        <AccessWrapper>
          <div>
            <IconCircle className='xl'>
              <BsTrash />
            </IconCircle>
            <h2>Delete Matter</h2>
            <CloseButton aria-label='Close' onClick={closeDeleteJob}>
              <BsXLg />
            </CloseButton>
            <ul className='details'>
              {details?.title !== undefined ? (
                <li>
                  <strong>Title: </strong>
                  <span>{details.title}</span>
                </li>
              ) : (
                <li>
                  <strong>Region: </strong>
                  <span>{details?.region}</span>
                </li>
              )}
              <li>
                <strong>Status: </strong>
                <span>{details?.status}</span>
              </li>
            </ul>

            <form
              onSubmit={handleSubmit((data: any) => {
                delete data.confirmDeletion;
                verifyForm(data);
              })}>
              <FormField>
                <Label htmlFor='reason'>Reason for deletion:</Label>
                <TextArea rows={4} key='reason' id='reason' {...register('reason', { required: true })}></TextArea>
                {errors.reason && <InlineError>This field is required</InlineError>}
              </FormField>

              <p>
                <strong>Note:</strong> Choosing the Delete Matter button below will permantly remove the matter{' '}
                <strong>and any associated shared data</strong> and you will be unable to view or edit the matter or any
                of the data again.
              </p>

              <CheckboxWrap>
                <CheckboxLabel>
                  <input type='checkbox' {...register('confirmDeletion', { required: true })} />
                  <span></span>
                  <span>I understand, please delete</span>
                </CheckboxLabel>
                {errors.confirmDeletion && <InlineError>This field is required</InlineError>}
              </CheckboxWrap>

              <Button type='submit'>Delete Matter</Button>
            </form>
          </div>
        </AccessWrapper>
      </Slider>
      <Confirm
        title='Warning'
        content='This will remove all matter data associated with this matter, please click confirm to proceed with deletion'
        open={confirmOpen}
        cancel={() => {
          setConfirmOpen(false);
        }}
        confirm={() => {
          confirmSubmit();
        }}
      />
    </>
  );
};

import styled from '@emotion/styled';

export const Panel = styled('div')`
  background: #fff;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
  position: relative;
  padding: 40px;
  margin-bottom: 40px;
`;
export const LinkButton = styled('button')`
  border: 0;
  background: transparent;
  color: ${props => props.theme.colors.primary};
  font-size: ${props => props.theme.fontSizes.body2};
  display: flex;
  align-items: center;
  cursor: pointer;
`;
export const ShadedTable = styled('table')`
  width: 100%;
  border: 0;
  border-spacing: 0 15px;
  margin-top: 10px;

  th {
    text-align: center;
  }

  tr th:first-of-type {
    text-align: left;
  }

  td {
    background: #ebebeb;
    padding: 15px;
    text-align: center;
  }
  tbody tr td:first-of-type {
    border-radius: 4px 0 0 4px;
    text-align: left;
  }
  tbody tr td:last-of-type {
    border-radius: 0 4px 4px 0;
  }
`;

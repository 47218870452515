import styled from '@emotion/styled';
import axios from 'axios';
import { ReactElement, useEffect, useState } from 'react';
import React, { BsChevronDown, BsInbox, BsPlus, BsEye, BsArrowLeft, BsArrowRight, BsChevronUp } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import TabButton from '../atoms/TabButton';
import { Spinner } from '../molecules/Spinner';
import Badge from '../atoms/Badge';
import { PageRoutes } from 'routes';
import { SearchBar } from '../atoms/SearchBar/SearchBar';
import DropDownSelector from '../atoms/DropDownSelector';
import { noneOption, regions } from 'constants/application';
import { Item, Option } from './../../types/Jobs';
import TextInputAutoComplete from '../molecules/TextInputAutoComplete';
import { SearchQuery, getMatters } from 'api/getMatters';
import { VerticalCenter } from '../atoms/VerticalCenter';
import Button from '../atoms/Button';

type Job = {
  id: string;
  title: string;
  matterId: string;
  region: string;
  jobUsers: JobUser[];
  lastModified: string;
  sharedFolderStatus: string;
  status: string;
};

type JobUser = {
  type: string;
  user: User;
};

type User = {
  firstName: string;
  lastName: string;
  name: string;
};

const HeadingFlexContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;

  h1 {
    font-size: 32px;
    margin: 0;
  }

  button {
    display: flex;
  }

  div {
    display: flex;
    align-items: baseline;
  }
`;

const StyledTable = styled('table')`
  width: 100%;
  border-spacing: 0 15px;
  table-layout: fixed;
  font-size: 87.5%;
  th {
    text-align: center;
  }

  thead th {
    padding: 5px;
  }

  thead th svg {
    margin-left: 10px;
  }

  thead th .active svg {
    color: ${props => props.theme.colors.secondary};
  }

  thead th:nth-of-type(2),
  thead th:nth-of-type(3),
  thead th:nth-of-type(4) {
    padding-left: 15px;
  }

  tbody tr {
    box-shadow: 2px 2px 5px rgb(0 0 0 / 10%);
  }
  tbody td {
    text-align: center;
    padding: 15px;
    background: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  tbody tr:first-of-type td {
    overflow: visible;
  }

  .a11yHidden {
    position: absolute;
    left: -3000em;
    display: block;
  }
`;

const LinkButton = styled('button')`
  border: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
`;

const PrimaryLink = styled('a')`
  margin-left: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  min-width: 100px;
  min-height: 40px;
  border-radius: 6px;
  padding: 9px 20px;
  font-size: ${props => props.theme.fontSizes.body2};
  font-weight: bold;
  background-color: ${props => props.theme.colors.primary};
  color: white;
  border: 1px solid transparent;

  svg {
    margin-right: 10px;
  }
`;

const Paging = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;

  > span {
    display: block;
    width: 16.325px;
    margin: 0 15px;
  }

  button {
    margin: 0 15px;
    font-weight: bold;
  }
  .active {
    color: ${props => props.theme.colors.secondary};
    text-decoration: underline;
  }
`;

const TitleWithIcon = styled('h1')`
  display: flex;
  align-items: end;

  svg {
    margin-right: 15px;
  }
`;

const JobsTable = (): ReactElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [jobs, setJobs] = useState<Job[] | null>(null);
  const [sort, setSort] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [jobType, setJobType] = useState<string | null>();
  const [totalPages, setTotalPages] = useState<number>(1);
  const [filter, setFilter] = useState<string>('all');
  const [pageTitle, setPageTitle] = useState('All matters');
  const [users, setUsers] = useState<Item[]>();
  const [searchTitle, setSearchTitle] = useState<string | null>(null);
  const [searchMatterId, setSearchMatterId] = useState<string | null>(null);
  const [searchRegion, setSearchRegion] = useState<string | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const [isClearValues, setIsClearValues] = useState<boolean>(false);

  const regionWithNone = [...regions, noneOption];

  const getJobs = async (
    page?: number,
    sort?: string,
    jobType?: string | null,
    userId?: string | null,
    title?: string | null,
    matterId?: string | null,
    region?: string | null
  ) => {
    setIsLoading(true);

    const request: SearchQuery = {
      pageNumber: page,
      pageSize: 10
    };

    sort && (request.orderBy = sort);
    request.type = jobType ? jobType : 'AllJobs';

    userId && userId.length > 0 && (request.userId = userId);
    title && title.length > 0 && (request.title = title);
    matterId && matterId.length > 0 && (request.matterId = matterId);
    region && region.length > 0 && region !== 'none' && (request.region = region);

    const jobData = await getMatters(request);

    setJobs(jobData.items);
    setPage(jobData.pageNumber);
    setTotalPages(jobData.totalPages);
    setIsLoading(false);
  };

  useEffect(() => {
    getJobs(page, sort);
  }, []);

  const pagingClick = (page: number) => {
    setPage(page);
    getJobs(page, sort, jobType);
  };

  const sortClick = (sortBy: string) => {
    setSort(sortBy);
    getJobs(page, sortBy, jobType);
  };

  const filterClick = (filterBy: string) => {
    let title;
    setPage(1);
    switch (filterBy) {
      case 'all':
        title = 'All matters';
        setJobType(null);
        getJobs(1, sort);
        break;
      case 'my':
        title = 'My matters';
        setJobType('MyJobs');
        getJobs(1, sort, 'MyJobs');
        break;
      case 'unassigned':
        title = 'Unassigned matters';
        setJobType('UnassignedJobs');
        getJobs(1, sort, 'UnassignedJobs');
        break;
      default:
        title = '';
    }
    setPageTitle(title);
    setFilter(filterBy);
  };

  const getPrimaryAnalyst = (analysts: JobUser[]) => {
    const primary = analysts.filter((analyst: JobUser) => {
      return Boolean(analyst.type === 'Primary');
    });
    if (primary.length > 0) {
      return primary[0].user.name;
    }
    return 'Unassigned';
  };
  const getUsers = async (search: string) => {
    const userData = await axios.get(process.env.REACT_APP_API_BASE_URL + '/api/users/search?name=' + search);
    setUsers(userData.data.users);
  };

  return (
    <>
      <>
        <HeadingFlexContainer>
          <TitleWithIcon>
            <BsInbox /> {pageTitle}
          </TitleWithIcon>
          <div>
            {
              <TabButton
                onClick={() => {
                  filterClick('all');
                }}
                className={filter === 'all' ? 'active' : ''}>
                All matters
              </TabButton>
            }
            <TabButton
              onClick={() => {
                filterClick('my');
              }}
              className={filter === 'my' ? 'active' : ''}>
              My matters
            </TabButton>
            <TabButton
              onClick={() => {
                filterClick('unassigned');
              }}
              className={filter === 'unassigned' ? 'active' : ''}>
              Unassigned matters
            </TabButton>
            <PrimaryLink href={PageRoutes.CreateJob}>
              <BsPlus /> Add new matter
            </PrimaryLink>
          </div>
        </HeadingFlexContainer>
        <StyledTable>
          <thead>
            <tr>
              <th style={{ width: '20%', textAlign: 'center' }}>
                Title
                <LinkButton
                  aria-label='Sort by title'
                  onClick={() => {
                    sortClick('title ' + (sort === 'title asc' ? 'desc' : 'asc'));
                  }}
                  className={sort.indexOf('title') > -1 ? 'active' : ''}>
                  {' '}
                  {sort === 'title asc' ? <BsChevronUp /> : <BsChevronDown />}
                </LinkButton>
              </th>
              <th style={{ width: '20%' }}>
                Matter ID
                <LinkButton
                  aria-label='Sort by Matter ID'
                  onClick={() => {
                    sortClick('matterId ' + (sort === 'matterId asc' ? 'desc' : 'asc'));
                  }}
                  className={sort.indexOf('matterId') > -1 ? 'active' : ''}>
                  {' '}
                  {sort === 'matterId asc' ? <BsChevronUp /> : <BsChevronDown />}
                </LinkButton>
              </th>
              <th style={{ width: '12%' }}>
                Region
                <LinkButton
                  aria-label='Sort by region'
                  onClick={() => {
                    sortClick('region ' + (sort === 'region asc' ? 'desc' : 'asc'));
                  }}
                  className={sort.indexOf('region') > -1 ? 'active' : ''}>
                  {' '}
                  {sort === 'region asc' ? <BsChevronUp /> : <BsChevronDown />}
                </LinkButton>
              </th>
              <th style={{ width: '15%' }}>
                Primary Analyst
                <LinkButton
                  aria-label='Sort by primary analyst'
                  onClick={() => {
                    sortClick('primaryUser ' + (sort === 'primaryUser asc' ? 'desc' : 'asc'));
                  }}
                  className={sort.indexOf('primaryUser') > -1 ? 'active' : ''}>
                  {' '}
                  {sort === 'primaryUser asc' ? <BsChevronUp /> : <BsChevronDown />}
                </LinkButton>
              </th>
              <th style={{ width: '15%' }}>
                Status
                <LinkButton
                  aria-label='Sort by status'
                  onClick={() => {
                    sortClick('status ' + (sort === 'status asc' ? 'desc' : 'asc'));
                  }}
                  className={sort.indexOf('status') > -1 ? 'active' : ''}>
                  {' '}
                  {sort === 'status asc' ? <BsChevronUp /> : <BsChevronDown />}
                </LinkButton>
              </th>
              <th style={{ width: '18%' }}>Details</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <SearchBar
                  onSearch={value => {
                    setIsClearValues(false);
                    setSearchTitle(value);
                    getJobs(page, sort, jobType, userId, value, searchMatterId, searchRegion);
                  }}
                  value={searchTitle}
                  isResetValue={isClearValues}
                />
              </td>
              <td>
                <SearchBar
                  onSearch={value => {
                    setIsClearValues(false);
                    setSearchMatterId(value);
                    getJobs(page, sort, jobType, userId, searchTitle, value, searchRegion);
                  }}
                  value={searchMatterId}
                  isResetValue={isClearValues}
                />
              </td>
              <td style={{ padding: '0px' }}>
                <DropDownSelector
                  value={searchRegion || ''}
                  name='region'
                  onChange={e => {
                    setSearchRegion(e.target.value);
                    getJobs(page, sort, jobType, userId, searchTitle, searchMatterId, e.target.value);
                  }}
                  placeholder='Please select'
                  ariaLabel='Region'
                  options={regionWithNone?.map((region: Option) => ({
                    label: region.name,
                    value: region.id
                  }))}
                  styling={{ width: '100%', fontSize: '14px', height: '30px', svgWidth: '6%', svgHeight: '50%' }}
                />
              </td>
              <td style={{ padding: '0px 0px 0px 20px' }}>
                <TextInputAutoComplete
                  setSelection={true}
                  onSetOption={value => {
                    setIsClearValues(false);
                    setUserId(value);
                    getJobs(page, sort, jobType, value, searchTitle, searchMatterId, searchRegion);
                  }}
                  searchResults={users}
                  searchValue={getUsers}
                  changeSelection={() => {
                    setUsers([] as any);
                    setUserId(null);
                    getJobs(page, sort, jobType, null, searchTitle, searchMatterId, searchRegion);
                  }}
                  ariaLabel='Analyst'
                  isBtnIconEnabled={true}
                  value={userId}
                  disabled={filter === 'my'}
                  styling={{ width: '90%', height: '100%', inputHeight: '30px', fontSize: '14px' }}
                  isResetValue={isClearValues}
                />
              </td>
              <td></td>
              <td style={{ paddingLeft: '50px' }}>
                <Button
                  minWidth='80%'
                  onClick={() => {
                    setSearchTitle(null);
                    setSearchMatterId(null);
                    setSearchRegion(null);
                    setUsers([] as any);
                    setUserId(null);
                    setIsClearValues(true);
                    getJobs(page, sort);
                  }}>
                  Clear
                </Button>
              </td>
            </tr>

            {!isLoading ? (
              jobs!?.map((job: Job, index: number) => (
                <tr key={index}>
                  <td>{job.title}</td>
                  <td>{job.matterId}</td>
                  <td style={{ textAlign: 'center' }}>{job.region === 'Australia' ? 'AUS' : job.region}</td>
                  <td>{job.jobUsers.length > 0 ? getPrimaryAnalyst(job.jobUsers) : job.status}</td>
                  <td>
                    <Badge type='badge' status={job.status} />
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <Link to={{ pathname: '/viewjob/' + job.id }}>
                      <BsEye style={{ fontSize: '24px' }} />
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td style={{ background: 'transparent' }}></td>
                <td style={{ background: 'transparent' }}></td>
                <td style={{ background: 'transparent' }}>
                  <VerticalCenter>
                    <Spinner />
                  </VerticalCenter>
                </td>
                <td style={{ background: 'transparent' }}></td>
                <td style={{ background: 'transparent' }}></td>
                <td style={{ background: 'transparent' }}></td>
              </tr>
            )}
          </tbody>
        </StyledTable>
        <Paging>
          {page > 1 ? (
            <LinkButton
              aria-label='Go to previous page'
              onClick={() => {
                let p = page;
                p--;
                pagingClick(p);
              }}>
              <BsArrowLeft />
            </LinkButton>
          ) : (
            <span></span>
          )}
          {(() => {
            const arr = [];
            for (let i = 1; i <= totalPages; i++) {
              arr.push(
                <LinkButton
                  key={i}
                  onClick={() => {
                    pagingClick(i);
                  }}
                  className={page === i ? 'active' : ''}>
                  {i}
                </LinkButton>
              );
            }
            return arr;
          })()}
          {page < totalPages ? (
            <LinkButton
              aria-label='Go to next page'
              onClick={() => {
                let p = page;
                p++;
                pagingClick(p);
              }}>
              <BsArrowRight />
            </LinkButton>
          ) : (
            <span></span>
          )}
        </Paging>
      </>
    </>
  );
};

export default JobsTable;

import React from 'react';
import { PageRoutes } from 'routes';
import BreadCrumbs from './../components/atoms/BreadCrumbs';
import { ResourceTable } from './../components/organisms/ResourceTable';

export const Resources = () => {
  return (
    <>
      <BreadCrumbs>
        <a href={PageRoutes.Resources}>Resource Management</a>
      </BreadCrumbs>
      <ResourceTable />
    </>
  );
};

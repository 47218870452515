import React from 'react';
import styled from '@emotion/styled';
import { ReactChild, ReactElement, useEffect, useRef } from 'react';

type SliderProps = {
  children: ReactChild;
  open: boolean;
  width?: string;
};

type SliderWrapperProps = {
  open: boolean;
  width?: string;
};

const SliderWrapper = styled.div<SliderWrapperProps>(({ open, width = '40vw', theme }) => {
  return {
    background: `${open ? theme.colors.scrim : 'transparent'}`,
    width: `${open ? '100vw' : '0'}`,
    height: `${open ? '100vh' : '0'}`,
    position: 'fixed',
    zIndex: 100,
    top: '0',
    right: '0',
    transition: 'background 0.2s linear',

    '> ul, > div': {
      zIndex: 100,
      display: 'flex',
      flexDirection: 'column',
      position: 'fixed',
      top: '0',
      right: '0',
      width: '90vw',
      height: '100vh',
      fontSmoothing: 'antialiased',
      transformOrigin: '0% 0%',
      transform: `${open ? 'none' : 'translate(100%, 0)'}`,
      transition: 'transform 0.25s linear',
      [theme.mq['tablet']]: {
        width
      }
    }
  };
});

const Slider = ({ children, open, width }: SliderProps): ReactElement => {
  const sliderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!sliderRef.current) {
      return;
    }

    const focusableSliderElements: NodeListOf<HTMLElement> = sliderRef.current.querySelectorAll(
      'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select'
    );

    const elementTabIndex = open ? 0 : -1;
    focusableSliderElements.forEach(el => (el.tabIndex = elementTabIndex));
  }, [open]);

  return (
    <SliderWrapper ref={sliderRef} open={open} width={width}>
      {children}
    </SliderWrapper>
  );
};

export default Slider;

import React from 'react';
import { PageRoutes } from 'routes';
import JobsTable from '../components/organisms/JobsTable';
import BreadCrumbs from './../components/atoms/BreadCrumbs';

export const Jobs = () => {
  return (
    <>
      <BreadCrumbs>
        <a href={PageRoutes.Root}>Matter Management</a>
      </BreadCrumbs>
      <JobsTable />
    </>
  );
};

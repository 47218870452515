import styled from '@emotion/styled';

const Label = styled.label(({ theme }) => {
  return {
    marginBottom: '12px',
    fontWeight: 'normal',
    fontSize: theme.fontSizes.body1,
    lineHeight: '24px',
    minWidth: '120px',
    color: theme.colors.primaryText,

    [theme.mq.tablet]: {
      marginBottom: 0,
      marginRight: '42px'
    }
  };
});

export default Label;

import React from 'react';
import styled from '@emotion/styled';
import { ReactElement } from 'react';

type Props = {
  content: string;
  open: boolean;
  isSuccess?: boolean;
};

const ToastBaseElement = styled('div')`
  position: fixed;
  top: -100px;
  left: 0px;
  width: calc(100% - 0px);
  z-index: 1000;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 0 0 4px 4px;
  transition: top 1s ease-in-out;

  &.active {
    top: 0;
  }
`;

const ToastErrorElement = styled(ToastBaseElement)`
  background: linear-gradient(235deg, #ffba01, #ffe800);
  font-size: 18px;
  font-weight: 700;
  color: #555555;
`;

const ToastSuccessElement = styled(ToastBaseElement)`
  background: linear-gradient(235deg, #acdf87, #68bb59);
  font-size: 18px;
  font-weight: 700;
  color: #0f3923;
`;

const Toast = ({ content, open, isSuccess = false }: Props): ReactElement => {
  return isSuccess ? (
    <ToastSuccessElement className={open ? 'active' : ''}>{content}</ToastSuccessElement>
  ) : (
    <ToastErrorElement className={open ? 'active' : ''}>{content}</ToastErrorElement>
  );
};

export default Toast;

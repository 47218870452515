import styled from '@emotion/styled';
import React, { ReactElement } from 'react';
import Button from '../atoms/Button';

type Props = {
  title: string;
  content: string;
  open: boolean;
  confirm: () => void;
  cancel: () => void;
};

const ConfirmElement = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 101;
  display: none;
  &.active {
    display: block;
  }
`;

const ConfirmContent = styled('div')`
  width: 400px;
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  position: absolute;
  top: calc(50vh - 50px);
  left: calc(50% - 200px);
`;

const ButtonWrap = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;

const Toast = ({ title, content, open, confirm, cancel }: Props): ReactElement => {
  return (
    <ConfirmElement className={open ? 'active' : ''}>
      <ConfirmContent>
        <h3>{title}</h3>
        {content}
        <ButtonWrap>
          <Button type='button' secondary={true} onClick={cancel}>
            Cancel
          </Button>
          <Button type='submit' onClick={confirm}>
            Confirm
          </Button>
        </ButtonWrap>
      </ConfirmContent>
    </ConfirmElement>
  );
};

export default Toast;

import {
  EditSharedFolderInfo,
  SessionHostUserStatus,
  SharedFolder,
  SharedFolderAccessPermissionStatus,
  SharedFolderStatus
} from '../../../types/Jobs';
import React from 'react';
import { BsPencil } from 'react-icons/bs';
import dayjs from 'dayjs';
import { ShadedTable, LinkButton } from './styled';

type ComponentProps = {
  sharedFolder: SharedFolder;
  handleEdit: (data: EditSharedFolderInfo) => void;
};
const SharedFolderInfo: React.FC<ComponentProps> = ({ sharedFolder, handleEdit }) => {
  const remainingTime = (endTime: string | undefined): string => {
    if (!endTime) return '';
    const azAccessEndTime = dayjs(endTime).local();
    const currentTime = dayjs();
    const timeDifferenceInMinutes = azAccessEndTime.diff(currentTime, 'minute');
    const hours = Math.floor(timeDifferenceInMinutes / 60);
    const minutes = timeDifferenceInMinutes % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };
  return (
    <>
      <ShadedTable>
        <thead>
          <tr>
            <th>Persona</th>
            <th>VM Name</th>
            <th>User</th>
            <th>User Status</th>
            <th>Access Status</th>
            <th>VM Status</th>
            <th>Duration</th>
            <th>Remaining Time(HH:MM)</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {sharedFolder.accessPermissions && sharedFolder.accessPermissions.length > 0 && (
            <>
              {sharedFolder.accessPermissions.map((accessPermission, index) => (
                <tr key={index}>
                  <td>{accessPermission.sessionHost.persona}</td>
                  <td>{accessPermission.sessionHost.name}</td>
                  <td>{accessPermission.sessionHost.user?.name || ''}</td>
                  <td>{accessPermission.sessionHost.userStatus}</td>
                  <td>{accessPermission.status}</td>
                  <td>{accessPermission.sessionHost.status}</td>
                  <td>{accessPermission.durationInHours}</td>
                  <td>
                    {!accessPermission.azAccessEndTime
                      ? ''
                      : dayjs().isBefore(dayjs(accessPermission.azAccessEndTime).local())
                      ? remainingTime(accessPermission.azAccessEndTime)
                      : '00:00'}
                  </td>
                  <td>
                    {(accessPermission.sessionHost.status === 'Deployed' ||
                      accessPermission.sessionHost.status === 'Rescaled') &&
                      sharedFolder &&
                      (sharedFolder.status === SharedFolderStatus.DEPLOYED ||
                        sharedFolder.status === SharedFolderStatus.LOCKED) &&
                      (accessPermission.status === SharedFolderAccessPermissionStatus.GRANTED ||
                        accessPermission.status === SharedFolderAccessPermissionStatus.DISABLED) &&
                      accessPermission.sessionHost.userStatus !== SessionHostUserStatus.UNASSIGNED && (
                        <LinkButton
                          data-testid='svg-button'
                          onClick={() => {
                            handleEdit({
                              ...accessPermission,
                              sharedFolderId: sharedFolder.id
                            });
                          }}>
                          <BsPencil />
                        </LinkButton>
                      )}
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </ShadedTable>
    </>
  );
};

export default SharedFolderInfo;

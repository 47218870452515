import styled from '@emotion/styled';

export const Wrapper = styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  background: #f0f9ff;
  min-width: 720px;
`;

export const ContentContainer = styled('div')`
  display: flex;
  flex-direction: row;
  column-gap: 20px;
`;
export const SectionContainer = styled('div')`
  width: 50%;
  img: {
    width: 220px;
    background: #addfff40;
  }
`;
export const ErrorBox = styled('div')`
  display: block;
  padding: 80px 0px;

  h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 42px;
    font-weight: 700px;
    color: #160b38;
    margin-bottom: 15px;
  }

  p {
    font-family: 'Jost', sans-serif;
    font-size: 18px;
    font-weight: 600px;
    line-height: 1.6rem;
    color: #160b38;
  }
`;

export const SideBarWrapper = styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  background: #f0f9ff;
  min-width: 250px;
`;

export const SideBarContentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  column-gap: 20px;
`;
export const SideBarSectionContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const SideBarErrorBox = styled('div')`
  display: block;
  padding: 10px 10px;

  h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 42px;
    font-weight: 700px;
    color: #160b38;
  }

  p {
    font-family: 'Jost', sans-serif;
    font-size: 18px;
    font-weight: 600px;
    line-height: 1.6rem;
    color: #160b38;
  }
`;

import { IRoute } from './types';

export const PageRoutes: IRoute = {
  Root: '/',
  Jobs: 'jobs',
  ViewJob: '/viewjob/:id',
  CreateJob: '/createjob',
  Resources: '/resources',
  CreateVm: '/createvm',
  Evidence: '/viewjob/:jobId/evidence',
  Others: '*'
};

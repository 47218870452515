import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { BsDisplay, BsXLg } from 'react-icons/bs';
import Button from '../atoms/Button';
import CloseButton from '../atoms/CloseButton';
import DropDownSelector from '../atoms/DropDownSelector';
import IconCircle from '../atoms/IconCircle';
import Label from '../atoms/Label';
import Slider from '../atoms/Slider';
import { Resource, Scale } from './../../types/SessionHosts';

type Props = {
  machine: Resource;
  scaleVMOpen: boolean;
  scaleVM: (update: Scale) => void;
  closeScaleVM: () => void;
};

type Option = {
  name: string;
  id: string;
};

const sizes = [
  {
    name: 'Small',
    id: 'Small'
  },
  {
    name: 'Medium',
    id: 'Medium'
  },
  {
    name: 'Large',
    id: 'Large'
  }
];

const AccessWrapper = styled('div')`
  background: #efefef;
  padding: 60px 90px 40px 90px;

  h2 {
    text-align: center;
    font-size: 22px;
    margin: 15px 0 30px 0;
  }

  .details {
    margin: 20px 0 60px;
    padding: 0;
    list-style: none;

    li {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
    }

    strong {
      font-size: 14px;
    }
  }
`;

const FormField = styled('div')`
  margin-bottom: 15px;
`;

export const ScaleVM = ({ machine, scaleVMOpen, scaleVM, closeScaleVM }: Props) => {
  const { register, setValue, handleSubmit } = useForm();

  useEffect(() => {
    setValue('size', machine.size);
  }, []);

  const onSubmit = (data: any) => {
    scaleVM({
      id: machine.id,
      vmSize: data.scaleSize
    });
  };

  return (
    <Slider open={scaleVMOpen} width='500px'>
      <AccessWrapper>
        <div>
          <IconCircle className='xl'>
            <BsDisplay />
          </IconCircle>
          <h2>Scale VM</h2>
          <CloseButton onClick={closeScaleVM}>
            <BsXLg />
          </CloseButton>
          <ul className='details'>
            {machine.name !== undefined ? (
              <li>
                <strong>VM: </strong>
                <span>{machine.name}</span>
              </li>
            ) : (
              <li>
                <strong>ID: </strong>
                <span>{machine.id}</span>
              </li>
            )}
            <li>
              <strong>User: </strong>
              <span>{machine?.user?.name || ''}</span>
            </li>
            <li>
              <strong>Region: </strong>
              <span>{machine.region}</span>
            </li>
            <li>
              <strong>Size: </strong>
              <span>{machine.size}</span>
            </li>
            <li>
              <strong>Disk Size: </strong>
              <span>{machine.dataDiskSizeInGB}</span>
            </li>
            <li>
              <strong>Status: </strong>
              <span>{machine.status}</span>
            </li>
            <li>
              <strong>Created Date: </strong>
              <span>{dayjs.utc(machine.created).local().format('DD-MMM-YYYY')}</span>
            </li>
          </ul>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormField>
              <Label htmlFor='scaleSize'>Size</Label>
              <DropDownSelector
                defaultValue={machine!?.size}
                placeholder='Please select'
                ariaLabel='Scale VM Size'
                {...register('scaleSize', { required: true })}
                options={sizes!?.map((size: Option) => ({
                  label: size.name,
                  value: size.id
                }))}
              />
            </FormField>

            <Button type='submit'>Scale VM</Button>
          </form>
        </div>
      </AccessWrapper>
    </Slider>
  );
};

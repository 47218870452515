import { useMsal } from '@azure/msal-react';
import React, { ReactElement, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import Modal from '../atoms/Modal';
import Button from '../atoms/Button';

const timeout = process.env.REACT_APP_IDLE_TIMEOUT_MILLISECONDS
  ? Number(process.env.REACT_APP_IDLE_TIMEOUT_MILLISECONDS)
  : 300000;
const promptBeforeIdle = process.env.REACT_APP_IDLE_WARNING_MILLISECONDS
  ? Number(process.env.REACT_APP_IDLE_WARNING_MILLISECONDS)
  : 30000;

const SessionTimer = (): ReactElement => {
  const [remaining, setRemaining] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);

  const { instance } = useMsal();

  const onIdle = async () => {
    setOpen(false);
    message('logout');
    const currentAccount = instance.getActiveAccount();
    await instance.logoutRedirect({ account: currentAccount });
  };

  const onActive = () => {
    setOpen(false);
  };

  const onPrompt = () => {
    setOpen(true);
  };

  const onMessage = async () => {
    const currentAccount = instance.getActiveAccount();
    await instance.logoutRedirect({ account: currentAccount });
  };

  const handleStillHere = () => {
    activate();
  };

  const { getRemainingTime, activate, message } = useIdleTimer({
    onIdle,
    onActive,
    onMessage,
    onPrompt,
    timeout,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200,
    promptBeforeIdle,
    throttle: 500
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <>
      <Modal
        style={{
          display: open ? 'flex' : 'none'
        }}>
        <div className='modal-bg'></div>
        <div className='modal-body'>
          <h3>Session Timeout</h3>
          <p>Logging out in {remaining} seconds</p>
          <Button type='button' onClick={handleStillHere}>
            I&apos;m still here
          </Button>
        </div>
      </Modal>
    </>
  );
};

export { SessionTimer };

import { Resource } from './SessionHosts';

export type Job = {
  id: string;
  title: string;
  matterId: string;
  priority?: string;
  description?: string;
  region: string;
  status: string;
  created: string;
  lastModified: string;
  jobUsers: Array<JobUser>;
  sharedFolders: SharedFolder[];
  dataCollection: Resource;
};

export type JobUser = {
  id: string;
  user: User;
  type: string;
  created: string;
  lastModified: string;
};

export type User = {
  id: string;
  principalName: string;
  emailAddress: string;
  name: string;
  firstName: string;
  lastName: string;
};

export type SharedFolder = {
  id: string;
  identifier?: string;
  status: SharedFolderStatus;
  type: string;
  created?: string;
  createdByUser?: User;
  accessPermissions?: Array<AccessPermissions>;
  jobId?: string;
};

export type AccessPermissions = {
  id: string;
  sessionHostId: string;
  sessionHost: SessionHost;
  status: SharedFolderAccessPermissionStatus;
  durationInHours?: number;
  azAccessStartTime?: string;
  azAccessEndTime?: string;
};

export type SessionHost = {
  id: string;
  userId: string;
  matterId: string;
  region: Region;
  persona: string;
  user: User;
  jobId: string;
  size: Size;
  dataDiskSizeInGB: number;
  name: string;
  status: string;
  userStatus: SessionHostUserStatus;
  internetStatus: string;
  internetDurationInHours: number;
  timeZone: string;
  autoShutdownStatus: string;
  autoShutdownTime?: string;
  internetStartTime: string;
  internetEndTime: string;
  powerStatus: string;
  nextAutoShutdownTime: string;
  created: string;
  lastModified: string;
};

export enum Region {
  AUSTRALIA = 'Australia',
  UK = 'UK'
}

export enum Size {
  SMALL = 'Small',
  MEDIUM = 'Medium',
  LARGE = 'Large'
}

export type ShutdownTime = {
  hour: number;
  minute: number;
  second?: number;
  millisecond?: number;
  ticks?: number;
};

export type EditJobDetails = {
  id: string;
  title: string;
  priority?: string;
  description: string;
  status: string;
};

export type UpdateJobUsers = {
  primaryUserId?: string;
  secondaryUserIds?: Array<string>;
};

export type NotesSearch = {
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  totalCount: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  items: Array<Note>;
};

export type Note = {
  id: string;
  jobId: string;
  note: string;
  created: string;
  lastModified: string;
  createdByUser: User;
};

export type Item = {
  id: string;
  name: string;
};

export type Option = {
  name: string;
  id: string;
  matterId?: string;
};

export interface EditSharedFolderInfo extends AccessPermissions {
  sharedFolderId: string;
}

export enum SharedFolderStatus {
  DEPLOYING = 'Deploying',
  DEPLOYED = 'Deployed',
  FAILEDTODEPLOY = 'FailedToDeploy',
  REMOVING = 'Removing',
  REMOVED = 'Removed',
  FAILEDTOREMOVE = 'FailedToRemove',
  LOCKING = 'Locking',
  LOCKED = 'Locked',
  FAILEDTOLICK = 'FailedToLock'
}

export enum SharedFolderAccessPermissionStatus {
  DISABLED = 'Disabled',
  GRANTING = 'Granting',
  GRANTED = 'Granted',
  FAILEDTOGRANT = 'FailedToGrant',
  REVOKING = 'Revoking',
  FAILEDTOREVOKE = 'FailedToRevoke'
}

export enum SessionHostUserStatus {
  UNASSIGNED = 'Unassigned',
  REMOVING = 'Removing',
  FAILEDTOREMOVE = 'FailedToRemove',
  ASSIGNING = 'Assigning',
  ASSIGNED = 'Assigned',
  FAILEDTOASSIGN = 'FailedToAssign'
}

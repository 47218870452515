export const sizes = [
  {
    name: 'Small',
    id: 'small'
  },
  {
    name: 'Medium',
    id: 'medium'
  },
  {
    name: 'Large',
    id: 'large'
  }
];

export const VmTableTitle = {
  VM: 'VM',
  PERSONA: 'Persona',
  USER: 'User',
  REGION: 'Region',
  USER_STATUS: 'User Status',
  STATUS: 'Status',
  INTERNET_ACCESS: 'Internet',
  DETAILS: 'Details',
  ACTIONS: 'Actions'
};

import axios from 'axios';

export const updateSessionHostUser = async (sessionHostId: string, updatedUserId: string | null): Promise<any> => {
  const update = { userId: updatedUserId };
  const res = await axios.post(
    process.env.REACT_APP_API_BASE_URL + '/api/sessionhosts/' + sessionHostId + '/updateuser',
    update
  );
  return res.data;
};

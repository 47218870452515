import styled from '@emotion/styled';

export const ListContainer = styled('div')`
  background: #fff;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
  position: relative;
  padding: 40px;
  margin-bottom: 40px;
  min-height: 100px;
  max-height: 400px;
  overflow: auto;
`;

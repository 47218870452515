import axios from 'axios';

export const createEvidence = async (jobId: string, identifier: string): Promise<any> => {
  const body = {
    jobId: jobId,
    type: 'EvidenceData',
    Identifier: identifier
  };
  const res = await axios.post(process.env.REACT_APP_API_BASE_URL + '/api/sharedFolders', body);
  return res.data;
};

import React from 'react';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';
import { BsDisplay, BsXLg } from 'react-icons/bs';
import Button from '../atoms/Button';
import CloseButton from '../atoms/CloseButton';
import IconCircle from '../atoms/IconCircle';
import Label from '../atoms/Label';
import Slider from '../atoms/Slider';
import TextArea from '../atoms/TextArea';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Resource } from './../../types/SessionHosts';

dayjs.extend(utc);

type Props = {
  machine: Resource;
  deleteVMOpen: boolean;
  deleteVM: (reason: string) => void;
  closeDeleteVM: () => void;
};

const AccessWrapper = styled('div')`
  background: #efefef;
  padding: 60px 90px 40px 90px;

  h2 {
    text-align: center;
    font-size: 22px;
    margin: 15px 0 30px 0;
  }

  .details {
    margin: 20px 0 60px;
    padding: 0;
    list-style: none;

    li {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
    }

    strong {
      font-size: 14px;
    }
  }
`;

const FormField = styled('div')`
  margin-bottom: 15px;
`;

const InlineError = styled.div(() => ({
  color: '#CC0000',
  marginTop: '5px',
  width: '100%',
  marginLeft: 0
}));

export const DeleteVM = ({ machine, deleteVMOpen, deleteVM, closeDeleteVM }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  return (
    <Slider open={deleteVMOpen} width='500px'>
      <AccessWrapper>
        <div>
          <IconCircle className='xl'>
            <BsDisplay />
          </IconCircle>
          <h2>Delete VM</h2>
          <CloseButton aria-label='Close' onClick={closeDeleteVM}>
            <BsXLg />
          </CloseButton>
          <ul className='details'>
            {machine.name !== undefined ? (
              <li>
                <strong>VM: </strong>
                <span>{machine.name}</span>
              </li>
            ) : (
              <li>
                <strong>ID: </strong>
                <span>{machine.id}</span>
              </li>
            )}
            <li>
              <strong>User: </strong>
              <span>{machine?.user?.name || ''}</span>
            </li>
            <li>
              <strong>Region: </strong>
              <span>{machine.region}</span>
            </li>
            <li>
              <strong>Size: </strong>
              <span>{machine.size}</span>
            </li>
            <li>
              <strong>Disk Size: </strong>
              <span>{machine.dataDiskSizeInGB}</span>
            </li>
            <li>
              <strong>Status: </strong>
              <span>{machine.status}</span>
            </li>
            <li>
              <strong>Created Date: </strong>
              <span>{dayjs.utc(machine.created).local().format('DD-MMM-YYYY')}</span>
            </li>
          </ul>

          <form onSubmit={handleSubmit((data: any) => deleteVM(data))}>
            <FormField>
              <Label htmlFor='reason'>Reason for deletion:</Label>
              <TextArea rows={4} key='reason' id='reason' {...register('reason', { required: true })}></TextArea>
              {errors.reason && <InlineError>This field is required</InlineError>}
            </FormField>

            <Button type='submit'>Delete VM</Button>
          </form>
        </div>
      </AccessWrapper>
    </Slider>
  );
};

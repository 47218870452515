import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { CreateJob } from '../pages/CreateJob';
import { ViewJob } from '../pages/ViewJob';
import { Jobs } from '../pages/Jobs';
import { Resources } from '../pages/Resources';
import { CreateVM } from '../pages/CreateVM';
import { Evidence } from '../pages/Evidence';
import { PageRoutes } from './routes.constants';

export const Router: React.FC<Record<string, never>> = () => {
  return (
    <Routes>
      <Route path={PageRoutes.Root} element={<Jobs />} />
      <Route path={PageRoutes.Jobs} element={<Jobs />} />
      <Route path={PageRoutes.ViewJob} element={<ViewJob />} />
      <Route path={PageRoutes.CreateJob} element={<CreateJob />} />
      <Route path={PageRoutes.Resources} element={<Resources />} />
      <Route path={PageRoutes.CreateVm} element={<CreateVM />} />
      <Route path={PageRoutes.Evidence} element={<Evidence />} />
    </Routes>
  );
};

import React from 'react';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';
import { BsXLg, BsFillFileEarmarkMedicalFill } from 'react-icons/bs';
import Button from '../atoms/Button';
import CloseButton from '../atoms/CloseButton';
import IconCircle from '../atoms/IconCircle';
import Label from '../atoms/Label';
import Slider from '../atoms/Slider';
import TextArea from '../atoms/TextArea';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { SharedFolder } from './../../types/Jobs';

dayjs.extend(utc);

type Props = {
  evidence: SharedFolder;
  deletionOpen: boolean;
  deleteEvidence: (id: string, reason: string) => void;
  closeDeletion: () => void;
};

const AccessWrapper = styled('div')`
  background: #efefef;
  padding: 60px 90px 40px 90px;

  h2 {
    text-align: center;
    font-size: 22px;
    margin: 15px 0 30px 0;
  }

  .details {
    margin: 20px 0 60px;
    padding: 0;
    list-style: none;

    li {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
    }

    strong {
      font-size: 14px;
    }
  }
`;

const FormField = styled('div')`
  margin-bottom: 15px;
`;

const InlineError = styled.div(() => ({
  color: '#CC0000',
  marginTop: '5px',
  width: '100%',
  marginLeft: 0
}));

export const DeleteEvidence = ({ evidence, deletionOpen, deleteEvidence, closeDeletion }: Props) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();

  const handleCloseSideBar = () => {
    reset();
    closeDeletion();
  };

  const onSubmit = (data: any) => {
    deleteEvidence(evidence.id!, data.reason);
    reset();
  };

  return (
    <Slider open={deletionOpen} width='500px'>
      <AccessWrapper>
        <div>
          <IconCircle className='xl'>
            <BsFillFileEarmarkMedicalFill />
          </IconCircle>
          <h2>Delete Evidence</h2>
          <CloseButton aria-label='Close' onClick={handleCloseSideBar}>
            <BsXLg />
          </CloseButton>
          <ul className='details'>
            <li>
              <strong>Evidence Identifier: </strong>
              <span>{evidence?.identifier || ''}</span>
            </li>
            <li>
              <strong>Status: </strong>
              <span>{evidence?.status || ''}</span>
            </li>
            <li>
              <strong>Created Date: </strong>
              <span>
                {dayjs
                  .utc(evidence?.created)
                  .local()
                  .format('DD-MMM-YYYY') || ''}
              </span>
            </li>
          </ul>

          <form onSubmit={handleSubmit(onSubmit)}>
            <FormField>
              <Label htmlFor='reason'>Reason for deletion:</Label>
              <TextArea rows={4} key='reason' id='reason' {...register('reason', { required: true })}></TextArea>
              {errors.reason && <InlineError>This field is required</InlineError>}
            </FormField>

            <Button type='submit'>Delete Evidence</Button>
          </form>
        </div>
      </AccessWrapper>
    </Slider>
  );
};

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
export const reactPlugin = new ReactPlugin();
export const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_APPLICATION_INSIGHTS,
    enableAutoRouteTracking: true,
    enableRequestHeaderTracking: true,
    extensions: [reactPlugin]
  }
});
appInsights.loadAppInsights();

import React from 'react';
import styled from '@emotion/styled';
import { BsInbox, BsChevronRight } from 'react-icons/bs';
import { JobForm } from '../components/organisms/JobForm';
import BreadCrumbs from '../components/atoms/BreadCrumbs';
import { PageRoutes } from 'routes';

const IconCircle = styled('div')`
  font-size: 40px;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin: 0 auto;
  color: #fff;
  background: ${props => props.theme.colors.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
`;
const NarrowContent = styled('div')`
  max-width: 500px;
  margin: 40px auto 0;

  h1 {
    text-align: center;
    font-size: 44px;
    margin-top: 15px;
    font-weight: 600;
  }
  h3 {
    text-align: center;
  }
  h3 + button {
    margin: 0 auto;
    background: #fff;
  }
`;

export const CreateJob = () => {
  return (
    <>
      <BreadCrumbs>
        <a href={PageRoutes.Root}>Matter Management</a> <BsChevronRight /> <span>Create Matter</span>
      </BreadCrumbs>
      <NarrowContent>
        <IconCircle>
          <BsInbox />
        </IconCircle>
        <h1>Create a Matter</h1>
        <JobForm />
      </NarrowContent>
    </>
  );
};

import styled from '@emotion/styled';

const LinkButton = styled('button')`
  border: 0;
  background: transparent;
  color: ${props => props.theme.colors.primary};
  font-size: ${props => props.theme.fontSizes.body2};
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export default LinkButton;

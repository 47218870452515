import styled from '@emotion/styled';
import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { BsArrowLeft, BsArrowRight, BsChevronDown, BsChevronUp, BsDisplay, BsPlus } from 'react-icons/bs';
import TabButton from '../atoms/TabButton';
import { iUserContext, UserContext } from '../RootComponent';
import { Spinner } from '../molecules/Spinner';
import Toast from '../atoms/Toast';
import { DeleteVM } from './DeleteVM';
import { UpdateInternetAccess } from './InternetAccess';
import { UpdateShutdown } from './UpdateShutdown';
import { RedeployVM } from './RedeployVM';
import { ScaleVM } from './ScaleVM';
import { VMDetails } from './VMDetails';
import { Resource, Scale, ShutdownUpdate } from './../../types/SessionHosts';
import { AddVMNote } from './AddVMNote';
import useOnClickOutside from 'hooks/useClickOnOutside';
import { PageRoutes } from 'routes';
import ResourceItem from '../molecules/ResourceItem/ResourceItem';
import { updateSessionHostUser } from 'api/updateSessionHostUser';
import { GENERIC_ERROR_MESSAGE, noneOption, regions, userStatus } from '../../constants';
import { AssignUser } from './AssignUser';
import { Update, updateSessionHostInternetAccess } from 'api/updateSessionHostInternetAccess';
import { UpdateFriendlyName } from './UpdateFriendlyName';
import { updateVMFriendlyName } from 'api/updateVMFriendlyName';
import { personas } from './VMForm/utils';
import { Item, Option } from './../../types/Jobs';
import { SearchQuery, getSessionHosts } from 'api/getSessionHosts';
import { SearchBar } from '../atoms/SearchBar/SearchBar';
import DropDownSelector from '../atoms/DropDownSelector';
import TextInputAutoComplete from '../molecules/TextInputAutoComplete';
import Button from '../atoms/Button';

const HeadingFlexContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;

  h1 {
    font-size: 32px;
    margin: 0;

    svg {
      margin-bottom: 5px;
    }
  }

  button {
    display: flex;
  }

  div {
    display: flex;
    align-items: baseline;
  }
`;

const StyledTable = styled('table')`
  width: 100%;
  border-spacing: 0 15px;
  table-layout: fixed;
  font-size: 90%;

  thead th {
    padding: 5px 15px;
    white-space: nowrap;
  }

  thead th svg {
    margin-left: 10px;
  }

  thead th .active svg {
    color: ${props => props.theme.colors.secondary};
  }

  thead th:nth-of-type(1),
  thead th:nth-of-type(2),
  thead th:nth-of-type(3) {
    text-align: left;
  }

  tbody tr {
    box-shadow: 2px 2px 5px rgb(0 0 0 / 10%);
  }
  tbody td {
    text-align: center;
    padding: 15px;
    background: #fff;
    white-space: nowrap;
    overflow: hidden;
  }

  tbody tr:first-of-type td {
    overflow: visible;
  }
  tbody tr td:last-of-type {
    overflow: inherit;
    text-overflow: inherit;
  }
`;

const TitleWithIcon = styled('h1')`
  display: flex;
  align-items: end;

  svg {
    margin-right: 15px;
  }
`;

const PrimaryLink = styled('a')`
  margin-left: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  min-width: 100px;
  min-height: 40px;
  border-radius: 6px;
  padding: 9px 20px;
  font-size: ${props => props.theme.fontSizes.body2};
  font-weight: bold;
  background-color: ${props => props.theme.colors.primary};
  color: white;
  border: 1px solid transparent;

  svg {
    margin-right: 10px;
  }
`;
const LinkButton = styled('button')`
  border: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
`;

const Paging = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;

  > span {
    display: block;
    width: 16.325px;
    margin: 0 15px;
  }

  button {
    margin: 0 15px;
    font-weight: bold;
  }
  .active {
    color: ${props => props.theme.colors.secondary};
    text-decoration: underline;
  }
`;

const VerticalCenter = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 200px);
`;

export const ResourceTable = () => {
  const { user } = useContext<iUserContext>(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [resources, setResources] = useState<Resource[] | null>(null);
  const [pageTitle, setPageTitle] = useState('All VMs');
  const [filter, setFilter] = useState<string>('all');
  const [totalPages, setTotalPages] = useState<number>(1);
  const [sort, setSort] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [optionsClickedIndex, setOptionsClickedIndex] = useState<number | null>(null);
  const [internetAccessOpenIndex, setInternetAccessOpenIndex] = useState<number | null>(null);
  const [updateShutdownOpenIndex, setUpdateShutdownOpenIndex] = useState<number | null>(null);
  const [deleteVMOpenIndex, setDeleteVMOpenIndex] = useState<number | null>(null);
  const [scaleVMOpenIndex, setScaleVMOpenIndex] = useState<number | null>(null);
  const [redeployVMOpenIndex, setRedeployVMOpenIndex] = useState<number | null>(null);
  const [notesOpenIndex, setNotesOpenIndex] = useState<number | null>(null);
  const [vmDetailsOpenIndex, setVMDetailsOpenIndex] = useState<number | null>(null);
  const [assignUserOpenIndex, setAssignUserOpenIndex] = useState<number | null>(null);
  const [updateFriendlyNameOpenIndex, setUpdateFriendlyNameOpenIndex] = useState<number | null>(null);
  const [isToastForSuccess, setIsToastForSuccess] = useState(false);
  const [toastActive, setToastActive] = useState(false);
  const [toastContent, setToastContent] = useState('');
  const [users, setUsers] = useState<Item[]>();
  const [searchVmName, setSearchVmName] = useState<string | null>(null);
  const [searchPersona, setSearchPersona] = useState<string | null>(null);
  const [analystId, setAnalystId] = useState<string | null>(null);
  const [searchRegion, setSearchRegion] = useState<string | null>(null);
  const [searchUserStatus, setSearchUserStatus] = useState<string | null>(null);
  const [isClearValues, setIsClearValues] = useState<boolean>(false);

  const personasWithNone = [...personas, noneOption];

  const regionWithNone = [...regions, noneOption];
  const userStatusWithNone = [...userStatus, noneOption];

  const elementRef = useRef<HTMLUListElement>(null);
  useOnClickOutside(elementRef, () => {
    setOptionsClickedIndex(null);
  });

  const getResources = async (
    page?: number,
    sort?: string,
    userId?: string | null,
    name?: string | null,
    persona?: string | null,
    region?: string | null,
    userStatus?: string | null
  ) => {
    setIsLoading(true);

    const request: SearchQuery = {
      pageNumber: page,
      pageSize: 10
    };

    sort && (request.orderBy = sort);
    userId && userId.length > 0 && (request.userId = userId);
    name && name.length > 0 && (request.name = name);
    persona && persona.length > 0 && persona !== 'none' && (request.persona = persona);
    region && region.length > 0 && region !== 'none' && (request.region = region);
    userStatus && userStatus.length > 0 && userStatus !== 'none' && (request.userStatus = userStatus);

    const resourceData = await getSessionHosts(request);

    setResources(resourceData.items);
    setTotalPages(resourceData.totalPages);
    setPage(resourceData.pageNumber);
    setIsLoading(false);
    setOptionsClickedIndex(null);
  };

  useEffect(() => {
    getResources(page, sort, analystId);
  }, []);

  const filterClick = (filterBy: string) => {
    let title;
    switch (filterBy) {
      case 'all':
        title = 'All VMs';
        setAnalystId(null);
        getResources(1, sort, null);
        break;
      case 'my':
        title = 'My VMs';
        setAnalystId(user.id);
        getResources(1, sort, user.id);
        break;
      default:
        title = '';
    }
    setPageTitle(title);
    setFilter(filterBy);
  };

  const sortClick = (sortBy: string) => {
    setSort(sortBy);
    getResources(page, sortBy, analystId);
  };

  const pagingClick = (page: number) => {
    setPage(page);
    getResources(page, sort, analystId);
  };

  const updateInternetAccess = async (id: string, update: Update) => {
    try {
      await updateSessionHostInternetAccess(id, update);
      setIsToastForSuccess(true);
      addToast('The request to update access permission sent successfully.');
    } catch (error: any) {
      addToast(error.response.data.detail || GENERIC_ERROR_MESSAGE);
    }
    setInternetAccessOpenIndex(null);
    getResources(page, sort, analystId);
  };

  const updateShutdown = async (update: ShutdownUpdate) => {
    const id = resources![updateShutdownOpenIndex ? updateShutdownOpenIndex : 0].id;

    await axios
      .post(process.env.REACT_APP_API_BASE_URL + '/api/sessionhosts/' + id + '/updateshutdown', update)
      .catch(error => {
        addToast(error.response.data.detail);
      });

    setUpdateShutdownOpenIndex(null);
    getResources(page, sort, analystId);
  };

  const deleteVM = async (detail: any) => {
    const id = resources![deleteVMOpenIndex ? deleteVMOpenIndex : 0].id;

    await axios
      .delete(process.env.REACT_APP_API_BASE_URL + '/api/sessionhosts/' + id, { data: { reason: detail.reason } })
      .catch(error => {
        addToast(error.response.data.detail);
      });

    setDeleteVMOpenIndex(null);
    getResources(page, sort, analystId);
  };

  const scaleVM = async (update: Scale) => {
    const id = resources![scaleVMOpenIndex ? scaleVMOpenIndex : 0].id;

    const body = {
      size: update.vmSize,
      dataDiskSizeInGB: update.vmDiskSize
    };

    await axios.post(process.env.REACT_APP_API_BASE_URL + '/api/sessionhosts/' + id + '/rescale', body).catch(error => {
      addToast(error.response.data.detail);
    });

    setScaleVMOpenIndex(null);
    getResources(page, sort, analystId);
  };

  const redeployVM = async () => {
    const id = resources![redeployVMOpenIndex ? redeployVMOpenIndex : 0].id;

    await axios.post(process.env.REACT_APP_API_BASE_URL + '/api/sessionhosts/' + id + '/redeploy').catch(error => {
      addToast(error.response.data.detail);
    });
    setRedeployVMOpenIndex(null);
    getResources(page, sort, analystId);
  };

  const addNote = async (note: any) => {
    const id = resources![notesOpenIndex ? notesOpenIndex : 0].id;
    setNotesOpenIndex(null);
    const data = {
      note: note.note
    };
    await axios.post(process.env.REACT_APP_API_BASE_URL + '/api/sessionhosts/' + id + '/notes', data).catch(error => {
      addToast(error.response.data.detail);
    });
    getResources(page, sort, analystId);
  };

  const assignUser = async (sessionHostId: string, updatedUserId: string | null) => {
    setAssignUserOpenIndex(null);
    try {
      await updateSessionHostUser(sessionHostId, updatedUserId);
      setIsToastForSuccess(true);
      addToast('The request to update session host user sent successfully.');
    } catch (error: any) {
      if (!error.response.data && error.message.includes('Request failed with status code 404')) {
        addToast(GENERIC_ERROR_MESSAGE);
      } else {
        error.response.data && addToast(error.response.data?.detail);
      }
    }
  };

  const updateFriendlyName = async (id: string, friendlyName: string) => {
    setUpdateFriendlyNameOpenIndex(null);
    const update = { friendlyName };
    try {
      await updateVMFriendlyName(id, update);
      setIsToastForSuccess(true);
      addToast('The request to update VM Friendly Name sent successfully.');
    } catch (error: any) {
      addToast(error.response.data.detail || GENERIC_ERROR_MESSAGE);
    }

    getResources(page, sort, analystId);
  };

  const optionsToggle = (index: number) => {
    optionsClickedIndex === index ? setOptionsClickedIndex(null) : setOptionsClickedIndex(index);
  };

  const addToast = (content?: string) => {
    setToastActive(true);
    setToastContent(content ? content : 'An error has occurred, please try again later');
    setTimeout(() => {
      setToastActive(false);
      setToastContent('');
      setIsToastForSuccess(false);
    }, 5000);
  };

  const getUsers = async (search: string) => {
    const userData = await axios.get(process.env.REACT_APP_API_BASE_URL + '/api/users/search?name=' + search);
    setUsers(userData.data.users);
  };

  return (
    <>
      <>
        <Toast open={toastActive} content={toastContent} isSuccess={isToastForSuccess}></Toast>
        <HeadingFlexContainer>
          <TitleWithIcon>
            <BsDisplay /> {pageTitle}
          </TitleWithIcon>
          <div>
            {
              <TabButton
                onClick={() => {
                  filterClick('all');
                }}
                className={filter === 'all' ? 'active' : ''}>
                All VMs
              </TabButton>
            }
            <TabButton
              onClick={() => {
                filterClick('my');
              }}
              className={filter === 'my' ? 'active' : ''}>
              My VMs
            </TabButton>
            <PrimaryLink href={PageRoutes.CreateVm}>
              <BsPlus /> Add new VM
            </PrimaryLink>
          </div>
        </HeadingFlexContainer>
        <StyledTable>
          <thead>
            <tr>
              <th style={{ textAlign: 'center' }}>
                VM
                <LinkButton
                  aria-label='Sort by VM name'
                  onClick={() => {
                    sortClick('name ' + (sort === 'name asc' ? 'desc' : 'asc'));
                  }}
                  className={sort.indexOf('name') > -1 ? 'active' : ''}>
                  {' '}
                  {sort === 'name asc' ? <BsChevronUp /> : <BsChevronDown />}
                </LinkButton>
              </th>
              <th style={{ width: '140px' }}>
                Persona
                <LinkButton
                  aria-label='Sort by persona'
                  onClick={() => {
                    sortClick('persona ' + (sort === 'persona asc' ? 'desc' : 'asc'));
                  }}
                  className={sort.indexOf('persona') > -1 ? 'active' : ''}>
                  {' '}
                  {sort === 'persona asc' ? <BsChevronUp /> : <BsChevronDown />}
                </LinkButton>
              </th>
              <th style={{ textAlign: 'center' }}>
                User
                <LinkButton
                  aria-label='Sort by user'
                  onClick={() => {
                    sortClick('user ' + (sort === 'user asc' ? 'desc' : 'asc'));
                  }}
                  className={sort.indexOf('user') > -1 ? 'active' : ''}>
                  {' '}
                  {sort === 'user asc' ? <BsChevronUp /> : <BsChevronDown />}
                </LinkButton>
              </th>
              <th style={{ width: '120px' }}>
                Region
                <LinkButton
                  aria-label='Sort by region'
                  onClick={() => {
                    sortClick('region ' + (sort === 'region asc' ? 'desc' : 'asc'));
                  }}
                  className={sort.indexOf('region') > -1 ? 'active' : ''}>
                  {' '}
                  {sort === 'region asc' ? <BsChevronUp /> : <BsChevronDown />}
                </LinkButton>
              </th>
              <th style={{ width: '180px' }}>
                User Status
                <LinkButton
                  aria-label='Sort by user status'
                  onClick={() => {
                    sortClick('status ' + (sort === 'userStatus asc' ? 'desc' : 'asc'));
                  }}
                  className={sort.indexOf('userStatus') > -1 ? 'active' : ''}>
                  {' '}
                  {sort === 'userStatus asc' ? <BsChevronUp /> : <BsChevronDown />}
                </LinkButton>
              </th>
              <th style={{ width: '160px' }}>
                Status
                <LinkButton
                  aria-label='Sort by status'
                  onClick={() => {
                    sortClick('status ' + (sort === 'status asc' ? 'desc' : 'asc'));
                  }}
                  className={sort.indexOf('status') > -1 ? 'active' : ''}>
                  {' '}
                  {sort === 'status asc' ? <BsChevronUp /> : <BsChevronDown />}
                </LinkButton>
              </th>
              <th style={{ width: '140px' }}>
                Internet
                <LinkButton
                  aria-label='Sort by status'
                  onClick={() => {
                    sortClick('status ' + (sort === 'status asc' ? 'desc' : 'asc'));
                  }}
                  className={sort.indexOf('status') > -1 ? 'active' : ''}>
                  {' '}
                  {sort === 'status asc' ? <BsChevronUp /> : <BsChevronDown />}
                </LinkButton>
              </th>
              <th style={{ width: '6%' }}>Details</th>
              <th style={{ width: '7%' }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <SearchBar
                  onSearch={value => {
                    setIsClearValues(false);
                    setSearchVmName(value);
                    getResources(page, sort, analystId, value, searchPersona, searchRegion, searchUserStatus);
                  }}
                  value={searchVmName}
                  isResetValue={isClearValues}
                />
              </td>
              <td style={{ padding: '0px' }}>
                <DropDownSelector
                  value={searchPersona || ''}
                  name='persona'
                  onChange={e => {
                    setSearchPersona(e.target.value);
                    getResources(page, sort, analystId, searchVmName, e.target.value, searchRegion, searchUserStatus);
                  }}
                  placeholder='Please select'
                  ariaLabel='Persona'
                  options={personasWithNone?.map((persona: Option) => ({
                    label: persona.name,
                    value: persona.id
                  }))}
                  styling={{ width: '100%', fontSize: '14px', height: '30px', svgWidth: '12%', svgHeight: '50%' }}
                />
              </td>
              <td>
                <TextInputAutoComplete
                  setSelection={true}
                  onSetOption={value => {
                    setIsClearValues(false);
                    setAnalystId(value);
                    getResources(page, sort, value, searchVmName, searchPersona, searchRegion, searchUserStatus);
                  }}
                  searchResults={users}
                  searchValue={getUsers}
                  changeSelection={() => {
                    setUsers([] as any);
                    setAnalystId(null);
                    getResources(page, sort, null, searchVmName, searchPersona, searchRegion, searchUserStatus);
                  }}
                  ariaLabel='Analyst'
                  isBtnIconEnabled={true}
                  value={analystId}
                  isResetValue={isClearValues}
                  disabled={filter === 'my'}
                  styling={{ width: '90%', height: '100%', inputHeight: '30px', fontSize: '14px' }}
                />
              </td>
              <td style={{ padding: '0px' }}>
                <DropDownSelector
                  value={searchRegion || ''}
                  name='region'
                  onChange={e => {
                    setSearchRegion(e.target.value);
                    getResources(page, sort, analystId, searchVmName, searchPersona, e.target.value, searchUserStatus);
                  }}
                  placeholder='Please select'
                  ariaLabel='Region'
                  options={regionWithNone?.map((region: Option) => ({
                    label: region.name,
                    value: region.id
                  }))}
                  styling={{ width: '100%', fontSize: '14px', height: '30px', svgWidth: '6%', svgHeight: '50%' }}
                />
              </td>
              <td style={{ padding: '0px 0px 0px 15px' }}>
                <DropDownSelector
                  value={searchUserStatus || ''}
                  name='userStatus'
                  onChange={e => {
                    setSearchUserStatus(e.target.value);
                    getResources(page, sort, analystId, searchVmName, searchPersona, searchRegion, e.target.value);
                  }}
                  placeholder='Please select'
                  ariaLabel='UserStatus'
                  options={userStatusWithNone?.map((us: Option) => ({
                    label: us.name,
                    value: us.id
                  }))}
                  styling={{ width: '95%', fontSize: '14px', height: '30px', svgWidth: '12%', svgHeight: '50%' }}
                />
              </td>

              {Array(3)
                .fill('')
                .map((_, idx) => (
                  <td key={idx}></td>
                ))}
              <td style={{ padding: '0px 5px 0px 0px' }}>
                <Button
                  minWidth='100%'
                  onClick={() => {
                    setSearchPersona(null);
                    setSearchVmName(null);
                    setSearchRegion(null);
                    setSearchUserStatus(null);
                    setIsClearValues(true);
                    setUsers([] as any);
                    setAnalystId(null);
                    getResources(page, sort);
                  }}>
                  Clear
                </Button>
              </td>
            </tr>
            {!isLoading ? (
              resources!?.map((resource: Resource, index: number) => (
                <ResourceItem
                  key={index}
                  index={index}
                  resource={resource}
                  toggleAction={optionsToggle}
                  optionsClickedIndex={optionsClickedIndex}
                  ref={elementRef}
                  handleClickDetails={setVMDetailsOpenIndex}
                  handleClickNotes={setNotesOpenIndex}
                  handleClickInternetAccess={setInternetAccessOpenIndex}
                  handleUpdateShutdown={setUpdateShutdownOpenIndex}
                  handleScaleVM={setScaleVMOpenIndex}
                  handleRedeployVM={setRedeployVMOpenIndex}
                  handleDelteVM={setDeleteVMOpenIndex}
                  handleAssignUser={setAssignUserOpenIndex}
                  handleUpdateFriendlyName={setUpdateFriendlyNameOpenIndex}
                />
              ))
            ) : (
              <tr>
                <td style={{ background: 'transparent' }}></td>
                <td style={{ background: 'transparent' }}></td>
                <td style={{ background: 'transparent' }}></td>
                <td style={{ background: 'transparent' }}>
                  <VerticalCenter>
                    <Spinner />
                  </VerticalCenter>
                </td>
                <td style={{ background: 'transparent' }}></td>
                <td style={{ background: 'transparent' }}></td>
                <td style={{ background: 'transparent' }}></td>
                <td style={{ background: 'transparent' }}></td>
              </tr>
            )}
          </tbody>
        </StyledTable>
        {resources!?.map((resource: Resource, index: number) => (
          <div key={index}>
            <VMDetails
              sessionHostId={resource.id}
              vmDetailsOpen={vmDetailsOpenIndex === index ? true : false}
              closeVMDetails={() => setVMDetailsOpenIndex(null)}
            />
            <AddVMNote
              sessionHostId={resource.id}
              addNoteOpen={notesOpenIndex === index ? true : false}
              addNote={addNote}
              closeAddNote={() => {
                setNotesOpenIndex(null);
              }}
            />
            <UpdateInternetAccess
              sessionHost={resource}
              updateSessionHostOpen={internetAccessOpenIndex === index ? true : false}
              updateInternetAccess={updateInternetAccess}
              closeUpdateSessionHost={() => {
                setInternetAccessOpenIndex(null);
              }}
            />
            <UpdateShutdown
              resource={resource}
              updateShutdownOpen={updateShutdownOpenIndex === index ? true : false}
              updateShutdown={updateShutdown}
              closeUpdateShutdown={() => {
                setUpdateShutdownOpenIndex(null);
              }}
            />

            <ScaleVM
              machine={resource}
              scaleVMOpen={scaleVMOpenIndex === index ? true : false}
              scaleVM={scaleVM}
              closeScaleVM={() => {
                setScaleVMOpenIndex(null);
              }}
            />
            <RedeployVM
              machine={resource}
              redeployVMOpen={redeployVMOpenIndex === index ? true : false}
              redeployVM={redeployVM}
              closeRedeployVM={() => {
                setRedeployVMOpenIndex(null);
              }}
            />

            <DeleteVM
              machine={resource}
              deleteVMOpen={deleteVMOpenIndex === index ? true : false}
              deleteVM={deleteVM}
              closeDeleteVM={() => {
                setDeleteVMOpenIndex(null);
              }}
            />
            <AssignUser
              sessionHostId={resource.id}
              assignUserOpen={assignUserOpenIndex === index ? true : false}
              assignUser={assignUser}
              closeAssignUser={() => {
                setAssignUserOpenIndex(null);
              }}
            />
            <UpdateFriendlyName
              machine={resource}
              updateFriendlyNameOpen={updateFriendlyNameOpenIndex === index ? true : false}
              updateFriendlyName={updateFriendlyName}
              closeUpdateFriendlyName={() => {
                setUpdateFriendlyNameOpenIndex(null);
              }}
            />
          </div>
        ))}
        <Paging>
          {page > 1 ? (
            <LinkButton
              aria-label='Go to previous page'
              onClick={() => {
                let p = page;
                p--;
                pagingClick(p);
              }}>
              <BsArrowLeft />
            </LinkButton>
          ) : (
            <span></span>
          )}
          {(() => {
            const arr = [];
            for (let i = 1; i <= totalPages; i++) {
              arr.push(
                <LinkButton
                  key={i}
                  onClick={() => {
                    pagingClick(i);
                  }}
                  className={page === i ? 'active' : ''}>
                  {i}
                </LinkButton>
              );
            }
            return arr;
          })()}
          {page < totalPages ? (
            <LinkButton
              aria-label='Go to next page'
              onClick={() => {
                let p = page;
                p++;
                pagingClick(p);
              }}>
              <BsArrowRight />
            </LinkButton>
          ) : (
            <span></span>
          )}
        </Paging>
      </>
    </>
  );
};

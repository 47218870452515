import Button from '../../components/atoms/Button';
import React from 'react';
import { ContentContainer, ErrorBox, SectionContainer, Wrapper } from './styled';
import NotFound from '../../assets/notFound.png';
import { useNavigate } from 'react-router-dom';
import { PageRoutes } from 'routes';

export type ComponentProps = {
  errorMessage: string;
};

const Error: React.FC<ComponentProps> = ({ errorMessage }) => {
  const navigate = useNavigate();
  const backToHome = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate(PageRoutes.Root);
  };

  return (
    <Wrapper>
      <ContentContainer>
        <SectionContainer>
          <ErrorBox>
            <h2>Oooops!</h2>
            <p>{errorMessage || 'Something went wrong'}</p>
          </ErrorBox>
          <Button onClick={backToHome} minWidth='300px' minHeight='55px'>
            Back to Home
          </Button>
        </SectionContainer>
        <SectionContainer>
          <img src={NotFound} alt='Not Found' width='420' />
        </SectionContainer>
      </ContentContainer>
    </Wrapper>
  );
};

export default Error;

import React from 'react';
import styled from '@emotion/styled';
import { forwardRef, ReactElement } from 'react';

import { ReactComponent as DownArrow } from './../icons/down.svg';

const StyledContainer = styled.div<{ styling?: Record<string, string> }>(({ theme, styling }) => ({
  position: 'relative',
  maxWidth: '720px',
  width: styling ? styling.width : '100%',

  svg: {
    position: 'absolute',
    top: '8px',
    right: '10px',
    pointerEvents: 'none',
    width: styling?.svgWidth || undefined,
    height: styling?.svgHeight || undefined,
    lineHeight: styling?.svgHeight || undefined
  },

  select: {
    appearance: 'none',
    outline: 'none',
    borderRadius: '4px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.colors.primary,
    background: theme.colors.white,
    padding: '0 0 0 12px',
    height: styling && styling.height ? styling.height : '40px',
    lineHeight: '24px',
    width: '100%',
    maxWidth: '720px',
    color: theme.colors.primaryText,
    fontSize: styling ? styling.fontSize : theme.fontSizes.body1,

    '&:hover': {
      borderColor: theme.colors.secondaryText
    },

    '&:focus': {
      borderColor: theme.colors.secondary
    }
  },

  ...styling
}));

type DropDownSelectorProps = {
  options: { label: string; value: string; disabled?: boolean }[];
  placeholder?: string;
  onChange: (...event: any[]) => void;
  value?: string;
  defaultValue?: string;
  disabled?: boolean;
  name: string;
  styling?: Record<string, string>;
  ariaLabel?: string;
};

const DropDownSelector = forwardRef<HTMLSelectElement, DropDownSelectorProps>(
  (
    { options, placeholder, onChange, name, styling, ariaLabel, value, defaultValue, disabled = false },
    ref
  ): ReactElement => {
    return (
      <StyledContainer styling={styling}>
        <DownArrow />
        <select
          onChange={onChange}
          name={name}
          value={value}
          ref={ref}
          defaultValue={defaultValue}
          disabled={disabled}
          aria-label={ariaLabel}>
          {placeholder && <option value='' disabled children={placeholder} />}
          {options!?.map(item => (
            <option key={item.value} value={item.value} children={item.label} disabled={item.disabled} />
          ))}
        </select>
      </StyledContainer>
    );
  }
);

DropDownSelector.displayName = 'DropDownSelector';

export default DropDownSelector;

import styled from '@emotion/styled';

const HighlightPanel = styled('div')`
  background: #fff;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
  position: relative;
  padding: 15px 20px;
  border-left: 5px solid;
  border-left-color: ${props => props.theme.colors.secondary};
  display: flex;
  align-items: center;
`;
export default HighlightPanel;

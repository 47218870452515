import styled from '@emotion/styled';

const IconCircle = styled('div')`
  border-radius: 15px;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-right: 10px;
  background-color: ${props => props.theme.colors.primary};
  &.big {
    height: 30px;
    width: 30px;
    background-color: ${props => props.theme.colors.secondary};
  }
  &.xl {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0 auto;
    font-size: 40px;
    background-color: ${props => props.theme.colors.secondary};
  }
`;

export default IconCircle;

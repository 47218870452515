import styled from '@emotion/styled';

export const PageHeader = styled('div')`
  margin-top: 40px;
  .sub-header {
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: end;
  }
  .sub-header svg {
    margin-right: 10px;
  }
  .header-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 15px;
  }
  h1 {
    font-size: 46px;
    margin: 15px 0 0 0;
  }
  ul {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: 15px;
  }
  li {
    margin-right: 15px;
    display: flex;
  }
  li strong {
    margin-right: 10px;
  }
`;

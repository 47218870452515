import styled from '@emotion/styled';

export const Spacer8 = styled('div')`
  margin-bottom: 8px;
`;

export const Spacer16 = styled('div')`
  margin-bottom: 16px;
`;

export const Spacer24 = styled('div')`
  margin-bottom: 24px;
`;
export const Spacer48 = styled('div')`
  margin-bottom: 48px;
`;

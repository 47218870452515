import styled from '@emotion/styled';
import CheckboxTick from './../icons/check.svg';

export const CheckboxLabel = styled.label(({ theme }) => ({
  marginLeft: 0,
  marginBottom: '20px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',

  input: {
    position: 'absolute',
    left: '-3000em'
  },

  'input+span': {
    border: '1px solid',
    borderColor: theme.colors.primary,
    background: theme.colors.white,
    width: '20px',
    height: '20px',
    display: 'block',
    borderRadius: '4px',
    marginRight: '10px',
    marginBottom: '2px',
    cursor: 'pointer'
  },

  'input:checked + span': {
    backgroundColor: theme.colors.secondary,
    borderColor: theme.colors.secondary,
    color: '#FFFFFF',
    backgroundSize: '25px 25px',
    backgroundPosition: '-4px',
    backgroundImage: `url(` + CheckboxTick + `)`
  }
}));

import { Job } from '../types/Jobs';
import axios from 'axios';

export type SearchQuery = {
  pageNumber?: number;
  pageSize: number;
  type?: string;
  orderBy?: string;
  title?: string;
  matterId?: string;
  region?: string;
  userId?: string;
};

export type ResourceResponse = {
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  totalCount: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  items: Job[];
};

export const getMatters = async (searchQuery: SearchQuery): Promise<any> => {
  const res = await axios.post(process.env.REACT_APP_API_BASE_URL + '/api/jobs/search', searchQuery);
  return res.data;
};

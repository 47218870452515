import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { BsInbox, BsXLg } from 'react-icons/bs';
import Button from '../atoms/Button';
import CloseButton from '../atoms/CloseButton';
import DropDownSelector from '../atoms/DropDownSelector';
import IconCircle from '../atoms/IconCircle';
import Label from '../atoms/Label';
import Slider from '../atoms/Slider';
import TextArea from '../atoms/TextArea';
import TextInput from '../atoms/TextInput';
import { Job, EditJobDetails } from './../../types/Jobs';

type Props = {
  details?: Job;
  editDetailsOpen: boolean;
  updatedDetails: (details: EditJobDetails) => void;
  closeEditJobDetail: () => void;
};

const Status = ['Unassigned', 'Assigned', 'InProgress', 'OnHold', 'Closed'];

const Priorities = ['High', 'Medium', 'Low'];

const JobDetailWrapper = styled('div')`
  background: #efefef;
  padding: 60px 40px 40px 40px;

  h2 {
    text-align: center;
    font-size: 22px;
    margin: 15px 0;
  }

  h3 {
    font-size: 18px;
    margin: 15px 0;
  }
`;

const ButtonGroup = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 30px;
  justify-content: flex-end;
`;

const InlineError = styled.div(() => ({
  color: '#CC0000',
  marginTop: '5px',
  width: '100%',
  marginLeft: 0
}));

const FormField = styled.div(() => ({
  display: 'flex',
  marginBottom: '16px',
  flexWrap: 'wrap'
}));

export const EditJobDetail = ({ details, updatedDetails, editDetailsOpen, closeEditJobDetail }: Props) => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = (data: any) => {
    updatedDetails({
      id: details ? details.id : '',
      title: data.title,
      description: data.description,
      priority: data.priority,
      status: data.status
    });
  };

  useEffect(() => {
    setValue('status', details!?.status);
    setValue('priority', details!?.priority);
  }, [details]);

  return (
    <Slider open={editDetailsOpen} width='500px'>
      <JobDetailWrapper>
        <CloseButton aria-label='Close' onClick={closeEditJobDetail}>
          <BsXLg />
        </CloseButton>
        <IconCircle className='xl'>
          <BsInbox />
        </IconCircle>
        <h2>Matter detail</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormField>
            <Label htmlFor='status'>Status</Label>
            <DropDownSelector
              value={details?.status}
              placeholder='Please select'
              ariaLabel='Status'
              {...register('status')}
              options={Status!?.map((status: string) => ({
                label: status,
                value: status,
                ...((status === 'Unassigned' || status === 'Assigned') && {
                  disabled: true
                })
              }))}
            />
          </FormField>
          <FormField>
            <Label htmlFor='region'>Priority</Label>
            <DropDownSelector
              defaultValue={details?.priority}
              placeholder='Please select'
              ariaLabel='Priority'
              {...register('priority')}
              options={Priorities!?.map((priority: string) => ({
                label: priority,
                value: priority
              }))}
            />
          </FormField>
          <FormField>
            <Label htmlFor='title'>Title</Label>
            <TextInput type='text' id='title' defaultValue={details!?.title} {...register('title')} />
          </FormField>
          <FormField>
            <Label htmlFor='description'>Description</Label>
            <TextArea
              rows={4}
              key='description'
              id='description'
              defaultValue={details!?.description}
              {...register('description', { pattern: /^[0-9A-Za-z\s\-,.]+$/ })}></TextArea>
            {errors.description?.type === 'pattern' && (
              <InlineError>Description can only contain letters, numbers, dashes, periods and commas</InlineError>
            )}
          </FormField>
          <ButtonGroup>
            <Button type='submit'>Save Matter Details</Button>
          </ButtonGroup>
        </form>
      </JobDetailWrapper>
    </Slider>
  );
};

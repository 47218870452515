import styled from '@emotion/styled';
import React from 'react';
import { BsHdd, BsXLg } from 'react-icons/bs';
import Button from '../atoms/Button';
import CloseButton from '../atoms/CloseButton';
import IconCircle from '../atoms/IconCircle';
import Label from '../atoms/Label';
import Slider from '../atoms/Slider';
import { EditSharedFolderInfo, SharedFolderAccessPermissionStatus } from './../../types/Jobs';
import TextInput from '../atoms/TextInput';
import { Spacer24, Spacer48 } from '../atoms/Spacer';
import { CheckboxLabel } from '../atoms/CheckboxLabel';
import { useForm } from 'react-hook-form';
import { InlineError } from '../atoms/InlineError';
import dayjs from 'dayjs';

type Props = {
  sharedData?: EditSharedFolderInfo;
  editSharedDataOpen: boolean;
  closeEditSharedData: () => void;
  updateShare: (id: string, update: UpdatedShare) => void;
};

type UpdatedShare = {
  sessionHostId?: string;
  action?: string;
  durationInHours?: number;
};

const Wrapper = styled('div')`
  background: #efefef;
  padding: 60px 40px 40px 40px;

  h2 {
    text-align: center;
    font-size: 22px;
    margin: 15px 0;
  }

  h3 {
    font-size: 18px;
    margin: 15px 0;
  }
`;

const OptionsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 600;

  div:first-of-type {
    display: flex;
    align-items: center;
    width: 40%;
    margin-top: 6px;
  }
  div:last-of-type label {
    min-width: 135px;
  }
`;

const FormField = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  justify-content: flex-end;
  min-width: 400px;

  label: {
    min-width: 180px;
  }
`;

const CheckboxWrap = styled('div')`
  margin-bottom: 0px;
`;

export const EditSharedData = ({ sharedData, editSharedDataOpen, closeEditSharedData, updateShare }: Props) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm();
  const enabledAccess = watch('grantAccess');

  const handleCloseSidebar = () => {
    setValue('grantAccess', undefined);
    setValue('duration', undefined);
    closeEditSharedData();
  };

  const updateAccess = (actionType: string, duration?: number) => {
    const req: UpdatedShare = {
      sessionHostId: sharedData ? sharedData.sessionHostId : '',
      action: actionType
    };
    enabledAccess && duration && actionType === 'Grant' && (req.durationInHours = duration);
    updateShare(sharedData!.sharedFolderId, req);
  };

  const onSubmit = (data: any) => {
    const duration = parseInt(data.duration);
    if (data.grantAccess) {
      updateAccess('Grant', duration);
      setValue('grantAccess', undefined);
      setValue('duration', undefined);
    }
  };

  const remainingTime = (endTime: string | undefined): string => {
    if (!endTime) return '';
    const azAccessEndTime = dayjs(endTime).local();
    const currentTime = dayjs();
    const timeDifferenceInMinutes = azAccessEndTime.diff(currentTime, 'minute');
    const hours = Math.floor(timeDifferenceInMinutes / 60);
    const minutes = timeDifferenceInMinutes % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };

  return (
    <Slider open={editSharedDataOpen} width='500px'>
      <Wrapper>
        <div>
          <IconCircle className='xl'>
            <BsHdd />
          </IconCircle>
          <h2>Shared Data</h2>
          <CloseButton onClick={handleCloseSidebar}>
            <BsXLg />
          </CloseButton>
          <h3>Persona:</h3>
          <p>{sharedData!?.sessionHost.persona}</p>
          {sharedData!?.sessionHost.name && (
            <>
              <h3>Name:</h3>
              <p>{sharedData!?.sessionHost.persona}</p>
            </>
          )}
          {sharedData!?.sessionHost.user?.name && (
            <>
              <h3>User:</h3>
              <p>{sharedData!?.sessionHost.user?.name}</p>
            </>
          )}
          <Spacer24 />
          <h3>Access Status:</h3>
          <p>{sharedData!?.status || ''}</p>
          {sharedData?.durationInHours && (
            <>
              <h3>Duration:</h3>
              <p>{sharedData!?.durationInHours || ''}</p>
            </>
          )}
          {sharedData?.azAccessEndTime && (
            <>
              <h3>Remaining Time(HH:MM):</h3>
              <p>
                {dayjs().isBefore(dayjs(sharedData.azAccessEndTime).local())
                  ? remainingTime(sharedData.azAccessEndTime)
                  : '00:00'}
              </p>
            </>
          )}
          <Spacer48 />

          {sharedData!?.status === SharedFolderAccessPermissionStatus.GRANTED && (
            <>
              <h3>Action:</h3>
              <Button
                type='button'
                minWidth='300px'
                minHeight='55px'
                customRedLight={true}
                onClick={() => updateAccess('Revoke')}>
                Revoke Access
              </Button>
            </>
          )}

          {sharedData!?.status === SharedFolderAccessPermissionStatus.DISABLED && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <h3>Action:</h3>
              <OptionsWrapper>
                <>
                  <CheckboxWrap>
                    <CheckboxLabel>
                      <input type='checkbox' {...register('grantAccess')} />
                      <span></span>
                      <span>Grant Access</span>
                    </CheckboxLabel>
                  </CheckboxWrap>
                </>
                {enabledAccess && (
                  <>
                    <div>
                      <FormField>
                        <Label htmlFor='duration'>Duration in hours</Label>
                        <TextInput
                          type='text'
                          id='duration'
                          pattern='\d*'
                          {...register('duration', { required: true })}
                        />
                      </FormField>
                    </div>
                    {errors.duration && <InlineError>This field is required</InlineError>}
                  </>
                )}
              </OptionsWrapper>
              <Button type='submit' minWidth='300px' minHeight='55px' customGreenLight={true}>
                Request Access
              </Button>
            </form>
          )}
        </div>
      </Wrapper>
    </Slider>
  );
};

import React from 'react';
import styled from '@emotion/styled';

const SpinnerBase = styled.div({
  span: {
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    margin: 'auto',

    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      margin: 'auto',
      borderRadius: '50%'
    }
  },

  '@keyframes spinner-1-1-inner': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(360deg)'
    }
  },

  '@keyframes spinner-1-1-outer': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(-360deg)'
    }
  },

  '@keyframes spinner-1-2-inner': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(220deg)'
    }
  },

  '@keyframes spinner-1-2-outer': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(-220deg)'
    }
  },

  '@keyframes spinner-1-3-inner': {
    '0%': {
      transform: 'rotate(-140deg)'
    },
    '100%': {
      transform: 'rotate(140deg)'
    }
  },

  '@keyframes spinner-1-3-outer': {
    '0%': {
      transform: 'rotate(140deg)'
    },
    '50%': {
      transform: 'rotate(-160deg)'
    },
    '100%': {
      transform: 'rotate(-140deg)'
    }
  },

  '@keyframes spinner-1-3-xs-inner': {
    '0%': {
      transform: 'rotate(-140deg)'
    },
    '50%': {
      transform: 'rotate(-20deg)'
    },
    '100%': {
      transform: 'rotate(140deg)'
    }
  }
});

const SpinnerOne = styled(SpinnerBase)(({ theme }) => {
  return {
    animation: 'spinner-1-1-inner 5s linear infinite',
    height: '80px',
    width: '80px',

    span: {
      clip: 'rect(0, 80px, 80px, 40px)',
      animation: 'spinner-1-2-inner 1.6s linear infinite',

      '&:after': {
        clip: 'rect(0, 80px, 80px, 40px)',
        border: `4px solid ${theme.colors.accentPrimary}`,
        animation: 'spinner-1-3-inner 1.6s cubic-bezier(0.77, 0, 0.175, 1) infinite'
      }
    }
  };
});

const SpinnerTwo = styled(SpinnerBase)(({ theme }) => {
  return {
    position: 'absolute',
    height: '60px',
    width: '60px',
    animation: 'spinner-1-1-outer 4s linear infinite',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',

    span: {
      clip: 'rect(30px, 80px, 60px, 0)',
      animation: 'spinner-1-2-outer 1.5s linear infinite',

      '&:after': {
        clip: 'rect(30px, 60px, 60px, 0)',
        border: `4px solid ${theme.colors.secondary}`,
        animation: 'spinner-1-3-outer 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite'
      }
    }
  };
});

const SpinnerThree = styled(SpinnerBase)(({ theme }) => {
  return {
    position: 'absolute',
    height: '40px',
    width: '40px',
    animation: 'spinner-1-1-inner 4s linear infinite',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',

    span: {
      clip: 'rect(0, 20px, 40px, 0)',
      animation: 'spinner-1-2-inner 1.2s linear infinite',

      '&:after': {
        clip: 'rect(0, 20px, 40px, 0)',
        border: `4px solid ${theme.colors.accentSecondary}`,
        animation: 'spinner-1-3-xs-inner 1.2s cubic-bezier(0.77, 0, 0.175, 1) infinite'
      }
    }
  };
});

const SpinnerWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '80px',
  width: '80px',
  position: 'relative',
  margin: 'auto'
});

const Spinner = () => {
  return (
    <SpinnerWrapper data-cy='loading-spinner'>
      <SpinnerOne>
        <span></span>
      </SpinnerOne>
      <SpinnerTwo>
        <span></span>
      </SpinnerTwo>
      <SpinnerThree>
        <span></span>
      </SpinnerThree>
    </SpinnerWrapper>
  );
};

const FullPageSpinnerWrapper = styled.div({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 100
});

const FullPageSpinner = () => {
  return (
    <FullPageSpinnerWrapper>
      <Spinner />
    </FullPageSpinnerWrapper>
  );
};

const SpinnerOverlay = styled.div(({ theme }) => {
  return {
    position: 'fixed',
    zIndex: 100,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: theme.colors.scrim,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white'
  };
});

export { Spinner, FullPageSpinner, SpinnerOverlay };

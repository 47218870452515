import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import TextInput from '../atoms/TextInput';
import Button from '../atoms/Button';
import styled from '@emotion/styled';
import { BsXLg } from 'react-icons/bs';
import CloseButton from '../atoms/CloseButton';

export type TextInputAutoCompleteProps = {
  onSetOption?: (option: string) => void;
  setSelection?: boolean;
  value?: string | null;
  ariaLabel?: string;
  searchValue?: (search: string) => void;
  searchResults?: TextInputAutoCompleteOption[];
  changeSelection?: () => void;
  disabled?: boolean;
  customLable?: string[];
  styling?: Record<string, string>;
  isBtnIconEnabled?: boolean;
  isResetValue?: boolean;
};

export type TextInputAutoCompleteOption = {
  id: string;
  name: string;
  matterId?: string;
};

const Wrapper = styled.div<{ styling?: Record<string, string> }>(({ styling }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: styling?.width || '100%',
  height: styling?.height || undefined,
  maxWidth: '720px',
  position: 'relative'
}));

const InnerWrap = styled.div(() => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center'
}));

const OptionsList = styled.div(() => ({
  width: '100%',
  maxWidth: '720px',
  border: '1px solid #D1D1D1',
  borderTopWidth: 0,
  borderRadius: '0 0 4px 4px',
  position: 'absolute',
  top: '40px',
  zIndex: 2,
  background: '#FFFFFF',
  overflow: 'hidden',

  p: {
    margin: 0,
    padding: '15px',
    width: '100%'
  },

  ul: {
    listStyle: 'none',
    margin: 0,
    padding: 0
  },

  button: {
    border: 0,
    padding: '15px',
    width: '100%',
    textAlign: 'left',
    background: '#FFFFFF',

    '&:hover': {
      background: '#D1D1D1',
      cursor: 'pointer'
    }
  }
}));

const ChangeButton = styled(Button)`
  padding: 0;
  color: ${props => props.theme.colors.secondary};
  text-decoration: underline;
`;

const TextInputAutoComplete = ({
  onSetOption,
  setSelection,
  ariaLabel,
  searchResults,
  searchValue,
  changeSelection,
  disabled,
  customLable,
  styling,
  isBtnIconEnabled,
  value,
  isResetValue
}: TextInputAutoCompleteProps): ReactElement => {
  const findDefaultOption =
    value && searchResults && searchResults.length > 0 && searchResults.find(res => res.id === value);
  const defaultValue = findDefaultOption && findDefaultOption.name;
  const defaultDisable = defaultValue && defaultValue.length > 0 ? true : false;
  const [autoCompleteValue, setAutoCompleteValue] = useState(defaultValue || '');
  const [isDisabled, setIsDisabled] = useState(defaultDisable);
  const [isLoading, setIsLoading] = useState(false);
  const [hideOptions, setHideOptions] = useState(false);
  const textInput = useRef<HTMLInputElement>(null);
  const [isSelected, setIsSelected] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [clickedOutside, setClickedOutside] = useState(true);

  const handleClickOutside = (event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      setClickedOutside(true);
    }
  };

  useEffect(() => {
    if (isResetValue) {
      setIsSelected(false);
      setIsDisabled(false);
      setAutoCompleteValue('');
      setHideOptions(false);
      textInput.current?.focus();
    }
  }, [value]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (searchResults != null) {
      setIsLoading(false);
    }
  });

  const getUsers = async (search: string) => {
    if (isLoading === false) {
      searchValue!(search);
      setIsLoading(true);
    }
  };

  const filterOptions = (value: string) => {
    if (value.length >= 3) {
      setHideOptions(false);
      setClickedOutside(false);
      getUsers(value);
    }
  };

  const setTextInput = (option: TextInputAutoCompleteOption) => {
    setIsSelected(true);
    onSetOption!(option.id);
    setHideOptions(true);
    if (setSelection === true) {
      setAutoCompleteValue(customLable ? `${option.matterId} - ${option.name}` : option.name || '');
      setIsDisabled(true);
    } else {
      setAutoCompleteValue('');
    }
  };

  const changeTextInput = () => {
    setIsSelected(false);
    setIsDisabled(false);
    setAutoCompleteValue('');
    setHideOptions(false);
    textInput.current?.focus();
    changeSelection!();
  };
  // useMemo to prevent unnecessary rendering of the list just when searchResults changes
  const memoOptions = useMemo(() => {
    return searchResults!?.map((option, index) => {
      return (
        <li key={index}>
          <button
            type='button'
            onClick={() => {
              setTextInput(option);
            }}>
            {customLable ? `${option.matterId} - ${option.name}` : option.name}
          </button>
        </li>
      );
    });
  }, [searchResults]);

  return (
    <>
      <Wrapper styling={styling}>
        <InnerWrap>
          <TextInput
            inputStyling={{ inputHeight: styling?.inputHeight || undefined, fontSize: styling?.fontSize }}
            disabled={disabled || isDisabled}
            autoComplete='none'
            aria-autocomplete='none'
            aria-label={ariaLabel}
            value={autoCompleteValue}
            ref={textInput}
            onChange={e => {
              filterOptions(e.target.value);
              setAutoCompleteValue(e.target.value);
              if (e.target.value.length < 1) {
                setHideOptions(true);
              }
            }}
          />
          {isDisabled && isBtnIconEnabled && (
            <CloseButton styling={{ fontSize: '10px', padding: '5px', top: '12%' }} onClick={changeTextInput}>
              <BsXLg />
            </CloseButton>
          )}
          {isDisabled && !isBtnIconEnabled && (
            <ChangeButton secondary onClick={changeTextInput}>
              Change
            </ChangeButton>
          )}
        </InnerWrap>
        {(searchResults!?.length > 0 || isLoading === true) && hideOptions === false && !clickedOutside && (
          <OptionsList ref={containerRef}>{!isSelected && <ul>{memoOptions}</ul>}</OptionsList>
        )}
      </Wrapper>
    </>
  );
};

export default TextInputAutoComplete;

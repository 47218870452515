import { PageHeader } from '../../../components/atoms/PageHeader';
import { EditSharedFolderInfo, Job, SharedFolder, SharedFolderStatus } from '../../../types/Jobs';
import { getJobById } from 'api';
import React, { useEffect, useState } from 'react';
import { BsFillArrowLeftCircleFill, BsLockFill, BsFillUnlockFill, BsPlusCircleFill, BsTrashFill } from 'react-icons/bs';
import { PageRoutes } from 'routes';
import Badge from '../../atoms/Badge';
import { DetailsPanel } from '../../atoms/DetailsPanel';
import dayjs from 'dayjs';
import {
  BlockButton,
  BlockSection,
  IconWrapper,
  IconWrapperWithAnimation,
  SmallText,
  TableRow,
  Wrapper
} from './styled';
import { useNavigate } from 'react-router-dom';
import { LinkButton, Panel, ShadedTable } from '../../../components/molecules/SharedFolderInfo/styled';
import { ListContainer } from '../../../components/atoms/ListContainer';
import SharedFolderInfo from '../../../components/molecules/SharedFolderInfo/SharedFolderInfo';
import { VerticalCenter } from '../../../components/atoms/VerticalCenter';
import { Spinner } from '../../../components/molecules/Spinner';
import Toast from '../../../components/atoms/Toast';
import { LockAccess } from '../LockAccess';
import { Spacer16 } from '../../../components/atoms/Spacer';
import { AddEvidence } from '../AddEvidence';
import { createEvidence } from 'api/createEvidence';
import { EditSharedData } from '../EditSharedData';
import { updateShareFolder } from 'api/updateShareFolder';
import { DeleteEvidence } from '../DeleteEvidence';
import { deleteEvidence } from 'api/deleteEvidence';
import { GENERIC_ERROR_MESSAGE } from 'constants/application';
import { lockEvidenceById } from 'api/lockEvidenceById';
import { getSharedFolderById } from 'api/getSharedFolderById';

type ComponentProps = {
  jobId: string;
};
export const EvidenceBoard: React.FC<ComponentProps> = ({ jobId }) => {
  const navigate = useNavigate();
  const [job, setJob] = useState<Job>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEvidence, setSelectedEvidence] = useState<string | null>(null);
  const [currentSharedData, setCurrentSharedData] = useState<EditSharedFolderInfo>();
  const [editSharedDataOpen, setEditSharedDataOpen] = useState(false);
  const [lockAccessOpen, setLockAccessOpen] = useState(false);
  const [deletionOpen, setDeletionOpen] = useState(false);
  const [addEvidenceOpen, setAddEvidenceOpen] = useState(false);
  const [toastActive, setToastActive] = useState(false);
  const [toastContent, setToastContent] = useState('');
  const [isToastForSuccess, setIsToastForSuccess] = useState(false);

  const evidenceDataSharedFolders = job && job.sharedFolders.filter(sf => sf.type === 'EvidenceData');
  const selectedEvidenceInfo = evidenceDataSharedFolders?.find(evidence => evidence.identifier === selectedEvidence);

  const isAvailableForDeletion = (evidence: SharedFolder) => {
    const isValidStatusInAccessPermission =
      !evidence.accessPermissions ||
      evidence.accessPermissions.length === 0 ||
      evidence.accessPermissions.every(ap => ap.status === 'Disabled');

    return (
      job &&
      job.status !== 'Closed' &&
      job.status !== 'Removing' &&
      evidence.status === 'Deployed' &&
      isValidStatusInAccessPermission
    );
  };

  const getJob = async () => {
    setIsLoading(true);
    const res = await getJobById(jobId);
    setJob(res);
    setIsLoading(false);
  };
  const handleClickEvidence = async (id: string, identifier: string) => {
    setSelectedEvidence(identifier);
    try {
      const sharedFolder = await getSharedFolderById(id);
      const updatedSharedFolders = job!.sharedFolders.map(sf => {
        if (sf.id === id) {
          return sharedFolder;
        }
        return sf;
      });
      const updatedJob = {
        ...job!,
        sharedFolders: updatedSharedFolders
      };
      setJob(updatedJob);
    } catch (err: any) {
      addToast(err.response.data.detail);
    }
  };
  const editSharedData = (data: EditSharedFolderInfo) => {
    setCurrentSharedData(data);
    setEditSharedDataOpen(true);
  };

  const updateShare = async (id: string, update: any) => {
    setEditSharedDataOpen(false);
    try {
      await updateShareFolder(id, update);
      getJob();
    } catch (error: any) {
      addToast(error.response.data.detail);
    }
  };
  const addToast = (content: string) => {
    setToastActive(true);
    setToastContent(content || GENERIC_ERROR_MESSAGE);
    setTimeout(() => {
      setToastActive(false);
      setToastContent('');
      setIsToastForSuccess(false);
    }, 5000);
  };

  const handleLockAccess = async (evidenceId: string, identifier: string, confirmLock: boolean) => {
    setLockAccessOpen(false);
    if (confirmLock) {
      try {
        await lockEvidenceById(evidenceId);
        setIsToastForSuccess(true);
        addToast(`The request of locking Evidence ${identifier} has been successfully sent.`);
        getJob();
      } catch (err: any) {
        addToast(err.response.data.detail);
      }
    }
  };

  const handleDeleteEvidence = async (id: string, reason: string) => {
    setDeletionOpen(false);
    try {
      await deleteEvidence(id, reason);
      setIsToastForSuccess(true);
      addToast('The request to remove Evidence sent successfully.');
      getJob();
    } catch (err: any) {
      addToast(err.response.data.detail);
    }
  };

  const addEvidence = async (data: { evidence: string }) => {
    setAddEvidenceOpen(false);
    try {
      await createEvidence(jobId, data.evidence);
      getJob();
    } catch (err: any) {
      addToast(err.response.data.detail);
    }
  };

  useEffect(() => {
    getJob();
  }, []);

  return (
    <>
      {!isLoading ? (
        <Wrapper>
          <Toast open={toastActive} content={toastContent} isSuccess={isToastForSuccess}></Toast>
          <BlockSection>
            <BlockButton onClick={() => navigate(PageRoutes.ViewJob.replace(':id', job!.id))}>
              <BsFillArrowLeftCircleFill />
              {'  '}Back to Matter Details Page
            </BlockButton>
          </BlockSection>
          <PageHeader>
            <div className='header-options'>
              <h1>Evidence</h1>
              <ul>
                <li>
                  <strong>Matter ID:</strong>
                  {job!?.matterId}
                </li>
                <li>
                  <strong>Status:</strong>
                  <Badge type='dot' status={job!?.status} />
                </li>
              </ul>
            </div>
          </PageHeader>
          <DetailsPanel>
            <ul>
              <li>
                <strong>Priority:</strong>
                {job!?.priority}
              </li>
              <li>
                <strong>Region:</strong>
                {job!?.region}
              </li>
              <li>
                <strong>Date Created:</strong>
                {dayjs(job!?.created)
                  .local()
                  .format('DD-MMM-YYYY')}
              </li>
              <li>
                <strong>Last Updated:</strong>
                {dayjs(job!?.lastModified)
                  .local()
                  .format('DD-MMM-YYYY')}
              </li>
              <li>
                <strong>Description:</strong>
                {job!?.description}
              </li>
            </ul>
          </DetailsPanel>
          <BlockButton onClick={() => setAddEvidenceOpen(true)}>
            <BsPlusCircleFill />
            {'  '}New evidence
          </BlockButton>
          <Spacer16 />
          <ListContainer>
            <ShadedTable>
              <thead>
                <tr>
                  <th>Evidence ID</th>
                  <th>Status</th>
                  <th>Created</th>
                  <th>Created by</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {evidenceDataSharedFolders && evidenceDataSharedFolders.length > 0 && (
                  <>
                    {evidenceDataSharedFolders.map((evidence, index) => (
                      <TableRow
                        key={index}
                        className={evidence.identifier === selectedEvidence ? 'selected' : ''}
                        onClick={() => handleClickEvidence(evidence.id, evidence.identifier!)}>
                        <td>{evidence.identifier}</td>
                        <td>{evidence.status}</td>
                        <td>{dayjs(evidence.created).local().format('DD-MMM-YYYY')}</td>
                        <td>{evidence.createdByUser?.name}</td>
                        <td>
                          {evidence && evidence.status === SharedFolderStatus.DEPLOYED && (
                            <LinkButton
                              onClick={() => {
                                setLockAccessOpen(true);
                              }}>
                              <IconWrapperWithAnimation>
                                <BsFillUnlockFill style={{ color: '#228B22' }} size={22} />
                              </IconWrapperWithAnimation>
                            </LinkButton>
                          )}
                          {evidence && evidence.status === SharedFolderStatus.LOCKED && (
                            <LinkButton>
                              <BsLockFill style={{ color: '#FF5733' }} size={22} />
                            </LinkButton>
                          )}
                          {evidence &&
                            evidence.status !== SharedFolderStatus.DEPLOYED &&
                            evidence.status !== SharedFolderStatus.LOCKED && (
                              <LinkButton>
                                <IconWrapper>
                                  <BsFillUnlockFill style={{ color: '#228B22' }} size={22} />
                                </IconWrapper>
                              </LinkButton>
                            )}
                        </td>
                        <td>
                          {isAvailableForDeletion(evidence) && (
                            <LinkButton
                              onClick={() => {
                                setDeletionOpen(true);
                              }}>
                              <IconWrapperWithAnimation>
                                <BsTrashFill style={{ color: '#FF6347' }} size={22} />
                              </IconWrapperWithAnimation>
                            </LinkButton>
                          )}
                        </td>
                      </TableRow>
                    ))}
                  </>
                )}
              </tbody>
            </ShadedTable>
          </ListContainer>
          <Wrapper>
            <h2>
              Evidence Data Access{' '}
              {selectedEvidenceInfo?.status && <SmallText>Status: {selectedEvidenceInfo?.status}</SmallText>}
            </h2>
            <Panel>
              {selectedEvidenceInfo &&
                selectedEvidenceInfo.accessPermissions &&
                selectedEvidenceInfo.accessPermissions.length > 0 && (
                  <SharedFolderInfo sharedFolder={selectedEvidenceInfo} handleEdit={editSharedData} />
                )}
            </Panel>
          </Wrapper>
          <LockAccess
            evidence={selectedEvidenceInfo!}
            lockAccessOpen={lockAccessOpen}
            lockAccess={handleLockAccess}
            closeLockAccess={() => {
              setLockAccessOpen(false);
            }}
          />
          <DeleteEvidence
            evidence={selectedEvidenceInfo!}
            deletionOpen={deletionOpen}
            deleteEvidence={handleDeleteEvidence}
            closeDeletion={() => {
              setDeletionOpen(false);
            }}
          />
          <AddEvidence
            addEvidenceOpen={addEvidenceOpen}
            closeAddEvidence={() => {
              setAddEvidenceOpen(false);
            }}
            addEvidence={addEvidence}
          />
          <EditSharedData
            sharedData={currentSharedData}
            editSharedDataOpen={editSharedDataOpen}
            closeEditSharedData={() => {
              setEditSharedDataOpen(false);
            }}
            updateShare={updateShare}
          />
        </Wrapper>
      ) : (
        <VerticalCenter>
          <Spinner />
        </VerticalCenter>
      )}
    </>
  );
};

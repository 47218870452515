import React from 'react';
import styled from '@emotion/styled';
import { ReactElement } from 'react';

type Props = {
  type: string;
  status: string;
};

const StyledBadge = styled('span')`
  &.badge {
    border-radius: 15px;
    padding: 5px 15px;
    background: #bbb;
    text-align: center;
    color: #ffffff;
    display: inline-block;
    min-width: 100px;
  }
  &.dot {
    display: flex;
  }
  &.dot:before {
    content: '';
    display: block;
    height: 15px;
    width: 15px;
    border-radius: 7.5px;
    margin-right: 15px;
  }

  &.Assigned.badge,
  &.Assigned.dot:before {
    background-color: #658d1b;
  }
  &.Unassigned.badge,
  &.Unassigned.dot:before {
    background-color: #47525a;
  }
  &.OnHold.badge,
  &.OnHold.dot:before {
    background-color: #c1292e;
  }
  &.InProgress.badge,
  &.InProgress.dot:before,
  &.Deploying.badge,
  &.Deploying.dot:before,
  &.Rescaling.badge,
  &.Rescaling.dot:before,
  &.Redeploying.badge,
  &.Redeploying.dot:before,
  &.Assigning.badge,
  &.Assigning.dot:before,
  &.Granting.badge,
  &.Granting.dot:before {
    background-color: #71dbd4;
  }
  &.Deployed.badge,
  &.Deployed.dot:before,
  &.Redeployed.badge,
  &.Redeployed.dot:before,
  &.Rescaled.badge,
  &.Rescaled.dot:before,
  &.Granted.badge,
  &.Granted.dot:before {
    background-color: #008c95;
  }
  &.Failed.badge,
  &.Failed.dot:before,
  &.FailedToRemove.badge,
  &.FailedToRemove.dot:before,
  &.FailedToDeploy.badge,
  &.FailedToDeploy.dot:before,
  &.FailedToRescale.badge,
  &.FailedToRescale.dot:before,
  &.FailedToRedeploy.badge,
  &.FailedToRedeploy.dot:before,
  &.FailedToAssign.badge,
  &.FailedToAssign.dot:before,
  &.FailedToGrant.badge,
  &.FailedToGrant.dot:before,
  &.FailedToRevoke.badge,
  &.FailedToRevoke.dot:before {
    background-color: #c1292e;
  }
  &.Requested.badge,
  &.Requested.dot:before,
  &.Removing.badge,
  &.Removing.dot:before,
  &.Revoking.badge,
  &.Revoking.dot:before {
    background-color: #ecb604;
  }
  &.On.badge,
  &.On.dot:before {
    background-color: #658d1b;
  }
`;
//Unassigned, Assigned, InProgress, OnHold, Closed, Deployed, Failed, Off, Requested, On
const Badge = ({ type, status }: Props): ReactElement => {
  return (
    <StyledBadge className={type + ' ' + status}>
      {status ? status.replace(/([A-Z])/g, ' $1').trim() : status}
    </StyledBadge>
  );
};

export default Badge;

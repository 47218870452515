import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { BsPower, BsXLg } from 'react-icons/bs';
import Button from '../atoms/Button';
import CloseButton from '../atoms/CloseButton';
import IconCircle from '../atoms/IconCircle';
import Label from '../atoms/Label';
import Slider from '../atoms/Slider';
import TextInput from '../atoms/TextInput';
import { Resource, ShutdownUpdate } from './../../types/SessionHosts';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

type Props = {
  resource: Resource;
  updateShutdownOpen: boolean;
  updateShutdown: (update: ShutdownUpdate) => void;
  closeUpdateShutdown: () => void;
};

const AccessWrapper = styled('div')`
  background: #efefef;
  padding: 60px 40px 40px 40px;

  h2 {
    text-align: center;
    font-size: 22px;
    margin: 15px 0 30px 0;
  }

  h3 {
    font-size: 16px;
    margin: 0 5px 0 0;
  }

  .radio-list {
    margin: 0;
    padding: 0;
    list-style: none;

    label {
      margin: 10px 0;
      display: flex;
    }

    input {
      width: 20px;
      height: 20px;
      margin: 0 10px 0 0;
    }
  }

  input {
    width: 50%;
  }
`;

const ShutdownNote = styled('span')`
  font-size: 12px;
  display: block;
  margin-bottom: 16px;
  text-align: right;
  padding: 6px;
`;

export const UpdateShutdown = ({ resource, updateShutdownOpen, updateShutdown, closeUpdateShutdown }: Props) => {
  const { register, handleSubmit } = useForm();
  const [shutdownOption, setShutdownOption] = useState('');
  const inProgress = [
    'Disabling',
    'FailedToDisable',
    'SkippingNext',
    'FailedToSkipNext',
    'Enabling',
    'FailedToEnable',
    'Updating',
    'FailedToUpdate'
  ];

  // const convertTimeToLocal = (hour: number, minute: number) => {
  //   const utcDate = dayjs().utc().startOf('day').add(hour, 'hour').add(minute, 'minute');
  //   return utcDate;
  // };

  const onSubmit = (data: any) => {
    let shutdownTime = null;
    if (shutdownOption === 'UpdateAutoShutdown') {
      //const utcDate = dayjs().startOf('day').add(data.shutdownTime.split(':')[0],'hour').add(data.shutdownTime.split(':')[1],'minute');
      //shutdownTime = dayjs(utcDate).utc().format('HH') + ":" + dayjs(utcDate).utc().format('mm') + ":00";
      shutdownTime = data.shutdownTime + ':00';
    }

    const update: ShutdownUpdate = {
      action: shutdownOption
    };

    shutdownTime && (update.shutdownTime = shutdownTime);

    updateShutdown(update);
  };

  return (
    <Slider open={updateShutdownOpen} width='500px'>
      <AccessWrapper>
        <div>
          <IconCircle className='xl'>
            <BsPower />
          </IconCircle>
          <h2>Update Shutdown</h2>
          <CloseButton onClick={closeUpdateShutdown}>
            <BsXLg />
          </CloseButton>
          {inProgress.indexOf(resource.autoShutdownStatus) > -1 && (
            <p>
              This feature is currently unavailable for this resource, please check again later.
              <br />
              Current status of auto shutdown is {resource.autoShutdownStatus}
            </p>
          )}
          {inProgress.indexOf(resource.autoShutdownStatus) === -1 && (
            <>
              <h3>Please select an option</h3>
              <form onSubmit={handleSubmit(onSubmit)}>
                <ul className='radio-list'>
                  {(resource.autoShutdownStatus === 'Enabled' || resource.autoShutdownStatus === 'Updated') && (
                    <li>
                      <Label>
                        <input
                          type='radio'
                          value='SkipNextShutdown'
                          {...register('shutdownOptions')}
                          onChange={() => {
                            setShutdownOption('SkipNextShutdown');
                          }}
                        />
                        Skip Next Shutdown
                      </Label>
                    </li>
                  )}
                  {(resource.autoShutdownStatus === 'Enabled' ||
                    resource.autoShutdownStatus === 'SkippedNext' ||
                    resource.autoShutdownStatus === 'Updated') && (
                    <li>
                      <Label>
                        <input
                          type='radio'
                          value='DisableAutoShutdown'
                          {...register('shutdownOptions')}
                          onChange={() => {
                            setShutdownOption('DisableAutoShutdown');
                          }}
                        />
                        Disable Auto Shutdown
                      </Label>
                    </li>
                  )}
                  {resource.autoShutdownStatus === 'Disabled' && (
                    <li>
                      <Label>
                        <input
                          type='radio'
                          value='EnableAutoShutdown'
                          {...register('shutdownOptions')}
                          onChange={() => {
                            setShutdownOption('EnableAutoShutdown');
                          }}
                        />
                        Enable Auto Shutdown
                      </Label>
                    </li>
                  )}
                  {(resource.autoShutdownStatus === 'Enabled' || resource.autoShutdownStatus === 'Updated') && (
                    <li>
                      <Label>
                        <input
                          type='radio'
                          value='UpdateAutoShutdown'
                          {...register('shutdownOptions')}
                          onChange={() => {
                            setShutdownOption('UpdateAutoShutdown');
                          }}
                        />
                        Update Auto Shutdown
                      </Label>
                    </li>
                  )}
                </ul>
                {shutdownOption === 'UpdateAutoShutdown' && (
                  <>
                    <Label htmlFor='shutdownTime'>Update auto shutdown</Label>
                    <TextInput
                      //   type="time" defaultValue={dayjs(localShutdownTime).local().format('HH:mm')} {...register("shutdownTime")} data-testid="AutoShutdownTime"
                      type='time'
                      {...register('shutdownTime')}
                      data-testid='AutoShutdownTime'
                    />
                    <ShutdownNote>Shutdown times are AEST for AUS VMs and GMT for UK VMs</ShutdownNote>
                  </>
                )}
                <Button type='submit'>Save Changes</Button>
              </form>
            </>
          )}
        </div>
      </AccessWrapper>
    </Slider>
  );
};

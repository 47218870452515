import theme from '../../../styles/theme';
import styled from '@emotion/styled';

export const SearchContainer = styled('div')`
  height: 30px;
  border-radius: 30px;
  padding: 0px 5px;
  display: flex;
  align-items: center;
  transition:
    width 1.2s ease,
    color 10s ease;
  box-shadow: 0 0 25px 0 rgba(0 0 0 0.4);
  width: 170px;
  background: #d4f4f2;
  &:hover {
    width: 100%;
    background: #b8e5e2;

    input {
      width: 95%;
    }
  }
`;
export const SearchTxt = styled('input')`
  background: transparent;
  border: none;
  outline: none;
  transition: 0.8s;
  font-size: 14px;
  width: 95%;
  height: 90%;
  font-family: 'Lato', sans-serif;
  color: ${theme.colors.primaryText};
`;
export const ClearBtn = styled('button')`
  background: transparent;
  cursor: pointer;
  border: none;

  &:hover {
    font-size: 18px;
    transition: font-size 0.8s ease;
  }
`;

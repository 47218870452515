import Button from '../../components/atoms/Button';
import React from 'react';
import { SideBarContentContainer, SideBarErrorBox, SideBarSectionContainer, SideBarWrapper, Wrapper } from './styled';
import NotFound from '../../assets/notFound.png';

export type ComponentProps = {
  errorMessage: string;
  closeSideBar: () => void;
};

const ErrorSideBar: React.FC<ComponentProps> = ({ errorMessage, closeSideBar }) => {
  return (
    <SideBarWrapper>
      <SideBarContentContainer>
        <SideBarSectionContainer>
          <img src={NotFound} alt='Not Found' width='80%' />
        </SideBarSectionContainer>
        <SideBarSectionContainer>
          <SideBarErrorBox>
            <h2>Oooops!</h2>
            <p>{errorMessage || 'Something went wrong'}</p>
          </SideBarErrorBox>
          <Button onClick={closeSideBar} minWidth='240px' minHeight='55px'>
            Back to Resources Page
          </Button>
        </SideBarSectionContainer>
      </SideBarContentContainer>
    </SideBarWrapper>
  );
};

export default ErrorSideBar;

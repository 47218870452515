import styled from '@emotion/styled';
import React from 'react';
import { BsWifi, BsXLg } from 'react-icons/bs';
import Button from '../atoms/Button';
import CloseButton from '../atoms/CloseButton';
import IconCircle from '../atoms/IconCircle';
import Label from '../atoms/Label';
import Slider from '../atoms/Slider';
import TextInput from '../atoms/TextInput';
import { Spacer24, Spacer48 } from '../atoms/Spacer';
import { CheckboxLabel } from '../atoms/CheckboxLabel';
import { useForm } from 'react-hook-form';
import { InlineError } from '../atoms/InlineError';
import { InternetStatus, Resource } from './../../types/SessionHosts';
import { remainingTime } from '../../utils/time';
import { Update } from 'api/updateSessionHostInternetAccess';
import { ActionType } from '../../types/Api';

type Props = {
  sessionHost?: Resource;
  updateSessionHostOpen: boolean;
  closeUpdateSessionHost: () => void;
  updateInternetAccess: (id: string, update: Update) => void;
};

const Wrapper = styled('div')`
  background: #efefef;
  padding: 60px 40px 40px 40px;

  h2 {
    text-align: center;
    font-size: 22px;
    margin: 15px 0;
  }

  h3 {
    font-size: 18px;
    margin: 15px 0;
  }
`;

const OptionsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 600;

  div:first-of-type {
    display: flex;
    align-items: center;
    width: 60%;
    margin-top: 6px;
  }
  div:last-of-type label {
    min-width: 220px;
  }
`;

const FormField = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  justify-content: flex-end;
  min-width: 400px;

  label: {
    min-width: 180px;
  }
`;

const CheckboxWrap = styled('div')`
  margin-bottom: 0px;
  width: 100px;
`;

export const UpdateInternetAccess: React.FC<Props> = ({
  sessionHost,
  updateSessionHostOpen,
  closeUpdateSessionHost,
  updateInternetAccess
}) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm();
  const enabledAccess = watch('grantAccess');

  const handleCloseSidebar = () => {
    setValue('grantAccess', undefined);
    setValue('duration', undefined);
    closeUpdateSessionHost();
  };

  const updateAccess = (actionType: ActionType, duration?: number) => {
    const req = {
      action: actionType,
      InternetDurationInHours: duration ? duration : null
    };
    updateInternetAccess(sessionHost!.id, req);
  };

  const onSubmit = (data: any) => {
    const duration = parseInt(data.duration);
    if (data.grantAccess) {
      updateAccess(ActionType.GRANT, duration);
      setValue('grantAccess', undefined);
      setValue('duration', undefined);
    }
  };

  return (
    <Slider open={updateSessionHostOpen} width='500px'>
      <Wrapper>
        <div>
          <IconCircle className='xl'>
            <BsWifi />
          </IconCircle>
          <h2>Update Internet Access</h2>
          <CloseButton onClick={handleCloseSidebar}>
            <BsXLg />
          </CloseButton>

          <h3>Internet Status:</h3>
          <p>{sessionHost?.internetStatus || ''}</p>
          <Spacer24 />
          {sessionHost?.internetDurationInHours && (
            <>
              <h3>Duration:</h3>
              <p>{sessionHost?.internetDurationInHours || ''}</p>
            </>
          )}
          {sessionHost?.internetStartTime && sessionHost?.internetEndTime && (
            <>
              <h3>Remaining Time(HH:MM):</h3>
              <p>{remainingTime(sessionHost?.internetEndTime)}</p>
            </>
          )}
          <Spacer48 />

          {sessionHost?.internetStatus === InternetStatus.GRANTED && (
            <>
              <h3>Action:</h3>
              <Button
                type='button'
                minWidth='300px'
                minHeight='55px'
                customRedLight={true}
                onClick={() => updateAccess(ActionType.REVOKE)}>
                Revoke Internet Access
              </Button>
            </>
          )}

          {sessionHost?.internetStatus === InternetStatus.DISABLED && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <h3>Action:</h3>
              <OptionsWrapper>
                <>
                  <CheckboxWrap>
                    <CheckboxLabel>
                      <input type='checkbox' {...register('grantAccess')} />
                      <span></span>
                      <span>Grant Internet Access</span>
                    </CheckboxLabel>
                  </CheckboxWrap>
                </>
                {enabledAccess && (
                  <>
                    <div>
                      <FormField>
                        <Label htmlFor='duration'>Duration in hours</Label>
                        <TextInput
                          type='text'
                          id='duration'
                          pattern='\d*'
                          {...register('duration', { required: true })}
                        />
                      </FormField>
                    </div>
                    {errors.duration && <InlineError>This field is required</InlineError>}
                  </>
                )}
              </OptionsWrapper>
              <Button type='submit' minWidth='300px' minHeight='55px' customGreenLight={true}>
                Request Access
              </Button>
            </form>
          )}
        </div>
      </Wrapper>
    </Slider>
  );
};

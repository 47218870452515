import React from 'react';
import TextArea from './../atoms/TextArea';
import Button from './../atoms/Button';
import Label from './../atoms/Label';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';
import Slider from '../atoms/Slider';
import { BsFileEarmarkText, BsXLg } from 'react-icons/bs';
import CloseButton from '../atoms/CloseButton';
import IconCircle from '../atoms/IconCircle';
import { NotesSearch } from './../../types/Jobs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

type Props = {
  notes: NotesSearch | undefined;
  addNoteOpen: boolean;
  addNote: (option: string) => void;
  closeAddNote: () => void;
};

const FormField = styled.div(() => ({
  display: 'flex',
  marginBottom: '16px',
  flexWrap: 'wrap'
}));

const NotesWrapper = styled('div')`
  background: #efefef;
  padding: 60px 40px 40px 40px;

  h2 {
    text-align: center;
    font-size: 22px;
    margin: 15px 0;
  }
`;

const ButtonGroup = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 30px;
  justify-content: flex-end;
`;

const NotesList = styled('ul')`
  margin: 20px 0;
  padding: 0;
  list-style: none;
  overflow-y: auto;
  height: calc(100vh - 420px);

  li {
    background: #fff;
    padding: 15px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
    margin: 15px 0;
  }

  div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }

  h4 {
    margin: 0;
  }
`;

export const AddNote = ({ notes, addNoteOpen, addNote, closeAddNote }: Props) => {
  const { register, handleSubmit } = useForm();

  return (
    <Slider open={addNoteOpen} width='500px'>
      <NotesWrapper>
        <div>
          <IconCircle className='xl'>
            <BsFileEarmarkText />
          </IconCircle>
          <h2>Notes</h2>
          <CloseButton aria-label='Close' onClick={closeAddNote}>
            <BsXLg />
          </CloseButton>
          <form onSubmit={handleSubmit((data: any) => addNote(data))}>
            <FormField>
              <Label htmlFor='note'>Add new note</Label>
              <TextArea rows={4} key='note' id='note' {...register('note')}></TextArea>
            </FormField>
            <ButtonGroup>
              <Button type='submit'>Save Note</Button>
            </ButtonGroup>
          </form>
        </div>

        <div>
          <>
            {notes!?.items.length > 0 && (
              <NotesList>
                {notes!?.items.map((note, index) => (
                  <li key={index}>
                    <div>
                      <h4>{note.createdByUser.name}</h4>
                      <span>{dayjs.utc(note.created).local().format('DD-MMM-YYYY')}</span>
                    </div>
                    <div>{note.note}</div>
                  </li>
                ))}
              </NotesList>
            )}
          </>
        </div>
      </NotesWrapper>
    </Slider>
  );
};

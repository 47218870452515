export const PERSONAS_NAME = {
  DAILY_DRIVER: 'Daily Driver',
  FORENSIC: 'Forensic',
  DATA_COLLECTION: 'Data Collection'
};

export const PERSONAS_ID = {
  DAILY_DRIVER: 'DailyDriver',
  FORENSIC: 'Forensic',
  DATA_COLLECTION: 'DataCollection'
};

export const regions = [
  {
    name: 'Australia',
    id: 'Australia'
  },
  {
    name: 'UK',
    id: 'UK'
  }
];

export enum SessionHostUserStatus {
  UNASSIGNED = 'Unassigned',
  REMOVING = 'Removing',
  FAILEDTOREMOVE = 'FailedToRemove',
  ASSIGNING = 'Assigning',
  ASSIGNED = 'Assigned',
  FAILEDTOASSIGN = 'FailedToAssign'
}
export const userStatus = [
  {
    name: 'Unassigned',
    id: 'Unassigned'
  },
  {
    name: 'Removing',
    id: 'Removing'
  },
  {
    name: 'FailedToRemove',
    id: 'FailedToRemove'
  },
  {
    name: 'Assigning',
    id: 'Assigning'
  },
  {
    name: 'Assigned',
    id: 'Assigned'
  },
  {
    name: 'FailedToAssign',
    id: 'FailedToAssign'
  }
];
export const noneOption = {
  name: 'None',
  id: 'none'
};

export const GENERIC_ERROR_MESSAGE = 'Sorry, something went wrong. Please try again later';

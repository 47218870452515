import React, { ReactNode } from 'react';
import { useMsal, useAccount } from '@azure/msal-react';
import axios, { AxiosRequestConfig } from 'axios';
import { loginRequest } from './authConfig';

// const uuidv4 = () => {
//   return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
//     ((c ^ crypto.getRandomValues(new Uint8Array(1))[0]) & (15 >> (c / 4))).toString(16)
//   );
// };

const uuidv4 = (): string => {
  return ('' + [1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c: string) =>
    ((parseInt(c, 10) ^ crypto.getRandomValues(new Uint8Array(1))[0]) & (15 >> (parseInt(c, 10) / 4))).toString(16)
  );
};

type Props = {
  children: ReactNode;
};

interface MyAxiosRequestConfig extends AxiosRequestConfig {
  headers?: {
    Authorization?: string;
    'X-Request-ID'?: string;
  };
}

export const RequestInterceptor = ({ children }: Props) => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);

  /* eslint-disable no-param-reassign */
  axios.interceptors.request.use(async (config: MyAxiosRequestConfig) => {
    if (!account) {
      throw Error('No active account! Verify a user has been signed in.');
    }

    const response = await instance.acquireTokenSilent({
      ...loginRequest,
      account
    });

    const bearer = `Bearer ${response.accessToken}`;
    config.headers!.Authorization = bearer;

    const requestId = uuidv4();
    config.headers!['X-Request-ID'] = requestId;

    return config;
  });
  /* eslint-enable no-param-reassign */

  return <>{children}</>;
};

import { ActionType } from '../types/Api';
import axios from 'axios';

export type Update = {
  action: ActionType;
  InternetDurationInHours: number | null;
};
export const updateSessionHostInternetAccess = async (id: string, update: Update): Promise<any> => {
  const res = await axios.post(
    process.env.REACT_APP_API_BASE_URL + '/api/sessionhosts/' + id + '/updateinternet',
    update
  );

  return res.data;
};

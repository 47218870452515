import dayjs from 'dayjs';

export const remainingTime = (endTime: string | undefined, startTime?: string): string => {
  if (!endTime) return '';
  const azAccessEndTime = dayjs(endTime).local();
  const currentTime = startTime ? dayjs(startTime).local() : dayjs();
  if (!currentTime.isBefore(azAccessEndTime)) return '00:00';
  const timeDifferenceInMinutes = azAccessEndTime.diff(currentTime, 'minute');
  const hours = Math.floor(timeDifferenceInMinutes / 60);
  const minutes = timeDifferenceInMinutes % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};

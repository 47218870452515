import { JobDetail } from './../components/organisms/JobDetail';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

export const ViewJob = () => {
  const params = useParams();
  const [id] = useState(params.id);

  return <JobDetail id={id} />;
};

import { Theme } from '@emotion/react';

export const breakpoints: Record<string, number> = {
  mobile: 360,
  tablet: 641,
  laptop: 1001,
  desktop: 1201,
  desktopL: 1600
};

const mq = Object.entries(breakpoints).reduce(
  (prev, [key, breakpoint]) => ({ ...prev, [key]: `@media (min-width: ${breakpoint}px)` }),
  {} as Record<string, string>
);

const theme: Theme = {
  colors: {
    primary: '#333F48',
    //secondary: '#981D97',
    secondary: '#008c95',
    primaryText: '#323231',
    secondaryText: '#5F5F5F',
    primaryHover: '#47525A',
    secondaryHover: '#981D97E5',
    secondaryButton: '#BBBBBA',
    scrim: '#000000A3',
    disabled: '#E8E8E8',
    mainBorder: '#D1D1D1',
    mainBodyBg: '#F5F5F5',
    modalBg: '#F9F9F9',
    accentPrimary: '#9B7793',
    accentSecondary: '#2C5697',
    white: '#FFFFFF',
    error: '#C1292E'
  },
  mq,
  fontSizes: {
    h1: '2.75rem',
    h2: '2rem',
    h3: '1.25rem',
    h4: '1.125rem',
    body1: '1rem',
    body2: '0.875rem',
    caption: '0.75rem',
    desktopH1: '3.375rem',
    desktopH2: '2.25rem',
    desktopH3: '1.5rem'
  },
  boxShadows: {
    small: '0px 8px 20px 5px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 6px 10px rgba(0, 0, 0, 0.02)',
    medium: '0px 6px 10px rgba(0, 0, 0, 0.14)'
  }
};

export default theme;

import React from 'react';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';
import { BsFillFileEarmarkLock2Fill, BsXLg } from 'react-icons/bs';
import Button from '../atoms/Button';
import CloseButton from '../atoms/CloseButton';
import Slider from '../atoms/Slider';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Spacer24, Spacer48, Spacer8 } from '../atoms/Spacer';
import CheckboxTick from './../icons/check.svg';
import { SharedFolder } from '@/types/Jobs';
import IconCircle from '../atoms/IconCircle';

dayjs.extend(utc);

type Props = {
  evidence: SharedFolder;
  lockAccessOpen: boolean;
  lockAccess: (evidenceId: string, identifier: string, confirmLock: boolean) => void;
  closeLockAccess: () => void;
};
const CheckboxWrap = styled('div')`
  margin-bottom: 20px;
`;

const TextWrapper = styled('div')`
  width: 100%;
  height: 240px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  margin: 20px auto 50px auto;
  padding: 20px;
  font-size: 15px;
`;
const AccessWrapper = styled('div')`
  background: #efefef;
  padding: 60px 50px 40px 50px;

  h2 {
    text-align: center;
    font-size: 22px;
    margin: 15px 0 30px 0;
  }

  .details {
    margin: 20px 0 60px;
    padding: 0;
    list-style: disc;

    li {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
    }

    strong {
      font-size: 14px;
    }
  }
`;

const InlineError = styled.div(() => ({
  color: '#CC0000',
  marginTop: '5px',
  width: '100%',
  marginLeft: 0
}));
const CheckboxLabel = styled.label(({ theme }) => ({
  marginLeft: 0,
  marginBottom: '20px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  fontSize: '18px',

  input: {
    position: 'absolute',
    left: '-3000em'
  },

  'input+span': {
    border: '1px solid',
    borderColor: theme.colors.primary,
    background: theme.colors.white,
    width: '25px',
    height: '25px',
    display: 'block',
    borderRadius: '4px',
    marginRight: '10px',
    marginBottom: '2px',
    cursor: 'pointer'
  },

  'input:checked + span': {
    backgroundColor: theme.colors.secondary,
    borderColor: theme.colors.secondary,
    color: '#FFFFFF',
    backgroundSize: '25px 25px',
    backgroundPosition: '-4px',
    backgroundImage: `url(` + CheckboxTick + `)`
  }
}));

const BulletUl = styled('ul')`
  li {
    list-style: disc;
  }
`;

export const LockAccess = ({ evidence, lockAccessOpen, lockAccess, closeLockAccess }: Props) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();

  const onSubmit = (data: any) => {
    const id = evidence.id || '';
    const identifier = evidence.identifier || '';
    lockAccess(id, identifier, data.confirmLock);
    reset();
  };
  const handleCloseSideBar = () => {
    reset();
    closeLockAccess();
  };
  return (
    <Slider open={lockAccessOpen} width='500px'>
      <AccessWrapper>
        <IconCircle className='xl'>
          <BsFillFileEarmarkLock2Fill />
        </IconCircle>
        <h2>Lock Evidence</h2>
        <CloseButton aria-label='Close' onClick={handleCloseSideBar}>
          <BsXLg />
        </CloseButton>
        <TextWrapper>
          <BulletUl>
            <li>Sets evidence to read-only</li>
            <li>Denies access form DataCollection VMs</li>
            <li>Allows access from Forensic VMs</li>
            <li>Prohibits the locked evidence and its associated matter from being deleted</li>
            <li>Initiates Chain of Custody tracking</li>
          </BulletUl>
          <Spacer24 />
          <div> This cannot be undone.</div>
          <Spacer8 />
          <div> Please select &quot;I understand&quot; to continue.</div>
        </TextWrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CheckboxWrap>
            <CheckboxLabel>
              <input type='checkbox' {...register('confirmLock', { required: true })} />
              <span></span>
              <span>I understand, please lock</span>
            </CheckboxLabel>
            {errors.confirmLock && <InlineError>This field is required</InlineError>}
          </CheckboxWrap>
          <Spacer48 />
          <Button type='submit' minWidth='400px' minHeight='55px' fontSize='22px'>
            Lock
          </Button>
        </form>
      </AccessWrapper>
    </Slider>
  );
};

import HighlightPanel from '../../atoms/HighlightPanel';
import styled from '@emotion/styled';

export const AnalystsWrapper = styled('div')`
  background: #efefef;
  padding: 60px 40px 40px 40px;

  h2 {
    text-align: center;
    font-size: 22px;
    margin: 15px 0;
  }

  h3 {
    font-size: 18px;
    margin: 15px 0;
  }
`;
export const AnalystPanel = styled(HighlightPanel)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  > div {
    display: flex;
    align-items: center;
  }
  span {
    max-width: 260px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
  }

  button {
    font-size: 18px;
    border: 0;
    padding: 0;
    background: transparent;
    cursor: pointer;
  }
  button svg {
    margin-top: 7px;
  }
`;

export const AddAnalysts = styled('div')`
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  button + div {
    flex: none;
    width: 100%;
    margin: 10px 0 30px;
  }

  button + button {
    margin-top: 30px;
  }
`;
export const FormField = styled.div(() => ({
  display: 'flex',
  marginBottom: '16px',
  flexWrap: 'wrap'
}));

export const OuterWrapper = styled('div')`
  min-height: 160px;
`;

import styled from '@emotion/styled';
import React, { ReactElement, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { BsBoxArrowRight, BsBoxArrowUpRight, BsDisplay, BsInbox } from 'react-icons/bs';
import { UserContext, iUserContext } from './../RootComponent';
import { ReactComponent as Logo } from './../icons/logo.svg';
import { useMsal } from '@azure/msal-react';
import { PageRoutes } from 'routes';

const Header = styled('div')`
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;

  &.Dev {
    background-color: #c1292e;
  }

  &.Test {
    background-color: #ecb604;
  }
  a {
    color: ${props => props.theme.colors.white};
    text-decoration: none;
  }

  > div {
    display: flex;
  }

  .brand {
    display: flex;
    padding-left: 15px;
    margin-right: 25px;
  }

  .brand svg {
    margin-right: 25px;
    fill: #fff;
    width: 88px;
    height: auto;
    padding-bottom: 7px;
  }

  .brand span {
    margin: 10px 0;
    padding: 5px 25px;
    border-left: 1px solid #666;
    font-size: 15px;
  }

  svg {
    margin-right: 10px;
    font-size: 0.9em;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
  }

  li {
    margin-right: 10px;
  }

  li a {
    display: flex;
    align-items: center;
  }

  .main-menu {
    font-size: 18px;
  }
  .main-menu a {
    margin-right: 20px;
    position: relative;
    height: 49px;
  }

  .main-menu a.active {
    &:after {
      content: '';
      width: 100%;
      height: 2px;
      display: block;
      position: absolute;
      bottom: 0;
      background: ${props => props.theme.colors.secondary};
    }
  }

  .user-info {
    margin: 10px 0;
    padding: 5px 25px;
    border-left: 1px solid #666;
    margin-left: 15px;
  }

  .logout {
    background: ${props => props.theme.colors.secondary};
    color: #fff;
    border: 0;
    padding: 10px;
    font-size: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`;

const SiteHeader = (): ReactElement => {
  const { user } = useContext<iUserContext>(UserContext);
  const { instance } = useMsal();
  const location = useLocation();

  const handleLogout = async () => {
    const currentAccount = instance.getActiveAccount();
    await instance.logoutRedirect({ account: currentAccount });
  };
  const environmentName = process.env.REACT_APP_ENVIRONMENT_NAME ? process.env.REACT_APP_ENVIRONMENT_NAME : '';

  return (
    <>
      <Header className={environmentName}>
        <div>
          <div className='brand'>
            <Logo />
            <span>AdaApp{' ' + environmentName}</span>
          </div>
          <ul className='main-menu'>
            <li>
              <a
                href={PageRoutes.Root}
                className={
                  location.pathname === PageRoutes.Root || location.pathname === PageRoutes.CreateJob
                    ? 'active'
                    : '' || location.pathname.indexOf('/viewjob') > -1
                    ? 'active'
                    : ''
                }>
                <BsInbox />
                Matter Management
              </a>
            </li>
            <li>
              <a
                href={PageRoutes.Resources}
                className={
                  location.pathname === PageRoutes.Resources || location.pathname === PageRoutes.CreateVm
                    ? 'active'
                    : ''
                }>
                <BsDisplay />
                Resource Management
              </a>
            </li>
          </ul>
        </div>
        <div>
          <ul className='sub-menu'>
            <li>
              <a
                href='https://client.wvd.microsoft.com/arm/webclient/index.html'
                target='_blank'
                rel='noopener noreferrer'>
                <BsBoxArrowUpRight />
                AVD Portal
              </a>
            </li>
          </ul>
          <div className='user-info'>{user.name}</div>
          <button className='logout' onClick={handleLogout}>
            <BsBoxArrowRight /> Logout
          </button>
        </div>
      </Header>
    </>
  );
};

export default SiteHeader;

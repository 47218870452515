import styled from '@emotion/styled';

const TextArea = styled.textarea(({ theme }) => ({
  outline: 'none',
  borderRadius: '4px',
  borderWidth: '1px',
  borderColor: theme.colors.primary,
  background: theme.colors.white,
  padding: '0 0 0 12px',
  lineHeight: '24px',
  width: '100%',
  maxWidth: '720px',
  color: theme.colors.primary,
  fontSize: theme.fontSizes.body1,

  '::placeholder': {
    color: theme.colors.secondaryText
  },

  '&:hover': {
    borderColor: theme.colors.secondaryText
  },

  '&:focus': {
    borderColor: theme.colors.secondary,

    '&::placeholder': {
      color: 'transparent'
    }
  },

  '&:disabled': {
    border: '1px solid white',
    backgroundColor: 'white'
  }
}));

export default TextArea;

import styled from '@emotion/styled';

export const PageError = styled.div(() => ({
  padding: '15px',
  borderRadius: '4px',
  border: '1px solid #CC0000',
  backgroundColor: '#F7C5C1',
  marginBottom: '20px',
  maxWidth: '880px'
}));

export const FormField = styled.div(() => ({
  display: 'flex',
  marginBottom: '16px',
  flexWrap: 'wrap'
}));

export const InlineError = styled.div(() => ({
  color: '#CC0000',
  marginTop: '5px',
  width: '100%',
  marginLeft: 0
}));

import styled from '@emotion/styled';

export const DetailsPanel = styled('div')`
  background: #fff;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
  position: relative;
  padding: 40px;
  margin-bottom: 40px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
  }

  li {
    width: 25%;
    min-width: 25%;
    margin-bottom: 40px;
    line-height: 1.4;
  }

  li.wide {
    width: 50%;
  }

  li:last-of-type {
    width: 100%;
    margin-bottom: 0;
  }

  li strong {
    display: block;
    margin-bottom: 5px;
  }

  button {
    position: absolute;

    right: 0;
    background: ${props => props.theme.colors.primary};
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    font-size: ${props => props.theme.fontSizes.body2};
  }

  button svg {
    margin-right: 10px;
  }
  button:nth-of-type(1) {
    top: 0;
  }
  button:nth-of-type(2) {
    bottom: 0;
  }
`;

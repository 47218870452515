import styled from '@emotion/styled';

export const BlockButton = styled('button')`
  background: ${props => props.theme.colors.primary};
  color: #fff;
  cursor: pointer;
  padding: 15px;
  width: 20%;
  right: 0;
  cursor: pointer;
  font-size: ${props => props.theme.fontSizes.body2};
`;

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;
export const BlockSection = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const TableRow = styled('tr')`
  cursor: pointer;
  &.selected td {
    background: #a3d2d5;
  }
`;
export const SmallText = styled('span')`
  font-size: 16px;
  font-weight: normal;
`;

export const IconWrapperWithAnimation = styled('div')`
  display: inline-block;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.5);
  }
`;

export const IconWrapper = styled('div')`
  display: inline-block;
  transition: transform 0.3s;
`;

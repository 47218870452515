import styled from '@emotion/styled';

const TextInput = styled.input<{ inputStyling?: Record<string, string | undefined> }>(({ theme, inputStyling }) => ({
  outline: 'none',
  borderRadius: '4px',
  borderWidth: '1px',
  borderColor: theme.colors.primary,
  borderStyle: 'solid',
  background: theme.colors.white,
  padding: '0 0 0 12px',
  height: inputStyling?.inputHeight || '40px',
  lineHeight: inputStyling?.inputHeight || '24px',
  width: '100%',
  maxWidth: '720px',
  color: theme.colors.primary,
  fontSize: inputStyling?.fontSize || theme.fontSizes.body1,

  '::placeholder': {
    color: theme.colors.secondaryText
  },

  '&:focus': {
    borderColor: theme.colors.secondary,

    '&::placeholder': {
      color: 'transparent'
    }
  },

  '&:disabled': {
    border: '1px solid white',
    backgroundColor: 'white'
  }
}));

export default TextInput;

import { TextInputAutoCompleteOption } from '@/components/molecules/TextInputAutoComplete';
import { Item, Job, JobUser } from '@/types/Jobs';
import { Persona } from '@/types/SessionHosts';
import { PERSONAS_ID, PERSONAS_NAME } from 'constants/application';

export const mapJobsDropdownFormat = (list: Job[]): TextInputAutoCompleteOption[] =>
  list.map(({ id, title, matterId }) => ({ id, name: title, matterId }));

export const mapJobUserList = (list: JobUser[]): Item[] => list.map(({ user }) => ({ id: user.id, name: user.name }));

export const getVmSizeByPersona = (persona: Persona) => {
  switch (persona) {
    case PERSONAS_ID.DAILY_DRIVER:
    case PERSONAS_ID.DATA_COLLECTION:
      return [
        {
          name: 'Small (4 CPU, 16GB RAM)',
          id: 'small'
        },
        {
          name: 'Medium (8 CPU, 16GB RAM)',
          id: 'medium'
        },
        {
          name: 'Large (16 CPU, 32GB RAM)',
          id: 'large'
        }
      ];
      break;
    case PERSONAS_ID.FORENSIC:
      return [
        {
          name: 'Small (8 CPU, 64GB RAM)',
          id: 'small'
        },
        {
          name: 'Medium (16 CPU, 128GB RAM)',
          id: 'medium'
        },
        {
          name: 'Large (32 CPU, 256GB RAM)',
          id: 'large'
        }
      ];
      break;
  }
};

export const getDataDiskSizeByPersona = (persona: Persona) => {
  switch (persona) {
    case PERSONAS_ID.DAILY_DRIVER:
      return [
        {
          name: '512 GB',
          id: '512'
        }
      ];
      break;
    case PERSONAS_ID.DATA_COLLECTION:
      return [
        {
          name: 'None',
          id: '0'
        },
        {
          name: '64 GB',
          id: '64'
        },
        {
          name: '128 GB',
          id: '128'
        },
        {
          name: '256 GB',
          id: '256'
        },
        {
          name: '512 GB',
          id: '512'
        },
        {
          name: '1024 GB',
          id: '1024'
        },
        {
          name: '2048 GB',
          id: '2048'
        }
      ];
      break;
    case PERSONAS_ID.FORENSIC:
      return [
        {
          name: '4096 GB',
          id: '4096'
        },
        {
          name: '8192 GB',
          id: '8192'
        },
        {
          name: '16384 GB',
          id: '16384'
        }
      ];
      break;
  }
};

export const personas = [
  {
    name: PERSONAS_NAME.DAILY_DRIVER,
    id: PERSONAS_ID.DAILY_DRIVER
  },
  {
    name: PERSONAS_NAME.FORENSIC,
    id: PERSONAS_ID.FORENSIC
  },
  {
    name: PERSONAS_NAME.DATA_COLLECTION,
    id: PERSONAS_ID.DATA_COLLECTION
  }
];

export const mapSubmitPayload = (data: any) => {
  const payload = {
    ...data,
    dataDiskSizeInGB: Number(data.dataDiskSizeInGB)
  };
  for (const key in data) {
    if (Object.hasOwnProperty.call(payload, key)) {
      if (payload[key] === '' || payload[key] === '0' || payload[key] === 0) {
        payload[key] = null;
      }
    }
  }
  return payload;
};

export const DADTADISK_DAILYDRIVER_DEFAULT = '512';

export const DADTADISK_FORENSIC_DEFAULT = '4096';

export const DADTADISK_DATACOLLECTION_DEFAULT = '0';

export const VM_DEFAULT_SIZE = 'small';

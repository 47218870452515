import styled from '@emotion/styled';

const Modal = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1;

  .modal-bg {
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    position: fixed;
  }
  .modal-body {
    width: 500px;
    margin: 200px auto;
    background: #fff;
    position: relative;
    padding: 20px;
  }
`;

export default Modal;

import theme from './theme';

export const globalStyling = `
  html,
  body {
    padding: 0;
    margin: 0;
    font-family: 'Lato', sans-serif;
    color: ${theme.colors.primaryText};
    background: ${theme.colors.mainBodyBg};
    min-width: 1200px;
    overflow-x: auto;
    overflow-y: scroll;
  }

  button {
    font-family: inherit;
  }

  a {
    color: ${theme.colors.secondary};
    text-decoration: underline;
  }

  * {
    box-sizing: border-box;
  }

  h1 {
    font-size: ${theme.fontSizes.h1};
    margin-top: 0;
  }

  h2 {
    font-size: ${theme.fontSizes.h2};
    margin-top: 0;
  }

  h3 {
    font-size: ${theme.fontSizes.h3};
    margin-top: 0;
  }

  h4 {
    font-size: ${theme.fontSizes.h4};
    margin-top: 0;
  }

  ${theme.mq['laptop']} {
    h1 {
      font-size: ${theme.fontSizes.desktopH1};
    }

    h2 {
      font-size: ${theme.fontSizes.desktopH2};
    }

    h3 {
      font-size: ${theme.fontSizes.desktopH3};
    }
`;

import React, { useEffect, useState } from 'react';
import { SearchContainer, SearchTxt, ClearBtn } from './styled';
import { BsXLg } from 'react-icons/bs';
import useDebounce from '../../../hooks/useDebounce';

type componentProps = {
  onSearch: (query: string) => void;
  value?: string | null;
  placeholder?: string;
  isResetValue?: boolean;
};
const _SearchBar: React.FC<componentProps> = ({ onSearch, value, placeholder, isResetValue }) => {
  const [inputValue, setInputValue] = useState(value || '');
  const delay = 500;
  const debouncedInputValue = useDebounce(inputValue, delay);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };
  const handleRemove = () => {
    setInputValue('');
  };

  useEffect(() => {
    onSearch(debouncedInputValue.trim());
  }, [debouncedInputValue]);

  useEffect(() => {
    if (isResetValue) {
      setInputValue('');
    }
  }, [value]);

  return (
    <SearchContainer>
      <SearchTxt type='text' value={inputValue} placeholder={placeholder || 'Search...'} onChange={handleInput} />
      <ClearBtn onClick={handleRemove}>
        <BsXLg />
      </ClearBtn>
    </SearchContainer>
  );
};
export const SearchBar = React.memo(_SearchBar);

import axios from 'axios';

export type Update = {
  friendlyName: string;
};
export const updateVMFriendlyName = async (id: string, update: Update): Promise<any> => {
  const res = await axios.post(
    process.env.REACT_APP_API_BASE_URL + '/api/sessionhosts/' + id + '/updateFriendlyName',
    update
  );

  return res.data;
};

import React from 'react';
import { BsChevronRight } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import { PageRoutes } from 'routes';
import BreadCrumbs from './../components/atoms/BreadCrumbs';
import { EvidenceBoard } from '../components/organisms/EvidenceBoard';

export const Evidence = () => {
  const { jobId } = useParams();

  return (
    <>
      <BreadCrumbs>
        <a href={PageRoutes.Root}>Matter Management</a> <BsChevronRight /> <a href={`/viewjob/${jobId}`}>{jobId}</a>{' '}
        <BsChevronRight /> <span>Evidence</span>
      </BreadCrumbs>
      <EvidenceBoard jobId={jobId || ''} />
    </>
  );
};

import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React from 'react';
import { BsDisplay, BsXLg } from 'react-icons/bs';
import Button from '../atoms/Button';
import CloseButton from '../atoms/CloseButton';
import IconCircle from '../atoms/IconCircle';
import Slider from '../atoms/Slider';
import { Resource } from './../../types/SessionHosts';

type Props = {
  machine: Resource;
  redeployVMOpen: boolean;
  redeployVM: () => void;
  closeRedeployVM: () => void;
};

const AccessWrapper = styled('div')`
  background: #efefef;
  padding: 60px 90px 40px 90px;

  h2 {
    text-align: center;
    font-size: 22px;
    margin: 15px 0 30px 0;
  }

  .details {
    margin: 20px 0 60px;
    padding: 0;
    list-style: none;

    li {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
    }

    strong {
      font-size: 14px;
    }
  }
`;

export const RedeployVM = ({ machine, redeployVMOpen, redeployVM, closeRedeployVM }: Props) => {
  return (
    <Slider open={redeployVMOpen} width='500px'>
      <AccessWrapper>
        <div>
          <IconCircle className='xl'>
            <BsDisplay />
          </IconCircle>
          <h2>Redeploy VM</h2>
          <CloseButton onClick={closeRedeployVM}>
            <BsXLg />
          </CloseButton>

          <ul className='details'>
            {machine.name !== undefined ? (
              <li>
                <strong>VM: </strong>
                <span>{machine.name}</span>
              </li>
            ) : (
              <li>
                <strong>ID: </strong>
                <span>{machine.id}</span>
              </li>
            )}
            <li>
              <strong>User: </strong>
              <span>{machine?.user?.name || ''}</span>
            </li>
            <li>
              <strong>Region: </strong>
              <span>{machine.region}</span>
            </li>
            <li>
              <strong>Size: </strong>
              <span>{machine.size}</span>
            </li>
            <li>
              <strong>Disk Size: </strong>
              <span>{machine.dataDiskSizeInGB}</span>
            </li>
            <li>
              <strong>Status: </strong>
              <span>{machine.status}</span>
            </li>
            <li>
              <strong>Created Date: </strong>
              <span>{dayjs.utc(machine.created).local().format('DD-MMM-YYYY')}</span>
            </li>
          </ul>

          <Button
            type='button'
            onClick={() => {
              redeployVM();
            }}>
            Redeploy VM
          </Button>
        </div>
      </AccessWrapper>
    </Slider>
  );
};

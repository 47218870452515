import styled from '@emotion/styled';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BsFillPersonFill, BsPlus, BsTrash, BsXLg } from 'react-icons/bs';
import Button from '../atoms/Button';
import CloseButton from '../atoms/CloseButton';
import HighlightPanel from '../atoms/HighlightPanel';
import IconCircle from '../atoms/IconCircle';
import Label from '../atoms/Label';
import LinkButton from '../atoms/LinkButton';
import Slider from '../atoms/Slider';
import TextInputAutoComplete from '../molecules/TextInputAutoComplete';
import { UpdateJobUsers, JobUser, User } from './../../types/Jobs';

type Props = {
  editAnalystsOpen: boolean;
  primaryAnalyst: Array<JobUser>;
  secondaryAnalysts: Array<JobUser>;
  updateAnalysts: (analysts: UpdateJobUsers) => void;
  closeEditAnalysts: () => void;
};

const AnalystsWrapper = styled('div')`
  background: #efefef;
  padding: 60px 40px 40px 40px;

  h2 {
    text-align: center;
    font-size: 22px;
    margin: 15px 0;
  }

  h3 {
    font-size: 18px;
    margin: 15px 0;
  }
`;
const AnalystPanel = styled(HighlightPanel)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  > div {
    display: flex;
    align-items: center;
  }
  span {
    max-width: 260px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
  }

  button {
    font-size: 18px;
    border: 0;
    padding: 0;
    background: transparent;
    cursor: pointer;
  }
  button svg {
    margin-top: 7px;
  }
`;

const AddAnalysts = styled('div')`
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  button + div {
    flex: none;
    width: 100%;
    margin: 10px 0 30px;
  }

  button + button {
    margin-top: 30px;
  }
`;

export const EditAnalysts = ({
  editAnalystsOpen,
  primaryAnalyst,
  secondaryAnalysts,
  updateAnalysts,
  closeEditAnalysts
}: Props) => {
  const [selectedAnalysts, setSelectedAnalysts] = useState<Array<JobUser>>();
  const [selectedPrimary, setSelectedPrimary] = useState<Array<JobUser>>(primaryAnalyst);
  const [selectedSecondary, setSelectedSecondary] = useState<Array<JobUser>>(secondaryAnalysts);
  const [users, setUsers] = useState<Array<User>>([]);
  const [showPrimaryAddField, setShowPrimaryAddField] = useState(false);
  const [showSecondaryAddField, setShowSecondaryAddField] = useState(false);

  useEffect(() => {
    setSelectedPrimary(primaryAnalyst);
    setSelectedSecondary(secondaryAnalysts);
  }, [primaryAnalyst, secondaryAnalysts]);

  const remove = (selected: JobUser) => {
    selected.type === 'Primary'
      ? setSelectedPrimary(selectedPrimary.filter(item => item !== selected))
      : setSelectedSecondary(selectedSecondary.filter(item => item !== selected));
    setSelectedAnalysts(selectedAnalysts?.filter(item => item !== selected));
  };

  const add = (id: string, type: string) => {
    const selectedUser = users?.filter(user => {
      return user.id === id;
    });
    const jobUser: JobUser = {
      type: type,
      user: selectedUser[0],
      id: '',
      created: '',
      lastModified: ''
    };
    type === 'Primary'
      ? setSelectedPrimary(selectedPrimary.concat(jobUser))
      : setSelectedSecondary(selectedSecondary.concat(jobUser));
    setSelectedAnalysts(selectedAnalysts?.concat(jobUser));

    type === 'Primary' ? setShowPrimaryAddField(false) : setShowSecondaryAddField(false);
    resetUsers();
  };

  const update = () => {
    const resp: UpdateJobUsers = {};
    selectedPrimary.length > 0 && (resp.primaryUserId = selectedPrimary[0].user.id);

    const secondaryIds = [];
    if (selectedSecondary.length > 0) {
      for (let i = 0; i < selectedSecondary.length; i++) {
        secondaryIds.push(selectedSecondary[i].user.id);
      }
    }
    secondaryIds.length > 0 && (resp.secondaryUserIds = secondaryIds);

    updateAnalysts(resp);
  };

  const getUsers = async (search: string) => {
    const userData = await axios.get(process.env.REACT_APP_API_BASE_URL + '/api/users/search?name=' + search);
    setUsers(userData.data.users);
  };

  const resetUsers = () => {
    setUsers([] as any);
  };

  return (
    <Slider open={editAnalystsOpen} width='460px'>
      <AnalystsWrapper>
        <div>
          <CloseButton aria-label='Close' onClick={closeEditAnalysts}>
            <BsXLg />
          </CloseButton>
          <IconCircle className='xl'>
            <BsFillPersonFill />
          </IconCircle>
          <h2>Analysts</h2>
          <h3>Primary Analyst</h3>

          {selectedPrimary!?.map((primaryAnalyst, index) => (
            <div key={index}>
              <AnalystPanel>
                <div>
                  <IconCircle className='big'>
                    <BsFillPersonFill />
                  </IconCircle>
                  <span>{primaryAnalyst.user.name}</span>
                </div>
                <button
                  aria-label={'Remove: ' + primaryAnalyst.user.name}
                  onClick={() => {
                    remove(primaryAnalyst);
                  }}>
                  <BsTrash />
                </button>
              </AnalystPanel>
            </div>
          ))}

          {selectedPrimary.length === 0 && (
            <AddAnalysts>
              <LinkButton
                type='button'
                onClick={() => {
                  setShowPrimaryAddField(true);
                  setShowSecondaryAddField(false);
                }}>
                <IconCircle>
                  <BsPlus />
                </IconCircle>{' '}
                Add primary analyst
              </LinkButton>
              {showPrimaryAddField && (
                <div>
                  <Label>Primary Analyst</Label>
                  <TextInputAutoComplete
                    setSelection={true}
                    onSetOption={option => {
                      add(option, 'Primary');
                    }}
                    searchResults={users}
                    searchValue={getUsers}
                    changeSelection={resetUsers}
                    ariaLabel='Primary Analyst'
                  />
                </div>
              )}
            </AddAnalysts>
          )}

          <h3>Secondary Analysts:</h3>
          {selectedSecondary!?.map((secondaryAnalyst, index) => (
            <AnalystPanel key={index}>
              <div>
                <IconCircle className='big'>
                  <BsFillPersonFill />
                </IconCircle>
                <span>{secondaryAnalyst.user.name}</span>
              </div>
              <button
                aria-label={'Remove: ' + secondaryAnalyst.user.name}
                onClick={() => {
                  remove(secondaryAnalyst);
                }}>
                <BsTrash />
              </button>
            </AnalystPanel>
          ))}

          <AddAnalysts>
            <LinkButton
              type='button'
              onClick={() => {
                setShowSecondaryAddField(true);
                setShowPrimaryAddField(false);
              }}>
              <IconCircle>
                <BsPlus />
              </IconCircle>{' '}
              Add secondary analyst
            </LinkButton>

            {showSecondaryAddField && (
              <div>
                <Label>Secondary Analyst</Label>
                <TextInputAutoComplete
                  setSelection={true}
                  onSetOption={option => {
                    add(option, 'Secondary');
                  }}
                  searchResults={users}
                  searchValue={getUsers}
                  changeSelection={resetUsers}
                  ariaLabel='Secondary Analyst'
                />
              </div>
            )}

            <Button
              onClick={() => {
                update();
              }}>
              Save changes
            </Button>
          </AddAnalysts>
        </div>
      </AnalystsWrapper>
    </Slider>
  );
};

import styled from '@emotion/styled';

const TabButton = styled('button')`
  border: 0;
  padding: 12px;
  background: transparent;
  cursor: pointer;
  font-weight: bold;
  color: #aaa;
  &.active {
    color: inherit;
    border-bottom: 2px solid;
    border-bottom-color: ${props => props.theme.colors.secondary};
  }
`;

export default TabButton;

import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import TextInput from './../atoms/TextInput';
import Label from './../atoms/Label';
import DropDownSelector from './../atoms/DropDownSelector';
import TextArea from './../atoms/TextArea';
import Button from './../atoms/Button';
import { Spinner } from '../molecules/Spinner';
import TextInputAutoComplete from './../molecules/TextInputAutoComplete';
import { CheckboxLabel } from '../atoms/CheckboxLabel';

const FormField = styled.div(() => ({
  display: 'flex',
  marginBottom: '16px',
  flexWrap: 'wrap'
}));

const InlineError = styled.div(() => ({
  color: '#CC0000',
  marginTop: '5px',
  width: '100%',
  marginLeft: 0
}));

const PageError = styled.div(() => ({
  padding: '15px',
  borderRadius: '4px',
  border: '1px solid #CC0000',
  backgroundColor: '#F7C5C1',
  marginBottom: '20px',
  maxWidth: '880px',
  wordWrap: 'break-word'
}));

type region = {
  name: string;
  id: string;
};

const regions = [
  {
    name: 'Australia',
    id: 'Australia'
  },
  {
    name: 'UK',
    id: 'UK'
  }
];

export const JobForm = () => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [primaryAnalyst] = useState('');
  const [users, setUsers] = useState();
  const [serverError, setServerError] = useState<string>();

  const createNew = () => {
    reset();
    setIsSubmitted(false);
    setUsers([] as any);
    setServerError('');
  };

  const getUsers = async (search: string) => {
    const userData = await axios.get(process.env.REACT_APP_API_BASE_URL + '/api/users/search?name=' + search);
    setUsers(userData.data.users);
  };

  const resetUsers = () => {
    setUsers([] as any);
  };

  const onSubmit = async (data: any) => {
    setIsLoading(true);

    Object.keys(data).forEach(key => {
      if (data[key] === '' || data[key] == null) {
        delete data[key];
      }
    });

    await axios
      .post(process.env.REACT_APP_API_BASE_URL + '/api/jobs', data)
      .then(() => {
        setIsLoading(false);
        setIsSubmitted(true);
      })
      .catch(error => {
        setIsLoading(false);
        setServerError(error.response.data.detail);
      });
  };

  return (
    <>
      {!isLoading ? (
        <>
          {!isSubmitted ? (
            <>
              {serverError && <PageError data-testid='pageError'>{serverError}</PageError>}
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormField>
                  <Label htmlFor='matterId'>Matter ID</Label>
                  <TextInput
                    type='text'
                    id='matterId'
                    {...register('matterId', { required: true, pattern: /^[a-zA-Z0-9-\s]+$/ })}
                  />
                  {errors.matterId?.type === 'required' && <InlineError>This field is required</InlineError>}
                  {errors.matterId?.type === 'pattern' && (
                    <InlineError>Matter ID can only contain letters, numbers and dashes</InlineError>
                  )}
                </FormField>
                <FormField>
                  <Label htmlFor='title'>Title</Label>
                  <TextInput type='text' id='title' {...register('title', { required: true })} />
                  {errors.title && <InlineError>This field is required</InlineError>}
                </FormField>
                <FormField>
                  <Label htmlFor='description'>Description</Label>
                  <TextArea
                    rows={4}
                    key='description'
                    id='description'
                    {...register('description', { pattern: /^[0-9A-Za-z\s\-,.]+$/ })}></TextArea>
                  {errors.description?.type === 'pattern' && (
                    <InlineError>Description can only contain letters, numbers, dashes, periods and commas</InlineError>
                  )}
                </FormField>
                <FormField>
                  <Label htmlFor='region'>Region</Label>
                  <DropDownSelector
                    defaultValue=''
                    placeholder='Please select'
                    ariaLabel='Region'
                    {...register('region', { required: true })}
                    options={regions!?.map((region: region) => ({
                      label: region.name,
                      value: region.id
                    }))}
                  />
                  {errors.region && <InlineError>This field is required</InlineError>}
                </FormField>
                <FormField>
                  <Label htmlFor='primaryAnalyst'>Primary Analyst</Label>
                  <Controller
                    control={control}
                    name='primaryUserId'
                    defaultValue={primaryAnalyst}
                    render={({ field: { onChange } }) => (
                      <TextInputAutoComplete
                        setSelection={true}
                        onSetOption={onChange}
                        searchResults={users}
                        searchValue={getUsers}
                        changeSelection={resetUsers}
                        ariaLabel='Primary Analyst'
                      />
                    )}
                  />
                </FormField>

                <CheckboxLabel>
                  <input type='checkbox' {...register('currentUserAsSecondaryUser')} />
                  <span></span>
                  <span>Add me as a Secondary Analyst</span>
                </CheckboxLabel>

                <Button type='submit'>Submit</Button>
              </form>
            </>
          ) : (
            <div>
              <h3>Your request has been submitted</h3>
              <Button type='button' onClick={createNew} secondary>
                Create new matter
              </Button>
            </div>
          )}
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

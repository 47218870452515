import styled from '@emotion/styled';

const CloseButton = styled.button<{ styling?: Record<string, string> }>(({ styling }) => ({
  position: 'absolute',
  top: styling?.top || '0',
  right: '0',
  border: '0',
  padding: styling?.padding || '15px',
  fontSize: styling?.fontSize || '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#333',
  background: '#dedede',
  cursor: 'pointer'
}));

export default CloseButton;

import styled, { StyledComponent } from '@emotion/styled';

import theme from './../../styles/theme';

type CustomProps = {
  secondary?: boolean;
  hasIcon?: boolean;
  secondaryLink?: boolean;
  minWidth?: string;
  minHeight?: string;
  fontSize?: string;
  customRedLight?: boolean;
  customGreenLight?: boolean;
  backgroundColor?: string;
};

const getSpecificButtonStyling: (
  secondary?: boolean,
  secondaryLink?: boolean,
  isError?: boolean
) => Record<string, unknown> = (secondary?: boolean, secondaryLink?: boolean) => {
  if (secondary) {
    return stylingSecondaryButton;
  }

  if (secondaryLink) {
    return stylingSecondaryLink;
  }

  return stylingPrimaryButton;
};

const stylingPrimaryButton = {
  backgroundColor: theme.colors.primary,
  color: 'white',
  border: '1px solid transparent',
  '&:hover': {
    backgroundColor: theme.colors.primaryHover,
    borderColor: 'transparent',
    color: 'white'
  },
  svg: {
    circle: {
      fill: 'white',
      stroke: 'white',
      '+ path': {
        stroke: 'white'
      }
    },
    path: {
      fill: 'white'
    }
  }
};

const stylingSecondaryButton = {
  backgroundColor: 'transparent',
  color: theme.colors.primary,
  border: `1px solid ${theme.colors.secondaryButton}`,
  '&:hover': {
    backgroundColor: 'transparent',
    borderColor: theme.colors.primaryHover,
    color: theme.colors.primaryHover
  },
  svg: {
    circle: {
      fill: theme.colors.primary,
      stroke: theme.colors.primary,
      '+ path': {
        stroke: theme.colors.primary
      }
    },
    path: {
      fill: theme.colors.primary
    }
  }
};

const basicStyling = {
  color: theme.colors.primary,
  backgroundColor: '#fcc7c3',
  fontSize: '18px',
  border: `1px solid ${theme.colors.secondaryButton}`,
  '&:hover': {
    backgroundColor: '#faa19b',
    borderColor: theme.colors.primaryHover,
    color: theme.colors.primaryHover
  },
  svg: {
    circle: {
      fill: theme.colors.primary,
      stroke: theme.colors.primary,
      '+ path': {
        stroke: theme.colors.primary
      }
    },
    path: {
      fill: theme.colors.primary
    }
  }
};
const customRedLightStyling = {
  ...basicStyling,
  backgroundColor: '#fcc7c3',
  fontSize: '18px',
  '&:hover': {
    backgroundColor: '#faa19b',
    borderColor: theme.colors.primaryHover,
    color: theme.colors.primaryHover
  }
};
const customGreenLightStyling = {
  ...basicStyling,
  backgroundColor: '#ceeed0',
  fontSize: '18px',
  '&:hover': {
    backgroundColor: '#a0d6a5',
    borderColor: theme.colors.primaryHover,
    color: theme.colors.primaryHover
  }
};

const stylingSecondaryLink = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: 0,
  color: theme.colors.secondary,
  marginBottom: '8px !important',
  height: '42px',
  cursor: 'pointer',
  marginRight: 'auto',
  border: 'none',
  background: 'none',
  fontSize: theme.fontSizes.body1,
  padding: 0,

  svg: {
    marginRight: '8px',
    position: 'relative',
    top: '1.5px'
  }
};

const Button = styled.button<CustomProps>(
  ({ secondary, hasIcon, secondaryLink, minWidth, minHeight, fontSize, customRedLight, customGreenLight }) => {
    const specificButtonStyling = getSpecificButtonStyling(secondary, secondaryLink);

    return {
      cursor: 'pointer',
      display: 'grid',
      alignItems: 'center',
      justifyContent: 'center',
      textDecoration: 'none',
      gridTemplateColumns: hasIcon ? 'auto auto' : 'auto',
      gap: '14px',
      minWidth: minWidth ? minWidth : '100px',
      minHeight: minHeight ? minHeight : '40px',
      borderRadius: '6px',
      padding: '9px 20px',
      fontSize: fontSize ? fontSize : theme.fontSizes.body2,
      fontWeight: 'bold',

      '&:disabled': {
        background: theme.colors.disabled,
        color: theme.colors.secondaryText,
        border: '1px solid transparent',
        cursor: 'default',

        '&:hover': {
          background: theme.colors.disabled,
          color: theme.colors.secondaryText,
          border: '1px solid transparent'
        }
      },
      ...(customRedLight && customRedLightStyling),
      ...(customGreenLight && customGreenLightStyling),
      ...(!customRedLight && !customGreenLight && specificButtonStyling)
    };
  }
);

export default Button as StyledComponent<
  {
    as?: string;
  } & CustomProps,
  JSX.IntrinsicElements['a'] | JSX.IntrinsicElements['button'] | JSX.IntrinsicElements['input']
>;

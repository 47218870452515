import styled from '@emotion/styled';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import {
  BsChevronRight,
  BsFillPersonFill,
  BsInbox,
  BsPencil,
  BsTrash,
  BsPlus,
  BsFillMenuButtonWideFill
} from 'react-icons/bs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Badge from '../atoms/Badge';
import BreadCrumbs from '../atoms/BreadCrumbs';
import HighlightPanel from '../atoms/HighlightPanel';
import IconCircle from '../atoms/IconCircle';
import { Spinner } from '../molecules/Spinner';
import Toast from '../atoms/Toast';
import { AddNote } from './AddNote';
import { EditAnalysts } from './EditAnalysts';
import { EditJobDetail } from './EditJobDetail';
import { EditSharedData } from './EditSharedData';
import { DeleteJob } from './DeleteJob';
import { useNavigate } from 'react-router-dom';
import { Job, JobUser, EditJobDetails, UpdateJobUsers, NotesSearch, EditSharedFolderInfo } from './../../types/Jobs';
import { PageRoutes } from 'routes';
import ResourceItem from '../molecules/ResourceItem/ResourceItem';
import useOnClickOutside from 'hooks/useClickOnOutside';
import { VMDetails } from './VMDetails';
import { AddVMNote } from './AddVMNote';
import { UpdateInternetAccess } from './InternetAccess';
import { UpdateShutdown } from './UpdateShutdown';
import { ScaleVM } from './ScaleVM';
import { RedeployVM } from './RedeployVM';
import { DeleteVM } from './DeleteVM';
import { Scale, ShutdownUpdate } from '@/types/SessionHosts';
import { VmTableTitle } from 'constants/vm';
import SharedFolderInfo from '../molecules/SharedFolderInfo/SharedFolderInfo';
import { PageHeader } from '../atoms/PageHeader';
import { DetailsPanel } from '../atoms/DetailsPanel';
import { Panel } from '../atoms/Panel';
import { VerticalCenter } from '../atoms/VerticalCenter';
import { createCaseData } from 'api/createCaseData';
import ErrorPage from '../../pages/Error/ErrorPage';
import { Update, updateSessionHostInternetAccess } from 'api/updateSessionHostInternetAccess';
import { GENERIC_ERROR_MESSAGE } from '../../constants';
import { updateSessionHostUser } from 'api/updateSessionHostUser';
import { AssignUser } from './AssignUser';
import { UpdateFriendlyName } from './UpdateFriendlyName';
import { updateVMFriendlyName } from 'api/updateVMFriendlyName';

dayjs.extend(utc);

type Props = {
  id: string | undefined;
};

const AnalystGroup = styled('div')`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: calc(100% - 40px);

  > li {
    min-width: 50%;
  }

  > li:first-of-type {
    margin-right: 20px;
  }
  > li:last-of-type {
    margin-left: 20px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  ul li {
    margin-bottom: 15px;
  }

  strong {
    font-size: 18px;
    margin: 15px 0;
    display: block;
  }
`;

const LinkButton = styled('button')`
  border: 0;
  background: transparent;
  color: ${props => props.theme.colors.primary};
  font-size: ${props => props.theme.fontSizes.body2};
  display: flex;
  align-items: center;
  cursor: pointer;
`;
export const NewCreationButton = styled('button')`
  position: absolute;
  top: 0;
  right: 0;
  background: ${props => props.theme.colors.primary};
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  margin-bottom: 15px;
  font-size: ${props => props.theme.fontSizes.body2};

  &[disabled] {
    color: #808080;
    cursor: not-allowed;
    border: '1px solid transparent';
  }
`;

const ShadedTable = styled('table')`
  width: 100%;
  border: 0;
  border-spacing: 0 15px;
  margin-top: 10px;

  th {
    text-align: center;
  }

  tr th:first-of-type {
    text-align: left;
  }

  td {
    background: #ebebeb;
    padding: 15px;
    text-align: center;
  }
  tbody tr td:first-of-type {
    border-radius: 4px 0 0 4px;
    text-align: left;
  }
  tbody tr td:last-of-type {
    border-radius: 0 4px 4px 0;
  }
`;

const RuledTable = styled('table')`
  width: 100%;
  border-spacing: 0;

  th {
    border-bottom: 1px solid #666;
    padding: 10px;
    text-align: left;
  }

  td {
    border-bottom: 1px solid #ccc;
    padding: 15px;
    text-align: left;
  }

  tr td:last-of-type {
    width: 75%;
  }
`;
const Paging = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;

  > span {
    display: block;
    width: 16.325px;
    margin: 0 15px;
  }

  button {
    margin: 0 15px;
    font-weight: bold;
  }
  .active {
    color: ${props => props.theme.colors.secondary};
    text-decoration: underline;
  }
`;

const SmallText = styled('span')`
  font-size: 16px;
  font-weight: normal;
`;

export const JobDetail = ({ id }: Props) => {
  const navigate = useNavigate();
  const [job, setJob] = useState<Job>();
  const elementRef = useRef<HTMLUListElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [notes, setNotes] = useState<NotesSearch>();
  const [toastActive, setToastActive] = useState(false);
  const [isToastForSuccess, setIsToastForSuccess] = useState(false);
  const [toastContent, setToastContent] = useState('');
  const [addNoteOpen, setAddNoteOpen] = useState(false);
  const [deleteJobOpen, setDeleteJobOpen] = useState(false);
  const [editAnalystsOpen, setEditAnalystsOpen] = useState(false);
  const [editDetailsOpen, setEditDetailsOpen] = useState(false);
  const [editSharedDataOpen, setEditSharedDataOpen] = useState(false);
  const [isClicked, setIsClicked] = useState<number | null>(null);
  const [currentSharedData, setCurrentSharedData] = useState<EditSharedFolderInfo>();
  const [primaryUser, setPrimaryUser] = useState<Array<JobUser>>([]);
  const [secondaryUsers, setSecondaryUsers] = useState<Array<JobUser>>([]);
  const [vmDetailsOpen, setVMDetailsOpen] = useState<number | null>(null);
  const [assignUserOpenIndex, setAssignUserOpenIndex] = useState<number | null>(null);
  const [internetAccessOpenIndex, setInternetAccessOpenIndex] = useState<number | null>(null);
  const [updateShutdownOpenIndex, setUpdateShutdownOpenIndex] = useState<number | null>(null);
  const [updateFriendlyNameOpenIndex, setUpdateFriendlyNameOpenIndex] = useState<number | null>(null);
  const [deleteVMOpenIndex, setDeleteVMOpenIndex] = useState<number | null>(null);
  const [scaleVMOpenIndex, setScaleVMOpenIndex] = useState<number | null>(null);
  const [redeployVMOpenIndex, setRedeployVMOpenIndex] = useState<number | null>(null);
  const [notesOpenIndex, setNotesOpenIndex] = useState<number | null>(null);
  const [showErrorPage, setShowErrorPage] = useState(false);

  const resource = job?.dataCollection;
  const matterDataSharedFolder = job && job.sharedFolders.find(sf => sf.type === 'MatterData');
  const caseDataSharedFolder = job && job.sharedFolders.find(sf => sf.type === 'CaseData');

  const getTaskDetails = async () => {
    setIsLoading(true);
    setShowErrorPage(false);
    await axios
      .get(process.env.REACT_APP_API_BASE_URL + '/api/jobs/' + id)
      .then(resp => {
        setJob(resp.data.job);
        setPrimaryUser(selectUsers(resp.data.job.jobUsers, 'Primary'));
        setSecondaryUsers(selectUsers(resp.data.job.jobUsers, 'Secondary'));
      })
      .catch(error => {
        if (error.isAxiosError && error.response.data.detail.includes('does not exist.')) {
          setShowErrorPage(true);
        } else {
          addToast(error.response.data.errors.id[0]);
        }
      });
    setIsLoading(false);
  };

  const getNotes = async (pageNumber?: number) => {
    setIsLoading(true);
    const body = {
      pageNumber: pageNumber ? pageNumber : 1,
      pageSize: 5
    };
    const resp = await axios.post(process.env.REACT_APP_API_BASE_URL + '/api/jobs/' + id + '/notes/search', body);
    setNotes(resp.data);
    setIsLoading(false);
  };

  useEffect(() => {
    getTaskDetails();
    getNotes();
  }, []);

  const selectUsers = (data: Array<JobUser>, type: string) => {
    return data.filter((user: JobUser) => {
      return user.type === type;
    });
  };

  const deleteJob = async (data: any) => {
    await axios
      .delete(process.env.REACT_APP_API_BASE_URL + '/api/jobs/' + id, { data: { reason: data.reason } })
      .then(() => {
        navigate('/jobs');
      })
      .catch(error => {
        addToast(error.response.data.detail);
      });
  };

  const addNote = async (note: any) => {
    setAddNoteOpen(false);
    setIsLoading(true);
    const data = {
      note: note.note
    };
    await axios.post(process.env.REACT_APP_API_BASE_URL + '/api/jobs/' + id + '/notes', data).catch(error => {
      addToast(error.response.data.detail);
    });
    getNotes();
  };

  const updateAnalysts = async (data: UpdateJobUsers) => {
    setEditAnalystsOpen(false);
    await axios
      .post(process.env.REACT_APP_API_BASE_URL + '/api/jobs/' + job?.id + '/updateusers', data)
      .catch(error => {
        addToast(error.response.data.detail);
      });
    getTaskDetails();
  };

  const showSummary = (content: string) => {
    if (content.length > 215) {
      return content.substring(0, 215) + '...';
    }
    return content;
  };

  const updateJobDetails = async (data: EditJobDetails) => {
    setEditDetailsOpen(false);
    const body = {
      title: data.title,
      priority: data.priority,
      description: data.description,
      status: data.status
    };
    await axios.put(process.env.REACT_APP_API_BASE_URL + '/api/jobs/' + data.id, body).catch(error => {
      addToast(error.response.data.detail);
    });
    getTaskDetails();
  };

  const editSharedData = (data: EditSharedFolderInfo) => {
    setCurrentSharedData(data);
    setEditSharedDataOpen(true);
  };

  const updateShare = async (id: string, update: any) => {
    setEditSharedDataOpen(false);
    try {
      await axios.post(process.env.REACT_APP_API_BASE_URL + '/api/sharedfolders/' + id + '/updateaccess', update);
      setIsToastForSuccess(true);
      addToast('The request to update access permission sent successfully.');
    } catch (error: any) {
      addToast(error.response.data.detail);
    }
    getTaskDetails();
  };

  const addToast = (content: string) => {
    setToastActive(true);
    setToastContent(content);
    setTimeout(() => {
      setToastActive(false);
      setToastContent('');
      setIsToastForSuccess(false);
    }, 5000);
  };

  useOnClickOutside(elementRef, () => {
    setIsClicked(null);
  });

  const optionsToggle = () => {
    setIsClicked(0);
  };

  const updateInternetAccess = async (id: string, update: Update) => {
    try {
      await updateSessionHostInternetAccess(id, update);
      setIsToastForSuccess(true);
      addToast('The request to update access permission sent successfully.');
    } catch (error: any) {
      addToast(error.response.data.detail || GENERIC_ERROR_MESSAGE);
    }
    setInternetAccessOpenIndex(null);
  };

  const updateShutdown = async (update: ShutdownUpdate) => {
    await axios
      .post(process.env.REACT_APP_API_BASE_URL + '/api/sessionhosts/' + resource!.id + '/updateshutdown', update)
      .catch(error => {
        addToast(error.response.data.detail);
      });

    setUpdateShutdownOpenIndex(null);
  };

  const deleteVM = async (detail: any) => {
    await axios
      .delete(process.env.REACT_APP_API_BASE_URL + '/api/sessionhosts/' + resource!.id, {
        data: { reason: detail.reason }
      })
      .catch(error => {
        addToast(error.response.data.detail);
      });

    setDeleteVMOpenIndex(null);
  };

  const scaleVM = async (update: Scale) => {
    const body = {
      size: update.vmSize,
      dataDiskSizeInGB: update.vmDiskSize
    };

    await axios
      .post(process.env.REACT_APP_API_BASE_URL + '/api/sessionhosts/' + resource!.id + '/rescale', body)
      .catch(error => {
        addToast(error.response.data.detail);
      });

    setScaleVMOpenIndex(null);
  };

  const redeployVM = async () => {
    await axios
      .post(process.env.REACT_APP_API_BASE_URL + '/api/sessionhosts/' + resource!.id + '/redeploy')
      .catch(error => {
        addToast(error.response.data.detail);
      });
    setRedeployVMOpenIndex(null);
  };

  const addNoteInVM = async (note: any) => {
    setNotesOpenIndex(null);
    const data = {
      note: note.note
    };
    await axios
      .post(process.env.REACT_APP_API_BASE_URL + '/api/sessionhosts/' + resource!.id + '/notes', data)
      .catch(error => {
        addToast(error.response.data.detail);
      });
  };

  const createNewCaseData = async () => {
    try {
      await createCaseData(job!.id);
      setIsToastForSuccess(true);
      addToast('The request to create Case Data sent successfully.');
      getTaskDetails();
    } catch (err: any) {
      addToast(err.response.data.detail);
    }
  };
  const assignUser = async (sessionHostId: string, updatedUserId: string | null) => {
    setAssignUserOpenIndex(null);
    try {
      await updateSessionHostUser(sessionHostId, updatedUserId);
      setIsToastForSuccess(true);
      addToast('The request to update session host user sent successfully.');
    } catch (error: any) {
      if (!error.response.data && error.message.includes('Request failed with status code 404')) {
        addToast(GENERIC_ERROR_MESSAGE);
      } else {
        error.response.data && addToast(error.response.data?.detail);
      }
    }
  };
  const updateFriendlyName = async (id: string, friendlyName: string) => {
    setUpdateFriendlyNameOpenIndex(null);
    const update = { friendlyName };
    try {
      await updateVMFriendlyName(id, update);
      setIsToastForSuccess(true);
      addToast('The request to update VM Friendly Name sent successfully.');
    } catch (error: any) {
      addToast(error.response.data.detail || GENERIC_ERROR_MESSAGE);
    }
  };

  return (
    <>
      {showErrorPage && <ErrorPage errorMessage={"We can't seem to find the matter you are looking for..."} />}
      {!showErrorPage && !isLoading && (
        <>
          <Toast open={toastActive} content={toastContent} isSuccess={isToastForSuccess}></Toast>
          <BreadCrumbs>
            <a href={PageRoutes.Root}>Matter Management</a> <BsChevronRight /> <span>{job!?.title}</span>
          </BreadCrumbs>
          <PageHeader>
            <div className='sub-header'>
              <BsInbox /> Matter Detail
            </div>
            <div className='header-options'>
              <h1>{job!?.title}</h1>
              <ul>
                <li>
                  <strong>Matter ID:</strong>
                  {job!?.matterId}
                </li>
                <li>
                  <strong>Status:</strong>
                  <Badge type='dot' status={job!?.status} />
                </li>
                <li>
                  <LinkButton
                    onClick={() => {
                      setDeleteJobOpen(true);
                    }}>
                    <IconCircle>
                      <BsTrash />
                    </IconCircle>{' '}
                    Delete Matter
                  </LinkButton>
                </li>
              </ul>
            </div>
          </PageHeader>
          <DetailsPanel>
            <button
              onClick={() => {
                setEditDetailsOpen(true);
              }}>
              <BsPencil />
              Edit details
            </button>
            <ul>
              <li>
                <strong>Priority:</strong>
                {job!?.priority}
              </li>
              <li>
                <strong>Region:</strong>
                {job!?.region}
              </li>
              <li>
                <strong>Date Created:</strong>
                {dayjs(job!?.created)
                  .local()
                  .format('DD-MMM-YYYY')}
              </li>
              <li>
                <strong>Last Updated:</strong>
                {dayjs(job!?.lastModified)
                  .local()
                  .format('DD-MMM-YYYY')}
              </li>
              <li>
                <strong>Description:</strong>
                {job!?.description}
              </li>
            </ul>
            <button
              onClick={() => {
                navigate(PageRoutes.Evidence.replace(':jobId', job!.id));
              }}>
              <BsFillMenuButtonWideFill />
              Manage Evidence
            </button>
          </DetailsPanel>
          <h2>Data Collection</h2>
          <Panel>
            <NewCreationButton
              onClick={() => navigate('/createvm', { state: job?.id || '' })}
              disabled={!!resource || (job && job.status === 'Closed') || (job && job.status === 'Removing')}>
              <BsPlus />
              Create Data Collection
            </NewCreationButton>

            <ShadedTable>
              <thead>
                <tr>
                  {Object.values(VmTableTitle).map((option, index) => (
                    <th key={index}>{option}</th>
                  ))}
                </tr>
              </thead>
              {resource && (
                <tbody>
                  <ResourceItem
                    resource={resource!}
                    toggleAction={optionsToggle}
                    optionsClickedIndex={isClicked}
                    ref={elementRef}
                    handleClickDetails={setVMDetailsOpen}
                    handleClickNotes={setNotesOpenIndex}
                    handleClickInternetAccess={setInternetAccessOpenIndex}
                    handleUpdateShutdown={setUpdateShutdownOpenIndex}
                    handleScaleVM={setScaleVMOpenIndex}
                    handleRedeployVM={setRedeployVMOpenIndex}
                    handleDelteVM={setDeleteVMOpenIndex}
                    handleAssignUser={setAssignUserOpenIndex}
                    handleUpdateFriendlyName={setUpdateFriendlyNameOpenIndex}
                  />
                </tbody>
              )}
            </ShadedTable>
            {resource && (
              <div>
                <VMDetails
                  sessionHostId={resource!.id}
                  vmDetailsOpen={vmDetailsOpen === 0 ? true : false}
                  closeVMDetails={() => setVMDetailsOpen(null)}
                />
                <AddVMNote
                  sessionHostId={resource!.id}
                  addNoteOpen={notesOpenIndex === 0 ? true : false}
                  addNote={addNoteInVM}
                  closeAddNote={() => {
                    setNotesOpenIndex(null);
                  }}
                />
                <UpdateInternetAccess
                  sessionHost={resource}
                  updateSessionHostOpen={internetAccessOpenIndex === 0 ? true : false}
                  updateInternetAccess={updateInternetAccess}
                  closeUpdateSessionHost={() => {
                    setInternetAccessOpenIndex(null);
                  }}
                />
                <UpdateShutdown
                  resource={resource!}
                  updateShutdownOpen={updateShutdownOpenIndex === 0 ? true : false}
                  updateShutdown={updateShutdown}
                  closeUpdateShutdown={() => {
                    setUpdateShutdownOpenIndex(null);
                  }}
                />

                <ScaleVM
                  machine={resource!}
                  scaleVMOpen={scaleVMOpenIndex === 0 ? true : false}
                  scaleVM={scaleVM}
                  closeScaleVM={() => {
                    setScaleVMOpenIndex(null);
                  }}
                />
                <RedeployVM
                  machine={resource!}
                  redeployVMOpen={redeployVMOpenIndex === 0 ? true : false}
                  redeployVM={redeployVM}
                  closeRedeployVM={() => {
                    setRedeployVMOpenIndex(null);
                  }}
                />

                <DeleteVM
                  machine={resource!}
                  deleteVMOpen={deleteVMOpenIndex === 0 ? true : false}
                  deleteVM={deleteVM}
                  closeDeleteVM={() => {
                    setDeleteVMOpenIndex(null);
                  }}
                />
                <AssignUser
                  sessionHostId={resource.id}
                  assignUserOpen={assignUserOpenIndex === 0 ? true : false}
                  assignUser={assignUser}
                  closeAssignUser={() => {
                    setAssignUserOpenIndex(null);
                  }}
                />
                <UpdateFriendlyName
                  machine={resource}
                  updateFriendlyNameOpen={updateFriendlyNameOpenIndex === 0 ? true : false}
                  updateFriendlyName={updateFriendlyName}
                  closeUpdateFriendlyName={() => {
                    setUpdateFriendlyNameOpenIndex(null);
                  }}
                />
              </div>
            )}
          </Panel>
          <div style={{ marginBottom: '40px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h2>Analysts</h2>
              <LinkButton
                onClick={() => {
                  setEditAnalystsOpen(true);
                }}>
                <IconCircle>
                  <BsPencil />
                </IconCircle>{' '}
                Edit analysts
              </LinkButton>
            </div>
            <AnalystGroup>
              <li>
                <strong>Primary Analyst:</strong>
                {primaryUser!.length > 0 ? (
                  <>
                    {primaryUser!.map((jobUser, index) => (
                      <div key={index}>
                        <HighlightPanel>
                          <IconCircle className='big'>
                            <BsFillPersonFill />
                          </IconCircle>
                          {jobUser.user.name}
                        </HighlightPanel>
                      </div>
                    ))}
                  </>
                ) : (
                  <span>No primary analyst assigned</span>
                )}
              </li>
              <li>
                <strong>Secondary Analysts:</strong>
                {secondaryUsers!.length > 0 ? (
                  <ul>
                    {secondaryUsers!.map((jobUser, index) => (
                      <li key={index}>
                        <HighlightPanel>
                          <IconCircle className='big'>
                            <BsFillPersonFill />
                          </IconCircle>
                          {jobUser.user.name}
                        </HighlightPanel>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <span>No secondary analysts assigned</span>
                )}
              </li>
            </AnalystGroup>
          </div>
          <div>
            <h2>
              Matter Data <SmallText>Status: {matterDataSharedFolder?.status}</SmallText>
            </h2>
            <Panel>
              {matterDataSharedFolder &&
                matterDataSharedFolder.accessPermissions &&
                matterDataSharedFolder.accessPermissions.length > 0 && (
                  <SharedFolderInfo sharedFolder={matterDataSharedFolder} handleEdit={editSharedData} />
                )}
            </Panel>
          </div>
          <div>
            <h2>
              Case Data <SmallText>Status: {caseDataSharedFolder?.status || 'None'}</SmallText>
            </h2>
            <Panel>
              <NewCreationButton
                onClick={createNewCaseData}
                disabled={
                  !!caseDataSharedFolder || (job && job.status === 'Closed') || (job && job.status === 'Removing')
                }>
                <BsPlus />
                Create Case Data
              </NewCreationButton>
              {caseDataSharedFolder &&
                caseDataSharedFolder.accessPermissions &&
                caseDataSharedFolder.accessPermissions.length > 0 && (
                  <SharedFolderInfo sharedFolder={caseDataSharedFolder} handleEdit={editSharedData} />
                )}
            </Panel>
          </div>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h2>Notes</h2>
              <LinkButton
                onClick={() => {
                  setAddNoteOpen(true);
                }}>
                <IconCircle>
                  <BsPencil />
                </IconCircle>{' '}
                Add note
              </LinkButton>
            </div>
            <Panel>
              <RuledTable>
                <thead>
                  <tr>
                    <th>Added By</th>
                    <th>Posted</th>
                    <th>Message</th>
                  </tr>
                </thead>
                <tbody>
                  {notes!?.items.length > 0 && (
                    <>
                      {notes!?.items.map((note, index) => (
                        <tr key={index}>
                          <td>{note.createdByUser.name}</td>
                          <td>{dayjs.utc(note.created).local().format('DD-MMM-YYYY')}</td>
                          <td>{showSummary(note.note)}</td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </RuledTable>
              <Paging>
                {(() => {
                  const arr = [];
                  for (let i = 1; i <= notes!?.totalPages; i++) {
                    arr.push(
                      <LinkButton
                        key={i}
                        onClick={() => {
                          getNotes(i);
                        }}
                        className={notes!?.pageNumber === i ? 'active' : ''}>
                        {i}
                      </LinkButton>
                    );
                  }
                  return arr;
                })()}
              </Paging>
            </Panel>
          </div>

          <AddNote
            notes={notes}
            addNoteOpen={addNoteOpen}
            closeAddNote={() => {
              setAddNoteOpen(false);
            }}
            addNote={addNote}
          />
          <DeleteJob
            details={job}
            deleteJobOpen={deleteJobOpen}
            deleteJob={deleteJob}
            closeDeleteJob={() => {
              setDeleteJobOpen(false);
            }}
          />
          <EditAnalysts
            editAnalystsOpen={editAnalystsOpen}
            primaryAnalyst={primaryUser}
            secondaryAnalysts={secondaryUsers}
            updateAnalysts={updateAnalysts}
            closeEditAnalysts={() => {
              setEditAnalystsOpen(false);
            }}
          />
          <EditJobDetail
            details={job}
            editDetailsOpen={editDetailsOpen}
            updatedDetails={updateJobDetails}
            closeEditJobDetail={() => {
              setEditDetailsOpen(false);
            }}
          />
          <EditSharedData
            sharedData={currentSharedData}
            editSharedDataOpen={editSharedDataOpen}
            closeEditSharedData={() => {
              setEditSharedDataOpen(false);
            }}
            updateShare={updateShare}
          />
        </>
      )}

      {!showErrorPage && isLoading && (
        <VerticalCenter>
          <Spinner />
        </VerticalCenter>
      )}
    </>
  );
};

import styled from '@emotion/styled';

export const LinkButtonHighlight = styled('button')`
  border: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
  color: ${props => props.theme.colors.secondary};
`;

export const ResourceOptions = styled('div')`
  position: relative;

  > button {
    display: block;
    margin: 0 auto;
  }

  .options-list {
    z-index: 2;
    position: absolute;
    right: -15px;
    min-width: 180px;
    top: 35px;
    padding: 7px 20px;
    margin: 0;
    list-style: none;
    background: #fff;
    box-shadow: 2px 2px 5px rgb(0 0 0 / 10%);
    border: 1px solid #f1f1f1;
  }

  li {
    padding: 7px 0;
  }
`;

export const LinkButton = styled('button')`
  border: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
`;
export const IconContainer = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  textalign: center;
  justify-content: center;
  cursor: pointer;
  height: 33px;

  .status-text {
    padding-top: 3px;
    display: none;
  }

  &:hover .status-text {
    display: block;
  }

  &:hover .status-icon {
    display: none;
  }
`;

import React from 'react';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';
import { BsFillClipboardHeartFill, BsXLg } from 'react-icons/bs';
import Button from '../atoms/Button';
import CloseButton from '../atoms/CloseButton';
import IconCircle from '../atoms/IconCircle';
import Label from '../atoms/Label';
import TextInput from '../atoms/TextInput';
import Slider from '../atoms/Slider';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Persona, Resource } from './../../types/SessionHosts';
import { Spacer16, Spacer48 } from '../atoms/Spacer';

dayjs.extend(utc);

type Props = {
  machine: Resource;
  updateFriendlyNameOpen: boolean;
  updateFriendlyName: (id: string, name: string) => void;
  closeUpdateFriendlyName: () => void;
};

type FormValues = {
  friendlyName: string | undefined;
};

const InfoWrapper = styled('div')`
  background: #efefef;
  padding: 60px 90px 40px 90px;

  h2 {
    text-align: center;
    font-size: 22px;
    margin: 15px 0 30px 0;
  }

  .details {
    margin: 20px 0 60px;
    padding: 0;
    list-style: none;

    li {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
    }

    strong {
      font-size: 14px;
    }
  }
`;

const FormField = styled('div')`
  margin-bottom: 15px;
`;

const InlineError = styled.div(() => ({
  color: '#CC0000',
  marginTop: '5px',
  width: '100%',
  marginLeft: 0
}));

const InputSection = styled.span`
  display: flex;
`;
const InputPrefix = styled.div(({ theme }) => ({
  color: '#999',
  width: '15%',
  outline: 'none',
  borderRadius: '4px',
  borderWidth: '1px',
  borderColor: theme.colors.primary,
  borderStyle: 'solid',
  borderRight: 'none',
  background: theme.colors.white,
  padding: '10px 0 0 12px'
}));

export const UpdateFriendlyName = ({
  machine,
  updateFriendlyNameOpen,
  updateFriendlyName,
  closeUpdateFriendlyName
}: Props) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset
  } = useForm<FormValues>();

  const prefix = machine.persona === Persona.DataCollection ? 'DC-' : machine.persona === Persona.Forensic ? 'FO-' : '';

  const validateDiff = (value: string | undefined) => {
    if (value) {
      const fullValue = `${prefix}${value}`;
      if (machine.friendlyName && machine.friendlyName === fullValue) {
        return false;
      }
      return true;
    }
  };

  const handleCloseSideBar = () => {
    setValue('friendlyName', undefined);
    closeUpdateFriendlyName();
    reset();
  };

  const onSubmit = (data: FormValues) => {
    const fullFriendlyName = `${prefix}${data.friendlyName}`;
    data.friendlyName && updateFriendlyName(machine.id, fullFriendlyName);
    setValue('friendlyName', undefined);
    reset();
  };

  return (
    <Slider open={updateFriendlyNameOpen} width='500px'>
      <InfoWrapper>
        <div>
          <IconCircle className='xl'>
            <BsFillClipboardHeartFill />
          </IconCircle>
          <h2>Update Friendly Name</h2>
          <CloseButton aria-label='Close' onClick={handleCloseSideBar}>
            <BsXLg />
          </CloseButton>
          <ul className='details'>
            {machine.name !== undefined ? (
              <>
                <li>
                  <strong>VM: </strong>
                </li>
                <li>
                  <span>{machine.name}</span>
                </li>
              </>
            ) : (
              <>
                <li>
                  <strong>VM: </strong>
                </li>
                <li>
                  <span>{machine.id}</span>
                </li>
              </>
            )}
            <Spacer16 />
            <li>
              <strong>Persona: </strong>
            </li>
            <li>
              <span>{machine?.persona || ''}</span>
            </li>
            <Spacer16 />
            <li>
              <strong>Current Friendly Name: </strong>
            </li>
            <li>
              <span>{machine?.friendlyName || ''}</span>
            </li>
            <Spacer16 />
            <li>
              <strong>Friendly Name Status: </strong>
            </li>
            <li>
              <span>{machine?.friendlyNameStatus || ''}</span>
            </li>
          </ul>

          <form onSubmit={handleSubmit(onSubmit)}>
            <FormField>
              <Label htmlFor='friendlyName'>New Friendly Name:</Label>
              <InputSection>
                {machine.persona === Persona.DataCollection && <InputPrefix>DC- </InputPrefix>}
                {machine.persona === Persona.Forensic && <InputPrefix>FO- </InputPrefix>}
                <TextInput
                  key='friendlyName'
                  id='friendlyName'
                  width={'85%'}
                  {...register('friendlyName', {
                    required: true,
                    minLength: 3,
                    maxLength: 61,
                    validate: {
                      validateDiff: validateDiff
                    }
                  })}></TextInput>
              </InputSection>
              {/* validation group and show different error messages */}
              {errors.friendlyName?.type === 'required' && <InlineError>This field is required</InlineError>}
              {errors.friendlyName?.type === 'minLength' && (
                <InlineError>The name you entered should be at least 3 characters long</InlineError>
              )}
              {errors.friendlyName?.type === 'maxLength' && (
                <InlineError>The name you entered should be no longer than 61 characters</InlineError>
              )}
              {errors.friendlyName?.type === 'validateDiff' && (
                <InlineError>
                  The new friendly name must be different from the current friendly name. Please choose a new friendly
                  name.
                </InlineError>
              )}
            </FormField>
            <Spacer48 />
            <Button type='submit'>Update Friendly Name</Button>
          </form>
        </div>
      </InfoWrapper>
    </Slider>
  );
};

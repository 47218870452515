import React from 'react';
import Button from './../atoms/Button';
import Label from './../atoms/Label';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';
import Slider from '../atoms/Slider';
import { BsXLg, BsFileRichtextFill } from 'react-icons/bs';
import CloseButton from '../atoms/CloseButton';
import IconCircle from '../atoms/IconCircle';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import TextInput from '../atoms/TextInput';
import { InlineError } from './VMForm/styled';

dayjs.extend(utc);

type Props = {
  addEvidenceOpen: boolean;
  addEvidence: (data: { evidence: string }) => void;
  closeAddEvidence: () => void;
};

const FormField = styled.div(() => ({
  display: 'flex',
  marginBottom: '16px',
  flexWrap: 'wrap'
}));

const NotesWrapper = styled('div')`
  background: #efefef;
  padding: 60px 40px 40px 40px;

  h2 {
    text-align: center;
    font-size: 22px;
    margin: 15px 0;
  }
`;

const ButtonGroup = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 30px;
  justify-content: flex-end;
`;

export const AddEvidence = ({ addEvidenceOpen, closeAddEvidence, addEvidence }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = (data: any) => {
    addEvidence(data);
  };

  return (
    <Slider open={addEvidenceOpen} width='500px'>
      <NotesWrapper>
        <div>
          <IconCircle className='xl'>
            <BsFileRichtextFill />
          </IconCircle>
          <h2>New Evidence</h2>
          <CloseButton aria-label='Close' onClick={closeAddEvidence}>
            <BsXLg />
          </CloseButton>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormField>
              <Label htmlFor='evidence'>Evidence ID</Label>
              <TextInput
                type='text'
                id='evidence'
                {...register('evidence', {
                  required: 'This field is required',
                  pattern: {
                    value: /^[a-zA-Z0-9](?!.*--)[a-zA-Z0-9-]{1,61}[a-zA-Z0-9]$/,
                    message:
                      'Evidence ID may only contain letters, numbers, and hyphens, and must begin with a letter or a number. Each hyphen must be preceded and followed by a non-hyphen character. The name must also be between 3 and 63 characters long.'
                  }
                })}
              />
              {errors.evidence && <InlineError>{String(errors.evidence.message)}</InlineError>}
            </FormField>
            <ButtonGroup>
              <Button type='submit'>Create</Button>
            </ButtonGroup>
          </form>
        </div>
      </NotesWrapper>
    </Slider>
  );
};

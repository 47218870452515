import { Persona, Resource } from '../../../types/SessionHosts';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BsFillPersonFill, BsPlus, BsTrash, BsXLg } from 'react-icons/bs';
import Button from '../../atoms/Button';
import CloseButton from '../../atoms/CloseButton';
import IconCircle from '../../atoms/IconCircle';
import Label from '../../atoms/Label';
import LinkButton from '../../atoms/LinkButton';
import Slider from '../../atoms/Slider';
import TextInputAutoComplete from '../../molecules/TextInputAutoComplete';
import { AddAnalysts, AnalystPanel, AnalystsWrapper, FormField, OuterWrapper } from './styled';
import { Option, Item } from '../../../types/Jobs';
import DropDownSelector from '../../atoms/DropDownSelector';
import { Controller, useForm } from 'react-hook-form';
import { Spacer48 } from '../../atoms/Spacer';
import { Spinner } from '../../../components/molecules/Spinner';
import { getSessionHostById } from '../../../api/getSessionHostById';
import ErrorSideBar from '../../../pages/Error/ErrorSideBar';
import { GENERIC_ERROR_MESSAGE } from '../../../constants';

type Props = {
  sessionHostId: string;
  assignUserOpen: boolean;
  assignUser: (sessionHostId: string, updatedUserId: string | null) => void;
  closeAssignUser: () => void;
};

export const AssignUser: React.FC<Props> = ({ sessionHostId, assignUserOpen, assignUser, closeAssignUser }) => {
  const { register, watch, handleSubmit, control, setValue } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [sessionHost, setSessionHost] = useState<Resource | null>(null);
  const [draftVmUser, setDraftVmUser] = useState<Item | null>();
  const [searchedUsers, setSearchedUsers] = useState<Item[]>();
  const [showAddUserField, setShowAddUserField] = useState(false);
  const [showErrorPage, setShowErrorPage] = useState(false);
  const [errorMessge, setErrorMessage] = useState('');
  const watchSelectedUser = watch('selectedUser');

  const removeCurrentUser = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setDraftVmUser(null);
    setValue('selectedUser', undefined);
  };

  const addOption = (id: string) => {
    const selectedUser = searchedUsers?.find(user => user.id === id);
    setDraftVmUser(selectedUser);
    resetUsers();
  };

  const updateUserAssignment = () => {
    const updatedUserId = draftVmUser?.id || null;
    assignUser(sessionHostId, updatedUserId);
    setShowAddUserField(false);
  };

  const handleCloseAssignUser = () => {
    setShowAddUserField(false);
    closeAssignUser();
  };

  const getUsers = async (search: string) => {
    const userData = await axios.get(process.env.REACT_APP_API_BASE_URL + '/api/users/search?name=' + search);
    setSearchedUsers(userData.data.users);
  };

  const getSessionHost = async () => {
    setIsLoading(true);
    try {
      const res = await getSessionHostById(sessionHostId);
      setSessionHost(res);
      res.user ? setDraftVmUser(res.user) : setDraftVmUser(null);
    } catch (error: any) {
      setShowErrorPage(true);
      if (error.isAxiosError && error.response.data?.detail.includes('does not exist.')) {
        setErrorMessage("We can't seem to find the session host you are looking for...");
      } else {
        setErrorMessage(GENERIC_ERROR_MESSAGE);
      }
    }
    setIsLoading(false);
  };

  const resetUsers = () => {
    setSearchedUsers([] as any);
  };

  const isClickable = () => {
    const currentUserId = sessionHost?.user?.id || null;
    const updatedUserId = draftVmUser?.id || null;
    return currentUserId === updatedUserId;
  };

  useEffect(() => {
    assignUserOpen && getSessionHost();
  }, [assignUserOpen]);

  useEffect(() => {
    draftVmUser && setShowAddUserField(false);
  }, [draftVmUser]);

  useEffect(() => {
    if (watchSelectedUser && watchSelectedUser.length > 0) {
      const selectedUser =
        sessionHost?.eligibleJobUsers && sessionHost?.eligibleJobUsers.find(user => user.id === watchSelectedUser);
      setDraftVmUser(selectedUser);
    }
  }, [watchSelectedUser]);

  return (
    <Slider open={assignUserOpen} width='460px'>
      {isLoading ? (
        <AnalystsWrapper>
          <Spinner />
        </AnalystsWrapper>
      ) : showErrorPage ? (
        <AnalystsWrapper>
          <ErrorSideBar errorMessage={errorMessge} closeSideBar={closeAssignUser} />
        </AnalystsWrapper>
      ) : (
        <AnalystsWrapper>
          <div>
            <CloseButton aria-label='Close' onClick={handleCloseAssignUser}>
              <BsXLg />
            </CloseButton>
            <IconCircle className='xl'>
              <BsFillPersonFill />
            </IconCircle>
            <h2>Update User Assignment</h2>
            <Spacer48 />

            <form onSubmit={handleSubmit(updateUserAssignment)}>
              <AnalystPanel>
                <div>
                  <IconCircle className='big'>
                    <BsFillPersonFill />
                  </IconCircle>
                  <span>{draftVmUser?.name || ''}</span>
                </div>
                {draftVmUser && (
                  <button
                    aria-label={'Remove: ' + draftVmUser?.name}
                    onClick={e => {
                      draftVmUser && removeCurrentUser(e);
                    }}>
                    <BsTrash />
                  </button>
                )}
              </AnalystPanel>
              <OuterWrapper>
                {(draftVmUser === null || (sessionHost && !sessionHost.user)) && (
                  <AddAnalysts>
                    <LinkButton
                      type='button'
                      onClick={() => {
                        setShowAddUserField(true);
                      }}>
                      <IconCircle>
                        <BsPlus />
                      </IconCircle>{' '}
                      Add user
                    </LinkButton>
                    {showAddUserField &&
                      sessionHost &&
                      sessionHost.persona === Persona.DataCollection &&
                      (!sessionHost?.eligibleJobUsers || sessionHost?.eligibleJobUsers.length === 0) && (
                        <AnalystPanel>
                          <div>
                            <IconCircle className='big'>
                              <BsFillPersonFill />
                            </IconCircle>
                            <strong>No User Available for this Job</strong>
                          </div>
                        </AnalystPanel>
                      )}
                    {showAddUserField &&
                      sessionHost &&
                      sessionHost.persona === Persona.DataCollection &&
                      sessionHost?.eligibleJobUsers &&
                      sessionHost?.eligibleJobUsers.length > 0 && (
                        <FormField>
                          <Label htmlFor='selectedUser'>Please select</Label>
                          <DropDownSelector
                            defaultValue=''
                            placeholder='Please select'
                            ariaLabel='SelectedUser'
                            {...register('selectedUser')}
                            options={sessionHost?.eligibleJobUsers.map((user: Option) => ({
                              label: user.name,
                              value: user.id
                            }))}
                          />
                        </FormField>
                      )}

                    {showAddUserField &&
                      ((sessionHost && sessionHost.persona === Persona.DailyDriver) ||
                        (sessionHost && sessionHost.persona === Persona.Forensic)) && (
                        <div>
                          <Label>Please search</Label>
                          <Controller
                            control={control}
                            name='selectedUser'
                            render={({ field: { onChange } }) => (
                              <TextInputAutoComplete
                                setSelection={true}
                                onSetOption={option => {
                                  addOption(option);
                                }}
                                searchResults={searchedUsers}
                                searchValue={getUsers}
                                changeSelection={resetUsers}
                                ariaLabel='Search Users'
                              />
                            )}
                          />
                        </div>
                      )}
                  </AddAnalysts>
                )}
              </OuterWrapper>
              <AddAnalysts>
                <Button type='submit' minWidth='250px' minHeight='50px' fontSize='16px' disabled={isClickable()}>
                  Save changes
                </Button>
              </AddAnalysts>
            </form>
          </div>
        </AnalystsWrapper>
      )}
    </Slider>
  );
};
